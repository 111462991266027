import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAllCustomers } from "../../redux/api/apiCustomer";
import { selectCustomers } from "../../redux/Customers";
import {
	getTransaction,
	insertTaxinvoice,
	getTaxinvoiceById,
	updateTaxinvoice,
} from "../../redux/api/apiTaxinvoice";

export default function Taxinvoice() {
	const { id } = useParams();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const customerList = useSelector(selectCustomers);
	useEffect(() => {
		dispatch(getAllCustomers());
	}, []);
	const customerLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				customerList.filter((i) =>
					i.cus_name.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};

	const [formData, setFormData] = useState({
		cus_id: "",
		datefrom: "",
		dateto: "",
		product_name: "",
		invoice_no: "",
		item_type: "",
		discount: 0,
	});

	const [sales, setSales] = useState([]);
	const [saleTotalqty, setSaleTotalqty] = useState(0);
	const [saleTotalprice, setSaleTotalprice] = useState(0);
	const [saleTotalsubtotal, setSaleTotalsubtotal] = useState(0);
	const [totaltaxAmount, setTotaltaxAmount] = useState(0);
	const [avgPrice, setAvgPrice] = useState(0);

	const checkTransaction = () => {
		if (formData.datefrom === "") {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please select date from",
			});
			return false;
		}
		if (formData.dateto === "") {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please select date to",
			});
			return false;
		}
		if (formData.item_type === "") {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please select invoice type",
			});
			return false;
		}

		if (formData.cus_id === "") {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please select customer",
			});
			return false;
		}
		Swal.fire({
			title: "Please wait...",
			html: "Checking transaction...",
			allowOutsideClick: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		// discount value zero
		reset({
			discount: 0,
		});
		dispatch(
			getTransaction(formData, (res) => {
				if (res.status === 200) {
					// data empty alert
					if (res.data.length === 0) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: "No data found",
						});
						setSales(res.data);
						setSaleTotalqty(0);
						setSaleTotalsubtotal(0);
						setSaleTotalprice(0);
						setTotaltaxAmount(0);
						setAvgPrice(0);
						reset({
							totalqty: 0,
							totalprice: 0,
							taxamount: 0,
							avgprice: 0,
							subtotal: 0,
							cus_id: formData.cus_id,
							datefrom: formData.datefrom,
							dateto: formData.dateto,
						});
						Swal.close();
						return false;
					}
					Swal.close();
					setSales(res.data);
					setSaleTotalqty(res.totalQuantity);
					setSaleTotalsubtotal(res.totalSubTotal);
					setSaleTotalprice(res.totalAmount);
					setTotaltaxAmount(res.totaltaxAmount);
					setAvgPrice(res.avgPrice);
					reset({
						totalqty: res.totalQuantity,
						totalprice: res.totalAmount,
						taxamount: res.totaltaxAmount,
						avgprice: res.avgPrice,
						subtotal: res.totalSubTotal,
						cus_id: formData.cus_id,
						datefrom: formData.datefrom,
						dateto: formData.dateto,
					});
				}
			})
		);
	};

	const save = (data) => {
		if (formData.product_name === "") {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please Enter product",
			});
			return false;
		}
		if (formData.invoice_no === "") {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please Enter invoice no",
			});
			return false;
		}
		if (formData.datefrom === "") {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please select Transaction date",
			});
			return false;
		}
		if (formData.invoice_date === "") {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please select Invoice date ",
			});
			return false;
		}
		if (formData.cus_id === "") {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please select customer",
			});
			return false;
		}
		if (formData.item_type === "") {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please select invoice type",
			});
			return false;
		}

		dispatch(
			insertTaxinvoice(formData, (res) => {
				if (res.status === 200) {
					Swal.fire({
						icon: "success",
						title: "Success...",
						text: "Tax Invoice Added Successfully.",
					});
					navigate("/taxinvoice");
				}
			})
		);
	};

	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<>
			<div className="page-wrapper compact-wrapper" id="pageWrapper">
				<Header />

				<div className="page-body-wrapper">
					<Sidebar />
					<div className="page-body">
						<div className="container-fluid">
							<div className="page-title">
								<div className="row">
									<div className="col-sm-6">
										<h3>
											{id ? "Update " : "Add "}
											Tax Invoice
										</h3>
									</div>
									<div className="col-sm-6">
										<ol className="breadcrumb">
											<li className="breadcrumb-item">
												<Link to="#">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width={24}
														height={24}
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														strokeWidth={2}
														strokeLinecap="round"
														strokeLinejoin="round"
														className="feather feather-home"
													>
														<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
														<polyline points="9 22 9 12 15 12 15 22" />
													</svg>
												</Link>
											</li>
											<li className="breadcrumb-item">
												{id ? "Update" : "Add"}
											</li>
											<li className="breadcrumb-item active"> Tax Invoice</li>
										</ol>
									</div>
								</div>
							</div>
						</div>

						<div className="container-fluid project-create">
							<div className="row">
								<form
									id="addCustomerForm"
									className="theme-form"
									onSubmit={handleSubmit(save)}
								>
									<div className="col-sm-12">
										<div className="card">
											<div className="card-body">
												<div className="form theme-form">
													<div className="col-md-12">
														<div className="row">
															<div className="col-md-4  ">
																<div className="form-group">
																	<label htmlFor="department_id">
																		Client *
																	</label>
																	<Controller
																		name="cus_id"
																		control={control}
																		rules={{ required: false }}
																		render={({ field }) => (
																			<AsyncSelect
																				{...field}
																				isClearable
																				defaultOptions={customerList}
																				loadOptions={customerLoadOptions}
																				getOptionLabel={(option) =>
																					option.cus_name
																				}
																				getOptionValue={(option) => option.id}
																				placeholder="Select Customer"
																				value={customerList.find(
																					(obj) => obj.id === field.value
																				)}
																				onChange={(e) => {
																					field.onChange(e);
																					setFormData({
																						...formData,
																						cus_id: e.id,
																					});
																				}}
																			/>
																		)}
																	/>
																</div>
															</div>
															<div className="col-md-4 ">
																<label className>Invoice Number</label>
																<input
																	type="text"
																	className="form-control"
																	name="invoice_no"
																	{...register("invoice_no", {
																		required: true,
																	})}
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			invoice_no: e.target.value,
																		});
																	}}
																/>
																{errors.invoice_no && (
																	<span className="text-danger">
																		This field is required
																	</span>
																)}
															</div>
															<div className="col-md-4 ">
																<label className>Description*</label>
																<input
																	type="text"
																	className="form-control"
																	name="product_name"
																	{...register("product_name", {
																		required: true,
																	})}
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			product_name: e.target.value,
																		});
																	}}
																/>
																{errors.product_name && (
																	<span className="text-danger">
																		This field is required
																	</span>
																)}
															</div>
															<div className="col-md-4 ">
																<label className>Invoice Date*</label>
																<input
																	type="date"
																	className="form-control"
																	name="invoice_date"
																	{...register("invoice_date", {
																		required: true,
																	})}
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			invoice_date: e.target.value,
																		});
																	}}
																/>
															</div>
															<div className="col-md-4 ">
																<label className>Transaction Date From*</label>
																<input
																	type="date"
																	className="form-control"
																	name="datefrom"
																	{...register("datefrom", {
																		required: true,
																	})}
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			datefrom: e.target.value,
																		});
																	}}
																/>
															</div>
															<div className="col-md-4 ">
																<label className>Transaction Date To*</label>
																<input
																	type="date"
																	className="form-control"
																	name="dateto"
																	{...register("dateto", {
																		required: true,
																	})}
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			dateto: e.target.value,
																		});
																	}}
																/>
															</div>
															<div className="col-md-4 ">
																<label className>Select Invoice Type*</label>
																<select
																	className="form-control"
																	name="item_type"
																	id="item_type"
																	{...register("item_type", {})}
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			item_type: e.target.value,
																		});
																	}}
																>
																	<option value="">Select</option>
																	<option value="cash">Cash</option>
																	<option value="credit">Credit</option>
																</select>
															</div>
															<div
																className="col-md-4"
																style={{
																	marginTop: 30,
																}}
															>
																<button
																	type="button"
																	className="btn btn-primary"
																	onClick={() => {
																		checkTransaction();
																	}}
																>
																	Fetch Delivery Orders
																</button>
															</div>

															<div className="col-md-12 ">
																<div className="form-group">
																	<h4 className="headstyle">
																		DELIVERY ORDERS LIST
																	</h4>
																</div>
															</div>
															<div
																className="col-md-12 "
																style={{ padding: 20 }}
															>
																<div className="d-flex align-items-center justify-content-center">
																	<div className="table-responsive">
																		<table className="table align-middle mb-0">
																			<thead className="table-light">
																				<tr>
																					<th>Sl no</th>

																					<th> Date</th>
																					<th>Vehicle</th>
																					<th>RFID</th>
																					<th>Quantity</th>
																					<th>Price</th>
																					<th>SubTotal</th>
																				</tr>
																			</thead>
																			<tbody>
																				{sales.map((item, index) => (
																					<tr key={index}>
																						<td>{index + 1}</td>

																						<td>{item.transaction_date}</td>
																						<td>{item.vehicle.vehicle_name}</td>
																						<td>{item.vehicle_code}</td>
																						<td>{item.quantity}</td>
																						<td>{item.unit_price}</td>
																						<td>{item.sub_total}</td>
																						{/* <td>
																							{item.payment_info.tax_amount}
																						</td>
																						<td>{item.payment_info.amount}</td> */}
																					</tr>
																				))}

																				{sales.length === 0 && (
																					<tr>
																						<td
																							colSpan={9}
																							className="text-center"
																						>
																							No Data Found
																						</td>
																					</tr>
																				)}
																			</tbody>
																		</table>
																	</div>
																</div>
															</div>

															<div className="col-md-4 ">
																<label className="form-label">
																	Total Quantity
																</label>
																<input
																	type="text"
																	className="form-control"
																	defaultValue={saleTotalqty}
																	readOnly
																	{...register("totalqty", {
																		required: false,
																	})}
																/>
															</div>

															<div className="col-md-4 ">
																<label className="form-label">Sub Total</label>
																<input
																	type="text"
																	className="form-control"
																	defaultValue={saleTotalsubtotal}
																	value={saleTotalsubtotal}
																	{...register("subtotal", {
																		required: false,
																	})}
																	readOnly
																/>
															</div>

															<div className="col-md-4 ">
																<label className="form-label">Tax %</label>
																<input
																	type="text"
																	className="form-control"
																	defaultValue={5}
																	{...register("tax", {
																		required: false,
																	})}
																	value={5}
																	readOnly
																/>
															</div>
															<div className="col-md-4 ">
																<label className="form-label">Tax Amount</label>
																<input
																	type="text"
																	className="form-control"
																	defaultValue={totaltaxAmount}
																	{...register("taxamount", {
																		required: false,
																	})}
																	readOnly
																/>
															</div>
															{/* <div className="col-md-4 ">
																<label className="form-label">Discount</label>
																<input
																	type="number"
																	className="form-control"
																	readOly
																	{...register("discount", {
																		required: false,
																	})}
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			discount: e.target.value,
																		});

																		setSaleTotalprice(
																			saleTotalsubtotal -
																				e.target.value +
																				totaltaxAmount
																		);
																	}}
																/>
															</div> */}
															<div className="col-md-4 ">
																<label className="form-label">Total</label>
																<input
																	type="text"
																	className="form-control"
																	defaultValue={saleTotalprice}
																	value={saleTotalprice}
																	readOnly
																	{...register("finalprice", {
																		required: false,
																	})}
																/>
															</div>
														</div>
													</div>
													<div className="col-md-12">
														<div className="mb-3">
															<button type="submit" className="btn btn-primary">
																Submit
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</>
	);
}
