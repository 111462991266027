import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function AuthUser() {
	const navigate = useNavigate();

	const getToken = () => {
		const tokenString = localStorage.getItem("token");
		const userToken = JSON.parse(tokenString);
		return userToken;
	};

	const getUser = () => {
		const userString = localStorage.getItem("user");
		const user_detail = JSON.parse(userString);
		return user_detail;
	};

	const getAccountType = () => {
		const accountTypeString = localStorage.getItem("account_type");
		const accountType = JSON.parse(accountTypeString);
		return accountType;
	};

	const getInfo = () => {
		const c_name = localStorage.getItem("c_name");
		const c_address = localStorage.getItem("c_address");
		const c_phone = localStorage.getItem("c_phone");
		const c_email = localStorage.getItem("c_email");
		const c_website = localStorage.getItem("c_website");
		const c_logo = localStorage.getItem("c_logo");
		return {
			c_name,
			c_address,
			c_phone,
			c_email,
			c_website,
			c_logo,
		};
	};

	const [token, setToken] = useState(getToken());
	const [user, setUser] = useState(getUser());
	const [accountType, setAccountType] = useState(getAccountType());
	const [info, setInfo] = useState(getInfo());

	const saveToken = (user, token, account_type, info) => {
		// console.log(JSON.stringify(info.company_name));
		localStorage.setItem("token", JSON.stringify(token));
		localStorage.setItem("user", JSON.stringify(user));
		localStorage.setItem("account_type", JSON.stringify(account_type));
		localStorage.setItem("c_name", JSON.stringify(info.company_name));
		localStorage.setItem("c_address", JSON.stringify(info.company_address));
		localStorage.setItem("c_phone", JSON.stringify(info.company_phone));
		localStorage.setItem("c_email", JSON.stringify(info.company_email));
		localStorage.setItem("c_website", JSON.stringify(info.company_website));
		localStorage.setItem("c_logo", JSON.stringify(info.company_logo));

		setToken(token);
		setUser(user);
		setAccountType(account_type);
		setInfo(info);

		navigate("/dashboard");
	};

	const logout = () => {
		localStorage.clear();
		navigate("/login");
	};

	const http = axios.create({
		baseURL: process.env.REACT_APP_BASEURL,
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	});
	return {
		setToken: saveToken,
		token,
		user,
		getToken,
		http,
		logout,
		accountType,
		info,
	};
}
