import React, { useEffect, useState } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import StockAnalysis from "./charts/StockAnalysis";
import TopFuelUsage from "./charts/TopFuelUsage";
import TransactionHistory from "./charts/TransactionHistory";
import Expense from "./charts/Expense";
import AccountTransaction from "./charts/AccountTransaction";
import Sidebar from "./components/Sidebar";
import {
	fetchUserPermission,
	dashboardCount,
	remaining_count,
} from "../../redux/api/apiUser";
import { useDispatch } from "react-redux";
import AuthUser from "../auth/AuthUser";
import { set, useForm } from "react-hook-form";
import { get } from "jquery";

export default function Dashboard() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const { getToken } = AuthUser();
	useEffect(() => {
		getToken();
	}, [getToken]);
	let defaultDate = new Date();
	defaultDate.setDate(defaultDate.getDate());
	// defaultDate to year month date
	defaultDate = defaultDate.toISOString().substr(0, 10);
	const [formData, setFormData] = useState({
		datefrom: defaultDate,
		dateto: defaultDate,
	});

	const dispatch = useDispatch();
	const [totalSale, setTotalSale] = React.useState(0);
	const [totalExpense, setTotalExpense] = React.useState(0);
	const [totalPurchase, setTotalPurchase] = React.useState(0);
	const [totalCustomer, setTotalCustomer] = React.useState(0);
	const [topFuelUsage, setTopFuelUsage] = React.useState([]);
	const [transactionHistory, setTransactionHistory] = React.useState([]);
	const [customerCreditLimit, setCustomerCreditLimit] = React.useState(0);
	const [customerUsed, setCustomerUsed] = React.useState(0);
	const [customerRemaining, setCustomerRemaining] = React.useState(0);
	useEffect(() => {
		dispatch(fetchUserPermission());
		dispatch(
			dashboardCount(formData, (res) => {
				if (res.limit) {
					setCustomerCreditLimit(res.limit);
					setCustomerUsed(res.used);
					setCustomerRemaining(res.remaining);
				} else {
					setTotalSale(res.totalSale);
					setTotalExpense(res.totalExpense);
					setTotalPurchase(res.totalPurchase);
					setTotalCustomer(res.totalCustomer);
					setTopFuelUsage(res.topFuelUsage);
					setTransactionHistory(res.transactionHistory);
				}
			})
		);
	}, [dispatch]);

	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}

	const getCount = () => {
		dispatch(
			dashboardCount(formData, (res) => {
				setTotalSale(res.totalSale);
				setTotalExpense(res.totalExpense);
				setTotalPurchase(res.totalPurchase);
				setTotalCustomer(res.totalCustomer);
				setTopFuelUsage(res.topFuelUsage);
				setTransactionHistory(res.transactionHistory);
				setCustomerCreditLimit(0);
				setCustomerUsed(0);
				setCustomerRemaining(0);
			})
		);
	};

	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Dashboard</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="index.html">
												<i data-feather="home" />
											</a>
										</li>
										<li className="breadcrumb-item">Home</li>
										<li className="breadcrumb-item active">Dashboard</li>
									</ol>
								</div>
							</div>
						</div>
					</div>
					<div className="container-fluid dashboard-2">
						<div className="row">
							{accountType === "customer" ? (
								<div className="col-md-12 box-col-12">
									<div className="row">
										<div className="card">
											<div
												className="col-md-12 row"
												style={{ padding: "10px" }}
											>
												<div className="col-md-3">
													<div className="card profit-card">
														<div className="card-header pb-0">
															<div className="d-flex justify-content-between">
																<div className="flex-grow-1">
																	<p className="square-after f-w-600 header-text-primary">
																		Credit Limit
																		<i className="fa fa-circle" />
																	</p>

																	<h4>
																		{customerCreditLimit
																			? customerCreditLimit
																			: 0}{" "}
																		د.إ
																	</h4>
																</div>
															</div>
														</div>

														<div className="card-body">
															<div className="d-flex">
																<div className="flex-grow-1">
																	<div className="profit-wrapper header-text-primary icon-bg-primary">
																		{/* <i className="fa fa-arrow-up" /> */}
																	</div>
																</div>
															</div>
															<div className="right-side icon-right-primary">
																<i className="fa fa-usd" />
																<div className="shap-block">
																	<div className="rounded-shap animate-bg-primary">
																		<i />
																		<i />
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-3">
													<div className="card profit-card">
														<div className="card-header pb-0">
															<div className="d-flex justify-content-between">
																<div className="flex-grow-1">
																	<p className="square-after f-w-600 header-text-primary">
																		Used Credit Limit
																		<i className="fa fa-circle" />
																	</p>

																	<h4>{customerUsed ? customerUsed : 0} د.إ</h4>
																</div>
															</div>
														</div>

														<div className="card-body">
															<div className="d-flex">
																<div className="flex-grow-1">
																	<div className="profit-wrapper header-text-primary icon-bg-primary"></div>
																</div>
															</div>
															<div className="right-side icon-right-danger">
																<i className="fa fa-usd" />
																<div className="shap-block">
																	<div className="rounded-shap animate-bg-primary">
																		<i />
																		<i />
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-3">
													<div className="card profit-card">
														<div className="card-header pb-0">
															<div className="d-flex justify-content-between">
																<div className="flex-grow-1">
																	<p className="square-after f-w-600 header-text-primary">
																		Remaining Credit Limit
																		<i className="fa fa-circle" />
																	</p>

																	<h4>
																		{customerRemaining ? customerRemaining : 0}{" "}
																		د.إ
																	</h4>
																</div>
															</div>
														</div>

														<div className="card-body">
															<div className="d-flex">
																<div className="flex-grow-1">
																	<div className="profit-wrapper header-text-primary icon-bg-primary"></div>
																</div>
															</div>
															<div className="right-side icon-right-success">
																<i className="fa fa-usd" />
																<div className="shap-block">
																	<div className="rounded-shap animate-bg-primary">
																		<i />
																		<i />
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							) : (
								<div className="col-md-12 box-col-12">
									<div className="row">
										<div className="card">
											<div
												className="col-md-12 row"
												style={{
													padding: "10px",
												}}
											>
												<div className="col-md-5 ">
													<label className> Date From</label>
													<input
														type="date"
														className="form-control"
														name="datefrom"
														{...register("datefrom", {
															required: true,
														})}
														onChange={(e) => {
															setFormData({
																...formData,
																datefrom: e.target.value,
															});
														}}
														defaultValue={defaultDate}
														value={formData.datefrom}
													/>
												</div>
												<div className="col-md-5">
													<div className="form-group">
														<label htmlFor="transaction_total_volume">
															Date To
														</label>
														<input
															type="date"
															className="form-control"
															id="dateto"
															name="dateto"
															{...register("dateto", {
																required: true,
															})}
															onChange={(e) => {
																setFormData({
																	...formData,
																	dateto: e.target.value,
																});
															}}
															defaultValue={defaultDate}
															value={formData.dateto}
														/>
													</div>
												</div>
												<div className="col-md-2">
													<div className="form-group">
														<label htmlFor="transaction_total_volume"></label>
														<button
															type="button"
															className="btn btn-primary"
															style={{
																marginTop: "30px",
															}}
															onClick={getCount}
														>
															Search
														</button>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-3">
											<div className="card profit-card">
												<div className="card-header pb-0">
													<div className="d-flex justify-content-between">
														<div className="flex-grow-1">
															<p className="square-after f-w-600 header-text-primary">
																Total Sale
																<i className="fa fa-circle" />
															</p>

															<h4>{totalSale} د.إ</h4>
														</div>
													</div>
												</div>

												<div className="card-body">
													<div className="d-flex">
														<div className="flex-grow-1">
															<div className="profit-wrapper header-text-primary icon-bg-primary">
																<i className="fa fa-arrow-up" />
															</div>
															{/* <h6 className="header-text-primary">- %</h6>
														<p className="mb-0">More Than last month</p> */}
														</div>
													</div>
													<div className="right-side icon-right-primary">
														<i className="fa fa-usd" />
														<div className="shap-block">
															<div className="rounded-shap animate-bg-primary">
																<i />
																<i />
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-3">
											<div className="card sell-card">
												<div className="card-header pb-0">
													<div className="d-flex justify-content-between">
														<div className="flex-grow-1">
															<p className="square-after f-w-600 header-text-success">
																Total Purchase
																<i className="fa fa-circle" />
															</p>
															<h4>{totalPurchase} د.إ</h4>
														</div>
													</div>
												</div>
												<div className="card-body">
													<div className="d-flex">
														<div className="flex-grow-1">
															<div className="profit-wrapper header-text-success icon-bg-success">
																<i className="fa fa-arrow-up" />
															</div>
															{/* <h6 className="header-text-success">- %</h6>
														<p className="mb-0">Look Pretty Good</p> */}
														</div>
													</div>
													<div className="right-side icon-right-success">
														<i className="fa fa-shopping-basket" />
														<div className="shap-block">
															<div className="rounded-shap animate-bg-secondary">
																<i />
																<i />
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-3">
											<div className="card visitor-card">
												<div className="card-header pb-0">
													<div className="d-flex justify-content-between">
														<div className="flex-grow-1">
															<p className="square-after f-w-600 header-text-info">
																Total Expense
																<i className="fa fa-circle" />
															</p>
															<h4>{totalExpense} د.إ</h4>
														</div>
													</div>
												</div>
												<div className="card-body">
													<div className="d-flex">
														<div className="flex-grow-1">
															<div className="profit-wrapper header-text-info icon-bg-info">
																<i className="fa fa-arrow-up" />
															</div>
															{/* <h6 className="header-text-info">- %</h6>
														<p className="mb-0">More Than last month</p> */}
														</div>
													</div>
													<div className="right-side icon-right-info">
														<i className="fa fa-usd" />
														<div className="shap-block">
															<div className="rounded-shap animate-bg-primary">
																<i />
																<i />
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className="col-md-3">
											<div className="card sell-card">
												<div className="card-header pb-0">
													<div className="d-flex justify-content-between">
														<div className="flex-grow-1">
															<p className="square-after f-w-600 header-text-success">
																Total Customers
																<i className="fa fa-circle" />
															</p>
															<h4>{totalCustomer}</h4>
														</div>
													</div>
												</div>
												<div className="card-body">
													<div className="d-flex">
														<div className="flex-grow-1">
															<div className="profit-wrapper header-text-success icon-bg-success">
																<i className="fa fa-arrow-up" />
															</div>
															{/* <h6 className="header-text-success"> - %</h6>
														<p className="mb-0">Look Pretty Good</p> */}
														</div>
													</div>
													<div className="right-side icon-right-success">
														<i className="fa fa-user" />
														<div className="shap-block">
															<div className="rounded-shap animate-bg-secondary">
																<i />
																<i />
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="container-fluid">
							<div className="row">
								{accountType === "customer" ? (
									""
								) : (
									<div className="col-sm-12 col-xl-6 box-col-6">
										<div className="card" style={{ height: "540px" }}>
											<div className="card-header pb-0">
												<h4>Top 5 Fuel Out By Tank</h4>
											</div>
											<div className="card-body">
												{topFuelUsage.length > 0 ? (
													<TopFuelUsage
														series={topFuelUsage.map(
															(item) => item.total_volume
														)}
														categories={topFuelUsage.map(
															(item) => item.tank_data.description
														)}
													/>
												) : (
													<p>Loading...</p>
												)}
											</div>
										</div>
									</div>
								)}
								{accountType === "customer" ? (
									""
								) : (
									<div className="col-sm-12 col-xl-6 box-col-6">
										<div
											className="card"
											style={{
												height: "536px",
											}}
										>
											<div className="card-header pb-0">
												<h4>Transaction Payment</h4>
											</div>
											<div className="card-body">
												{transactionHistory.length > 0 ? (
													<TransactionHistory
														series={transactionHistory.map(
															(item) => item.amount
														)}
														categories={transactionHistory.map(
															(item) => item.payment_date
														)}
													/>
												) : (
													<p>Loading...</p>
												)}
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>

				<Footer />
			</div>
		</div>
	);
}
