import React, { useEffect, useState } from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import DataTable from "datatables.net-dt";
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomers } from "../../redux/Customers";
import { getAllCustomers } from "../../redux/api/apiCustomer";
import { getPaymentReport } from "../../redux/api/apiReports";
import { paymentSelector } from "../../redux/Reports";

import { useNavigate } from "react-router-dom";
export default function Taxpayments() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const customerList = useSelector(selectCustomers);
	const customerLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				customerList.filter((i) =>
					i.cus_name.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};
	const custmerValue = (e) => {
		if (e === null) {
			setFilterList({
				...filterList,
				cus_id: "",
			});
			return;
		}
		setFilterList({
			...filterList,
			cus_id: e.id,
		});
	};
	const [currentDate, setCurrentDate] = useState(new Date());
	let defaultDate = new Date();
	defaultDate.setDate(defaultDate.getDate() + 3);
	const [filterList, setFilterList] = useState({
		datefrom: "",
		dateto: "",
		cus_id: "",
	});
	let table = new DataTable("#payemnt-table");
	const dispatch = useDispatch();
	const paymentList = useSelector(paymentSelector);

	useEffect(() => {
		dispatch(getPaymentReport());
		dispatch(getAllCustomers());
	}, [dispatch]);

	useEffect(() => {
		table.destroy();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		table = new DataTable("#payemnt-table", {
			data: paymentList,
			columns: [
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},

				{
					data: null,
					render: function (data, type) {
						if (data.payment_date) {
							return data.payment_date;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.customer) {
							return data.customer.cus_name;
						}
						return "";
					},
				},

				{
					data: null,
					render: function (data, type) {
						if (data.invoice) {
							return data.invoice.invoice_no;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.account) {
							return data.account.account_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.payment_method) {
							return data.payment_method;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.cheque_no) {
							return data.cheque_no;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.receipt_no) {
							return data.receipt_no;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.amount) {
							return data.amount;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.notes) {
							return data.notes;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.created_who) {
							return data.created_who.name;
						}
						return "";
					},
				},

				{
					data: "id",
					render: function (data, type, row) {
						return `
				<div class="btn-group">
				<button type="button" class="btn btn-success " onClick="Print(${data})">
				  Print
				</button>
			
							</div>
									`;
					},
				},
			],
		});
	}, [paymentList]);
	const [exportToPdf, setExportToPdf] = useState(false);
	const getPaymentList = () => {
		setExportToPdf(false);
		Swal.fire(
			"Loading...",
			"Please wait while we are fetching the data",
			"info"
		);
		Swal.showLoading();

		dispatch(
			getPaymentReport(filterList, (res) => {
				if (res) {
					Swal.close();
					setExportToPdf(true);
				}
			})
		);
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	}
	accountType = accountType.replace(/['"]+/g, "");
	const logoos = localStorage.getItem("c_logo");
	var logo = "";
	if (logoos === null) {
		logo = "";
	} else {
		logo = process.env.REACT_APP_IMAGE_URL + logoos.replace(/['"]+/g, "");
	}

	var company_name = localStorage.getItem("c_name");
	if (company_name) {
		company_name = company_name.replace(/['"]+/g, "");
	} else {
		company_name = "";
	}

	var company_address = localStorage.getItem("c_address");
	if (company_address) {
		company_address = company_address.replace(/['"]+/g, "");
	} else {
		company_address = "";
	}
	var company_phone = localStorage.getItem("c_phone");
	if (company_phone) {
		company_phone = company_phone.replace(/['"]+/g, "");
	} else {
		company_phone = "";
	}
	var company_email = localStorage.getItem("c_email");
	if (company_email) {
		company_email = company_email.replace(/['"]+/g, "");
	} else {
		company_email = "";
	}
	const navigate = useNavigate();
	window.Print = async function (id) {
		navigate("/tax/payments/downlaod/" + id);
		// 		try {
		// 			const printArea = document.createElement("div");
		// 			// Assuming you have a function to fetch payment info by ID
		// 			const paymentInfo = await getPaymentInfoById(id);
		// 			if (paymentInfo) {
		// 				printArea.innerHTML = `
		// <div class="col-lg-12" style="text-align:center">
		//     <div class="invoice p-3 mb-3">
		//         <div class="row invoice-info" style="margin-left:18px">
		//             <div class="col-sm-4 invoice-col" >
		//                 <div class="company-logo" style="margin-left:40px">
		//                     <img src="/1689772513.png" alt="logo" style="height:200px; width:250px;" class="lg-logo">
		//                 </div>
		//             </div>
		//             <div class="col-sm-8 invoice-col" style="margin-left:25px">
		//                 <address>
		//                  ${company_phone}<br>
		//                  ${company_email}<br>
		//                 ${company_address}
		//                 </address>
		//             </div>
		//         </div>
		//         <hr>
		//         <!-- Additional content can be added here -->
		//     </div>
		// </div>
		//     `;
		// 				const opt = {
		// 					margin: 1,
		// 					filename: "PaymentSlip.pdf",
		// 					image: { type: "jpeg", quality: 0.98 },
		// 					html2canvas: { scale: 2 },
		// 					jsPDF: { unit: "in", format: [7, 15], orientation: "portrait" },
		// 				};
		// 				// Assuming html2pdf is properly imported and initialized
		// 				html2pdf().set(opt).from(printArea).save();
		// 			}
		// 		} catch (error) {
		// 			console.error("An error occurred:", error);
		// 		}
	};

	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3> Payment</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="index.html">
												<i data-feather="home" />
											</a>
										</li>
										<li className="breadcrumb-item">Reports</li>
										<li className="breadcrumb-item active"> Payment</li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											{accountType === "admin" ? (
												<div className="col-md-4">
													<div className="form-group">
														<label htmlFor="department_id">Client *</label>
														<Controller
															name="cus_id"
															control={control}
															rules={{ required: true }}
															render={({ field }) => (
																<AsyncSelect
																	{...field}
																	isClearable
																	defaultOptions={customerList}
																	loadOptions={customerLoadOptions}
																	getOptionLabel={(option) => option.cus_name}
																	getOptionValue={(option) => option.id}
																	placeholder="Select Customer"
																	value={customerList.find(
																		(obj) => obj.id === field.value
																	)}
																	onChange={(e) => {
																		field.onChange(e);
																		custmerValue(e);
																	}}
																/>
															)}
														/>
													</div>
												</div>
											) : (
												""
											)}

											<div className="col-md-4">
												<div className="form-group">
													<label htmlFor="vehicle">DateFrom</label>
													<input
														type="date"
														className="form-control"
														id="dateFrom"
														onChange={(e) =>
															setFilterList({
																...filterList,
																datefrom: e.target.value,
															})
														}
														defaultValue={
															currentDate.getFullYear() /
															currentDate.getMonth() /
															currentDate.getDate()
														}
													/>
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group">
													<label htmlFor="vehicle">DateTo</label>
													<input
														type="date"
														className="form-control"
														id="dateTo"
														onChange={(e) =>
															setFilterList({
																...filterList,
																dateto: e.target.value,
															})
														}
														defaultValue={
															currentDate.getFullYear() /
															currentDate.getMonth() /
															currentDate.getDate()
														}
													/>
												</div>
											</div>
											<div
												className="col-md-2 mt-4"
												style={{
													display: "inline-grid",
													marginLeft: "10px",
													height: "10px",
												}}
											>
												<button
													type="button"
													className="btn btn-primary"
													onClick={getPaymentList}
												>
													Search
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table
												className="display"
												id="payemnt-table"
												style={{
													width: "100%",
													textAlign: "center",
													border: "1px solid #dee2e6",
												}}
											>
												<thead>
													<tr>
														<th>Sl no</th>
														<th> Recived on</th>
														<th>Customer</th>
														<th>Invoice Number</th>

														<th>Account</th>
														<th>Payment Method</th>
														<th>Cheque No</th>
														<th>Reciept NO</th>

														<th> Paid Amount</th>
														<th>Notes</th>
														<th>Recieved By</th>
														<th>Print</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
