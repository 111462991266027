import axios from "axios";
import Swal from "sweetalert2";
import {
	getUsers,
	getUserPermission,
	getAccountType,
	getCompanyInfo,
} from "../Users";

const apiUser = axios.create({
	baseURL: process.env.REACT_APP_BASEURL + "users",
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",

		"Access-Control-Allow-Origin": "*",
		"X-Server-ID": process.env.REACT_APP_SERVER_ID,
	},
});

apiUser.interceptors.request.use((config) => {
	const token = localStorage.getItem("token").replace(/['"]+/g, "");
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	return config;
});
//  insrt user
export const createUser = (payload, callback) => (dispatch) => {
	apiUser
		.post("/insert", payload)
		.then((res) => {
			if (res.status === 200) {
				callback(res.data);
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});
};

//  get user

export const fetchUsers = () => (dispatch) => {
	apiUser
		.get("/fetch")
		.then((res) => {
			if (res.status === 200) {
				dispatch(getUsers(res.data.data));
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});
};

// delete user

export const deleteUser = (id, callback) => (dispatch) => {
	apiUser
		.delete("/delete/" + id)
		.then((res) => {
			if (res.status === 200) {
				callback(res.data);
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});
};

//  get user by id

export const fetchUserById = (id, callback) => (dispatch) => {
	apiUser
		.get("/get/" + id)
		.then((res) => {
			if (res.status === 200) {
				callback(res.data);
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});
};

// update user by id

export const updateUser = (id, payload, callback) => (dispatch) => {
	apiUser
		.post("/update/" + id, payload)
		.then((res) => {
			if (res.status === 200) {
				callback(res.data);
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});
};

//  get user permission

export const fetchUserPermission = () => (dispatch) => {
	apiUser.get("/permisison/list").then((res) => {
		dispatch(getAccountType(res.data.account_type));
		if (res.data.account_type === "admin") {
			dispatch(getUserPermission(res.data.data));
		} else {
			if (res.status === 200) {
				if (res.data) {
					var perm = res.data.data;
					perm = perm.split(",");

					dispatch(getUserPermission(perm));
				}
			}
		}
	});
};

// dashboardCount
export const dashboardCount = (payload, callback) => (dispatch) => {
	apiUser
		.post("/dashboard/count", payload)
		.then((res) => {
			if (res.status === 200) {
				callback(res.data.data);
			}
		})
		.catch((err) => {
			// console.log(err);
		});
};
export const remaining_count = (payload, callback) => (dispatch) => {
	apiUser
		.get("/dashboard/remaining_count", payload)
		.then((res) => {
			// console.log(res.data.status);
			if (res.data.status === 200) {
				callback(res.data.data);
			}
		})
		.catch((err) => {
			// console.log(err);
		});
};

// update company info

export const updateCompanyInfo = (payload, callback) => (dispatch) => {
	apiUser
		.post("/update/company/info", payload)
		.then((res) => {
			if (res.status === 200) {
				callback(res.data);
			}
		})
		.catch((err) => {
			// console.log(err);
		});
};

// fetch company info

export const fetchCompanyInfo = (callback) => (dispatch) => {
	apiUser
		.get("/fetch/company/info")
		.then((res) => {
			if (res.status === 200) {
				callback(res.data);
				dispatch(getCompanyInfo(res.data));
			}
		})
		.catch((err) => {
			// console.log(err);
		});
};

// update password

export const updateChangePassword = (payload, callback) => (dispatch) => {
	apiUser
		.post("/change/cr", payload)
		.then((res) => {
			if (res.status === 200) {
				callback(res.data);
			}
		})
		.catch((err) => {
			// console.log(err);
		});
};

// check type

export const checkAccountType = (payload, callback) => (dispatch) => {
	apiUser
		.post("/type/validate", payload)
		.then((res) => {
			// console.log(res);
			if (res.status === 200) {
				callback(res.data);
			}
		})
		.catch((err) => {
			// console.log(err);
		});
};
