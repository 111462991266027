import React, { useEffect, useState, useRef } from "react";
import html2pdf from "html2pdf.js";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllSales } from "../../redux/api/apiSale";
import DataTable from "datatables.net-dt";
import jsPDF from "jspdf";
export default function Transaction() {
	// const datefrom = searchParams.get("datefrom");
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const datefrom = searchParams.get("datefrom");
	const dateto = searchParams.get("dateto");
	const cus_id = searchParams.get("cus_id");
	const [reports, setReports] = useState([]);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			getAllSales(
				{
					datefrom: datefrom,
					dateto: dateto,
					cus_id: cus_id,
				},
				(res) => {
					setReports(res.data);

					// console.log(res);
				}
			)
		);
	}, [dispatch, cus_id, datefrom, dateto]);
	let table = new DataTable("#report-table");
	useEffect(() => {
		table.destroy();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		table = new DataTable("#report-table", {
			data: reports,

			searching: false,
			paging: false,
			info: false,

			columns: [
				//  auto increment id column
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},

				{
					data: null,
					render: function (data, type) {
						if (data.do_number) {
							return '<p style="color: red">' + data.do_number + "</p>";
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.quantity) {
							return data.quantity;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.transaction_begining_time) {
							return data.transaction_begining_time;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.customer) {
							return data.customer.cus_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.vehicle) {
							return data.vehicle.vehicle_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.vehicle) {
							return data.vehicle.vehicle_number;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.vehicle) {
							return data.vehicle.vehicle_plate_number;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.vehicle_code) {
							return data.vehicle_code;
						}
						return "";
					},
				},

				{
					data: null,
					render: function (data, type) {
						if (data.odometer_value) {
							return data.odometer_value;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.meter_value) {
							return data.meter_value;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.payment_info) {
							return data.payment_info.amount;
						}
						return "";
					},
				},

				{
					data: null,
					render: function (data, type) {
						if (data.payment_type) {
							return data.payment_type;
						}
						return "";
					},
				},
			],
		});
	}, [reports, table]);
	const [exportToPdf, setExportToPdf] = useState(false);
	const handleConvertToPdf = () => {
		document.getElementById("print").style.display = "none";
		setExportToPdf(true);
		// // hide the button

		// const element = document.getElementById("canva-component"); // Replace 'canva-component' with the ID of your Canva component

		// // how to remove datatables while exporting to pdf

		// html2pdf()
		// 	.set({
		// 		margin: 0.5,
		// 		filename: "transaction_reports.pdf",
		// 		image: { type: "jpeg", quality: 0.98 },
		// 		html2canvas: { scale: 2 },
		// 		jsPDF: {
		// 			unit: "in",
		// 			format: [20.4, 20],
		// 			orientation: "landscape",
		// 		},
		// 	})
		// 	.from(element)
		// 	.save();
		window.print();
		setExportToPdf(false);
		document.getElementById("print").style.display = "block";
	};
	return (
		<div className="col-sm-12">
			<div
				className="col-md-12"
				style={{
					textAlign: "center",
				}}
			>
				<button
					onClick={handleConvertToPdf}
					style={{
						display: exportToPdf ? "none" : "block",
					}}
					class="btn btn-primary"
					id="print"
				>
					Convert to PDF
				</button>
			</div>

			<div className="card" id="canva-component">
				<div className="card-body">
					<div className="row">
						<div className="col-lg-12">
							<div className="invoice p-3 mb-3">
								<div className="row invoice-info">
									<div className="col-sm-4 invoice-col">
										<div className="company-logo">
											<img
												src="/creation_billing.png"
												alt="Acculance"
												className="lg-logo"
												style={{ width: "160px" }}
											/>
										</div>
									</div>

									<div
										className="col-md-12   "
										style={{
											textAlign: "center",
										}}
									>
										<h2>Transaction Report</h2>
									</div>
									<div className="col-md-12   ">
										<b>Date From:</b>
										{datefrom}
										<br />
										<b>Date To:</b>
										{dateto ? dateto : "Till Date"}
									</div>
								</div>
								<hr />
								<div className="row mt-5 position-relative">
									<div
										className="position-absolute w-100 h-100 opacity-50 z-10 tbl-loading"
										style={{ display: "none" }}
									>
										<div className="loader07" />
									</div>
									<div className="table-responsive">
										<table className="table table-sm" id="report-table">
											<thead>
												<tr>
													<th>Sl no</th>

													<th>Do No</th>
													<th>Quantity</th>
													<th>Date</th>
													<th>Customer Name</th>
													<th>Vehicle Name</th>
													<th>Vehicle Number</th>
													<th>Vehicle Plate Number</th>
													<th>Vehicle Code</th>

													<th>Odometer</th>
													<th>Meter Value</th>
													<th>Amount</th>
													<th>Transaction Type</th>
												</tr>
											</thead>
											<tbody></tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
