import axios from "axios";

import Swal from "sweetalert2";
import { getPurchase } from "../../redux/Purchase";
const apiPurchase = axios.create({
	baseURL: process.env.REACT_APP_BASEURL + "purchase",
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",

		"Access-Control-Allow-Origin": "*",
		"X-Server-ID": process.env.REACT_APP_SERVER_ID,
	},
});

apiPurchase.interceptors.request.use((config) => {
	const token = localStorage.getItem("token").replace(/['"]+/g, "");
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	return config;
});

// insert purchase
export const insertPurchase = (payload, callback) =>
	apiPurchase
		.post(`/insert`, payload, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		})

		.then((res) => {
			// console.log(res);
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			} else {
				callback(res.data);
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

// get purchase
export const getPurchaseList = (payload, callback) => (dispatch) =>
	apiPurchase.get("/fetch", payload).then((res) => {
		// console.log(res);
		if (res.status === 200) {
			if (callback) {
				callback(res.data);
			}

			dispatch(getPurchase(res.data.data));
		}
	});

// get purchase by id
export const getPurchaseById = (id, callback) =>
	apiPurchase.get(`/fetch/${id}`).then((res) => {
		if (res.data.status === 200) {
			// console.log(res.data.data);
			if (callback) {
				callback(res.data.data);
			}
		}
	});

// update purchase
export const updatePurchase = (id, payload, callback) =>
	apiPurchase
		.post(`/update/${id}`, payload)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data.status);
				}
			} else {
				callback(res.data);
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

// delete purchase
export const deletePurchase = (id, callback) =>
	apiPurchase
		.delete(`/delete/${id}`)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data.status);
				}
			} else {
				callback(res.data);
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

// remainining balance by id
export const getRemainingPurchaseBalance = (id, callback) =>
	apiPurchase.get(`/remainingBalance/${id}`).then((res) => {
		if (res.data.status === 200) {
			// console.log(res.data.data);
			if (callback) {
				callback(res.data.data);
			}
		}
	});
// save supplier payment
export const saveSupplierPayment = (payload, callback) =>
	apiPurchase
		.post(`/saveSupplierPayment`, payload)
		.then((res) => {
			// console.log(res);
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			} else {
				callback(res.data);
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});
