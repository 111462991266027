import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import DataTable from "datatables.net-dt";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Modal from "react-modal";
import { useForm } from "react-hook-form";

import {
	insertSupplier,
	getSupplierList,
	getSupplierById,
	updateSupplierByID,
	deleteSupplier,
} from "../../redux/api/apiSupplier";
import { supplierSelector } from "../../redux/Supplier";
Modal.setAppElement("#root");
export default function Supplier() {
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const supplierList = useSelector(supplierSelector);
	useEffect(() => {
		dispatch(getSupplierList());
	}, [dispatch]);
	const [formData, setFormData] = useState({
		sp_id: "",
		sp_name: "",
		sp_contact_no: "",
		sp_email: "",
		sp_address: "",
	});
	const [modalIsOpen, setIsOpen] = useState(false);
	function openModal() {
		reset({
			sp_id: "",
			sp_name: "",
			sp_contact_no: "",
			sp_email: "",
			sp_address: "",
		});
		setIsOpen(true);
	}
	function closeModal() {
		setEditID("");
		reset({
			sp_id: "",
			sp_name: "",
			sp_contact_no: "",
			sp_email: "",
			sp_address: "",
		});
		setIsOpen(false);
	}
	const [editID, setEditID] = useState("");
	let table = new DataTable("#supplier-table");
	useEffect(() => {
		// console.log(priceList);
		table.destroy();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		table = new DataTable("#supplier-table", {
			data: supplierList,
			columns: [
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},
				{
					data: null,
					render: function (data, type, row) {
						if (row.sp_id) {
							return row.sp_id;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type, row) {
						if (row.sp_name) {
							return row.sp_name;
						}
						return "";
					},
				},

				{
					data: null,
					render: function (data, type, row) {
						if (row.sp_contact_no) {
							return row.sp_contact_no;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type, row) {
						if (row.sp_email) {
							return row.sp_email;
						}
						return "";
					},
				},

				{
					data: "id",
					render: function (data, type, row) {
						return `
                <div class="btn-group">
                <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  Action
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#" onClick="editSupplier(${data})">Edit</a></li>
                  <li><a class="dropdown-item" href="#" onClick="deleteSupplier(${data})">Delete</a></li>
                </ul>
              </div>


                `;
					},
				},
			],
		});
	}, [supplierList]);

	function createSupplier(data) {
		dispatch(
			insertSupplier(data, (res) => {
				reset();
				if (res === 200) {
					closeModal();
					Swal.fire({
						title: "Success!",
						text: res.message,
						icon: "success",
						confirmButtonText: "OK",
					}).then((result) => {
						if (result.isConfirmed) {
							reset();
							dispatch(getSupplierList());
						}
					});
				} else {
					closeModal();
					Swal.fire({
						title: "Error!",
						text: res.message,
						icon: "error",
						confirmButtonText: "OK",
					});
				}
			})
		);
	}
	const updateSupplier = (data) => {
		// console.log(data);
		dispatch(
			updateSupplierByID(editID, data, (res) => {
				if (res.status === 200) {
					closeModal();
					Swal.fire({
						title: "Success!",
						text: res.message,
						icon: "success",
						confirmButtonText: "OK",
					}).then((result) => {
						if (result.isConfirmed) {
							reset();
							dispatch(getSupplierList());
						}
					});
				}
			})
		);
	};

	window.editSupplier = (id) => {
		setEditID(id);

		dispatch(
			getSupplierById(id, (res) => {
				openModal();

				reset({
					sp_id: res.sp_id,
					sp_name: res.sp_name,
					sp_contact_no: res.sp_contact_no,
					sp_email: res.sp_email,
					sp_address: res.sp_address,
				});
			})
		);
	};

	window.deleteSupplier = function (id) {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(
					deleteSupplier(id, (res) => {
						if (res.status === 200) {
							Swal.fire("Deleted!", res.message, "success").then((result) => {
								if (result.isConfirmed) {
									dispatch(getSupplierList());
								}
							});
						}
					})
				);
			}
		});
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Supplier</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a
												href="index.html"
												data-bs-original-title="data-bs-original-title"
												title="title"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-home"
												>
													<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
													<polyline points="9 22 9 12 15 12 15 22" />
												</svg>
											</a>
										</li>
										<li className="breadcrumb-item">Manage</li>
										<li className="breadcrumb-item active">Supplier</li>
									</ol>
								</div>
							</div>
						</div>
					</div>
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						contentLabel="Example Modal"
						className="modal-dialog modal-dialog-centered"
						closeTimeoutMS={500}
						style={{
							//  width 1200px
							overlay: {
								backgroundColor: "rgba(0, 0, 0, 0.5)",
								zIndex: 9999,
							},
						}}
					>
						<div
							className="modal-content"
							style={{ width: "800px !important;" }}
						>
							<div className="modal-header">
								<h5 className="modal-title">
									{editID ? "Update" : "Add New"} Supplier
								</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
									onClick={closeModal}
								/>
							</div>
							<form
								onSubmit={handleSubmit(
									editID ? updateSupplier : createSupplier
								)}
							>
								<div className="modal-body">
									<div className="col-md-12">
										<div className="row">
											<div className="col-md-6 ">
												<label className>Supplier ID (optional)</label>
												<input
													type="text"
													className="form-control"
													name="sp_id"
													onChange={(e) => {
														setFormData({
															...formData,
															sp_id: e.target.value,
														});
													}}
													{...register("sp_id")}
												/>
											</div>
											<div className="col-md-6 ">
												<label className>Supplier Name</label>
												<input
													type="text"
													className="form-control"
													name="sp_name"
													onChange={(e) => {
														setFormData({
															...formData,
															sp_name: e.target.value,
														});
													}}
													{...register("sp_name", {
														required: true,
													})}
												/>
												{errors.sp_name && (
													<span className="text-danger">
														This field is required
													</span>
												)}
											</div>
											<div className="col-md-6 ">
												<label className>Contact Number one </label>
												<input
													type="text"
													className="form-control"
													name="sp_contact_no"
													onChange={(e) => {
														setFormData({
															...formData,
															sp_contact_no: e.target.value,
														});
													}}
													{...register("sp_contact_no", {
														required: true,
													})}
												/>
												{errors.sp_contact_no && (
													<span className="text-danger">
														This field is required
													</span>
												)}
											</div>

											<div className="col-md-6 ">
												<label className>Email </label>
												<input
													type="text"
													className="form-control"
													name="sp_email"
													onChange={(e) => {
														setFormData({
															...formData,
															sp_email: e.target.value,
														});
													}}
													{...register("sp_email", {
														required: true,
													})}
												/>
												{errors.sp_email && (
													<span className="text-danger">
														This field is required
													</span>
												)}
											</div>

											<div className="col-md-12 ">
												<label className>Address (optional)</label>
												<textarea
													className="form-control"
													name="sp_address"
													placeholder="Enter Address"
													onChange={(e) => {
														setFormData({
															...formData,
															sp_address: e.target.value,
														});
													}}
													{...register("sp_address")}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-secondary"
										data-bs-dismiss="modal"
										onClick={closeModal}
									>
										Close
									</button>
									<button type="submit" className="btn btn-primary">
										Save changes
									</button>
								</div>
							</form>
						</div>
					</Modal>
					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<button
											class="btn btn-primary"
											type="button"
											onClick={openModal}
										>
											ADD SUPPLIER
										</button>
										<div className="table-responsive">
											<table className="display" id="supplier-table">
												<thead>
													<tr>
														<th>Sl no</th>
														<th>Supplier ID</th>
														<th>Supplier Name</th>
														<th>Contact Number</th>
														<th>Email</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
