import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import DataTable from "datatables.net-dt";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import {
	insertCategory,
	getCategoryList,
	getCategoryById,
	updateCategoryByID,
	deleteCategoryByID,
} from "../../redux/api/apiExpense";
import { categorySelector } from "../../redux/Expense";
export default function Category() {
	const dispatch = useDispatch();
	const category = useSelector(categorySelector);
	useEffect(() => {
		dispatch(getCategoryList());
	}, []);
	const [modalIsOpen, setIsOpen] = useState(false);
	const [editID, setEditID] = useState("");
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	function openModal() {
		setIsOpen(true);
		reset({
			cat_name: "",
			is_active: "",
			notes: "",
		});
	}
	const [formData, setFormData] = useState({
		cat_name: "",
		is_active: "",
		notes: "",
	});

	function closeModal() {
		setIsOpen(false);
		reset();
		setEditID("");
	}

	const addCategory = (data) => {
		closeModal();
		reset();
		Swal.fire({
			title: "Please wait...",
			html: "Saving data",
			allowOutsideClick: false,
			onBeforeOpen: () => {
				Swal.showLoading();
			},
		});
		insertCategory(data, (res) => {
			if (res.status === 200) {
				Swal.fire({
					icon: "success",
					title: "Success",
					text: res.message,
				});
				dispatch(getCategoryList());
			}
		});
	};
	const updateCategory = (data) => {
		closeModal();
		reset();
		Swal.fire({
			title: "Please wait...",
			html: "Saving data",
			allowOutsideClick: false,
			onBeforeOpen: () => {
				Swal.showLoading();
			},
		});
		updateCategoryByID(editID, data, (res) => {
			if (res.status === 200) {
				Swal.fire({
					icon: "success",
					title: "Success",
					text: res.message,
				});
				dispatch(getCategoryList());
			}
		});
	};
	let table = new DataTable("#category-table");
	useEffect(() => {
		table.destroy();
		table = new DataTable("#category-table", {
			data: category,
			columns: [
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.cat_name) {
							return data.cat_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.is_active === 1) {
							return "Active";
						}
						return "Inactive";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.notes) {
							return data.notes;
						}
						return "";
					},
				},
				{
					data: "id",
					render: function (data, type, row) {
						return `
                <div class="btn-group">
                <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  Action
                </button>
                <ul class="dropdown-menu">
				  <li><a class="dropdown-item" href="#" onClick="editCategory(${data})">Edit</a></li>
				  <li><a class="dropdown-item" href="#" onClick="deleteCategory(${data})">Delete</a></li>
					</ul>
						</div>
								`;
					},
				},
			],
		});
	}, [category]);

	window.editCategory = (id) => {
		setEditID(id);
		openModal();
		dispatch(
			getCategoryById(id, (res) => {
				setFormData({
					cat_name: res.cat_name,
					is_active: res.is_active,
					notes: res.notes,
				});

				reset({
					cat_name: res.cat_name,
					is_active: res.is_active,
					notes: res.notes,
				});
			})
		);
	};

	window.deleteCategory = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(
					deleteCategoryByID(id, (res) => {
						if (res.status === 200) {
							Swal.fire({
								icon: "success",
								title: "Success",
								text: res.message,
							});
							dispatch(getCategoryList());
						}
					})
				);
			} else {
				Swal.fire({
					icon: "error",
					title: "Cancelled",
					text: "Your data is safe :)",
				});
			}
		});
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Expense Category</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="index.html">
												<i data-feather="home" />
											</a>
										</li>
										<li className="breadcrumb-item">Expense</li>
										<li className="breadcrumb-item active"> Category </li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						contentLabel="Example Modal"
						className="modal-dialog modal-dialog-centered"
						closeTimeoutMS={500}
						style={{
							//  width 1200px
							overlay: {
								backgroundColor: "rgba(0, 0, 0, 0.5)",
								zIndex: 9999,
							},
						}}
					>
						<div
							className="modal-content"
							style={{
								width: "1200px",
								margin: "0 auto",
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title">
									{editID ? "Edit Category" : "Add Category"}
								</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
									onClick={closeModal}
								/>
							</div>
							<form
								onSubmit={handleSubmit(editID ? updateCategory : addCategory)}
							>
								<div className="modal-body">
									<div className="col-md-12 row">
										<div className="col-md-6">
											<label>Category Name</label>
											<input
												type="text"
												className="form-control"
												name="cat_name"
												id="cat_name"
												placeholder="Enter Category Name"
												{...register("cat_name", {
													required: "Category Name is required",
												})}
											/>
											{errors.cat_name && (
												<span className="text-danger">
													{errors.cat_name.message}
												</span>
											)}
										</div>
										<div className="col-md-6">
											<label>Status</label>
											<select
												className="form-control"
												name="is_active"
												id="is_active"
												{...register("is_active")}
											>
												<option value="">Select Status</option>
												<option value="1">Active</option>
												<option value="0">Inactive</option>
											</select>
										</div>
										<div className="col-md-12">
											<label>Notes</label>
											<textarea
												className="form-control"
												name="notes"
												id="notes"
												placeholder="Enter Notes"
												defaultValue={""}
												{...register("notes")}
											/>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-secondary"
										data-bs-dismiss="modal"
										onClick={closeModal}
									>
										Close
									</button>
									<button type="submit" className="btn btn-primary">
										Save changes
									</button>
								</div>
							</form>
						</div>
					</Modal>

					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<button
											class="btn btn-primary"
											type="button"
											onClick={openModal}
										>
											Add Category
										</button>

										<div className="table-responsive">
											<table
												className="display"
												id="category-table"
												style={{
													width: "100%",
													textAlign: "center",
													border: "1px solid #dee2e6",
												}}
											>
												<thead>
													<tr>
														<th>Sl no</th>
														<th>Category Name</th>
														<th>Status</th>
														<th>Notes</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
