import { createSlice } from "@reduxjs/toolkit";

export const customersSlice = createSlice({
    name: "customers",
    initialState: {
        customers: [],
        price: [],
        tags: [],
        vehicle: [],
        tanks: [],
        

    },
    reducers: {
        setCustomers: (state, action) => {
            state.customers = action.payload;
      
        },
        updateCustomer: (state, action) => {
            state.customers = state.customers.map((customer) => {
                if (customer._id === action.payload._id) {
                    return action.payload;
                } else {
                    return customer;
                }
            });
        },
        deleteCustomer: (state, action) => {
            state.customers = state.customers.filter(
                (customer) => customer._id !== action.payload._id
            );
        },
        getPrice: (state, action) => {
            state.price = action.payload;
        },
        getTags: (state, action) => {
            state.tags = action.payload;
        },
        getCustomerVehicle: (state, action) => {
            state.vehicle = action.payload;
        },
        getTank: (state, action) => {
            state.tanks = action.payload;
        }


      
    },
});

export const { setCustomers,getPrice,getTags,getCustomerVehicle,getTank } = customersSlice.actions;

export const selectCustomers = (state) => state.customers.customers;
export const selectPrice = (state) => state.customers.price;
export const selectTags = (state) => state.customers.tags;
export const selectCustomerByVehicle = (state) => state.customers.vehicle;
export const selectTanks = (state) => state.customers.tanks;


export default customersSlice.reducer;



