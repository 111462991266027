import React, { useEffect } from "react";
import Footer from "../dashboard/components/Footer";
import Sidebar from "../dashboard/components/Sidebar";
import Header from "../dashboard/components/Header";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "datatables.net-dt";
import {
	getCategoryList,
	getExpenseList,
	deleteExpenseByID,
} from "../../redux/api/apiExpense";
import { categorySelector, expenseSelector } from "../../redux/Expense";
import { useNavigate } from "react-router-dom";
export default function Manage() {
	const dispatch = useDispatch();
	const category = useSelector(categorySelector);
	const expense = useSelector(expenseSelector);
	const navigate = useNavigate();
	useEffect(() => {
		dispatch(getCategoryList());
		dispatch(getExpenseList());
	}, []);
	let table = new DataTable("#expense-table");
	useEffect(() => {
		table.destroy();
		table = new DataTable("#expense-table", {
			data: expense,
			columns: [
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.expense_reason) {
							return data.expense_reason;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.expense_category.cat_name) {
							return data.expense_category.cat_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.amount) {
							return data.amount;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.account.account_name) {
							return data.account.account_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.date) {
							return data.date;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.image && data.image !== "") {
							return `
							<a href="http://206.189.81.148/${data.image}" target="_blank">
								<img src="http://206.189.81.148/${data.image}" alt="image" width="50" height="50" />
							</a>
							`;
						}
						return "";
					},
				},

				{
					data: "id",
					render: function (data, type, row) {
						return `
                <div class="btn-group">
                <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  Action
                </button>
                <ul class="dropdown-menu">
				  <li><a class="dropdown-item" href="#" onClick="editExpense(${data})">Edit</a></li>
				  <li><a class="dropdown-item" href="#" onClick="deleteExpense(${data})">Delete</a></li>
					</ul>
						</div>
								`;
					},
				},
			],
		});
	}, [expense]);

	window.editExpense = (id) => {
		navigate("/edit-expense/" + id);
	};

	window.deleteExpense = (id) => {
		// console.log(id);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(
					deleteExpenseByID(id, (res) => {
						if (res.status === 200) {
							Swal.fire({
								icon: "success",
								title: "Success",
								text: res.message,
							});
							dispatch(getExpenseList());
						}
					})
				);
			} else {
				Swal.fire({
					icon: "error",
					title: "Cancelled",
					text: "Your data is safe :)",
				});
			}
		});
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Manage Expense</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="index.html">
												<i data-feather="home" />
											</a>
										</li>
										<li className="breadcrumb-item">Manage</li>
										<li className="breadcrumb-item active"> Expense </li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table
												className="display"
												id="expense-table"
												style={{
													width: "100%",
													textAlign: "center",
													border: "1px solid #dee2e6",
												}}
											>
												<thead>
													<tr>
														<th>Sl no</th>
														<th>Expense Reason</th>
														<th>Category</th>
														<th>Amount</th>
														<th>Account</th>
														<th>Date</th>

														<th>Documents</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
