import axios from "axios";
import {
	getVehicleTypes,
	getVehicleModels,
	getVehicleBrands,
	getVehicleColors,
	getVehicleYears,
	setVehicles,
	getvehicelByID,
} from "../Vehicle";

const apiVehicle = axios.create({
	baseURL: process.env.REACT_APP_BASEURL + "vehicles",
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",

		"Access-Control-Allow-Origin": "*",
		"X-Server-ID": process.env.REACT_APP_SERVER_ID,
	},
});

apiVehicle.interceptors.request.use((config) => {
	const token = localStorage.getItem("token").replace(/['"]+/g, "");
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	return config;
});

export const createVehicle = (payload, callback) => (dispatch) => {
	apiVehicle.post("/insert", payload).then((res) => {
		if (res.status === 200) {
			callback(res.data);
		}
	});
};

// get vehicle types
export const fetchVehicleTypes = (callback) => (dispatch) => {
	apiVehicle.get("/get-vehicle-type").then((res) => {
		if (res.status === 200) {
			// console.log(res.data.data);
			dispatch(getVehicleTypes(res.data.data));
		}
	});
};
// saveVehicleType
export const saveVehicleType = (payload, callback) => (dispatch) => {
	apiVehicle.post("/save/vehicle/type", payload).then((res) => {
		if (res.status === 200) {
			callback(res.data);
		}
	});
};

// getVehicleTypeByID
export const getVehicleTypeByID = (id, callback) => (dispatch) => {
	apiVehicle.get("/vehicle/type/" + id).then((res) => {
		// console.log(res.data.data);
		if (res.status === 200) {
			callback(res.data.data);
		}
	});
};

// update vehicle type
export const updateVehicleType = (id, payload, callback) => (dispatch) => {
	apiVehicle.put("/update/vehicle/type/" + id, payload).then((res) => {
		if (res.status === 200) {
			callback(res.data);
		}
	});
};

// delete vehicle type
export const deleteVehicleType = (id, callback) => (dispatch) => {
	apiVehicle.delete("/delete/vehicle/type/" + id).then((res) => {
		if (res.status === 200) {
			callback(res.data);
		}
	});
};

// get vehicle models
export const fetchVehicleModels = (callback) => (dispatch) => {
	apiVehicle.get("/get-vehicle-model").then((res) => {
		if (res.status === 200) {
			dispatch(getVehicleModels(res.data.data));
		}
	});
};
// saveVehicleModel
export const saveVehicleModel = (payload, callback) => (dispatch) => {
	apiVehicle.post("/save/vehicle/model", payload).then((res) => {
		if (res.status === 200) {
			callback(res.data);
		}
	});
};
// get vehicle model by id
export const fetchVehicleModelByID = (id, callback) => (dispatch) => {
	apiVehicle.get("/vehicle/model/" + id).then((res) => {
		if (res.status === 200) {
			callback(res.data.data);
		}
	});
};

// update vehicle model
export const updateVehicleModel = (id, payload, callback) => (dispatch) => {
	apiVehicle.put("/update/vehicle/model/" + id, payload).then((res) => {
		if (res.status === 200) {
			callback(res.data);
		}
	});
};
// saveVehicleBrand
export const saveVehicleBrand = (payload, callback) => (dispatch) => {
	apiVehicle.post("/save/vehicle/brand", payload).then((res) => {
		if (res.status === 200) {
			callback(res.data);
		}
	});
};

// delete vehicle model
export const deleteVehicleModel = (id, callback) => (dispatch) => {
	apiVehicle.delete("/delete/vehicle/model/" + id).then((res) => {
		if (res.status === 200) {
			callback(res.data);
		}
	});
};

// get vehicle brands
export const fetchVehicleBrands = (callback) => (dispatch) => {
	apiVehicle.get("/get-vehicle-brand").then((res) => {
		if (res.status === 200) {
			dispatch(getVehicleBrands(res.data.data));
		}
	});
};

// get vehicle brand by id
export const fetchVehicleBrandByID = (id, callback) => (dispatch) => {
	apiVehicle.get("/vehicle/brand/" + id).then((res) => {
		if (res.status === 200) {
			callback(res.data.data);
		}
	});
};

// update vehicle brand
export const updateVehicleBrand = (id, payload, callback) => (dispatch) => {
	apiVehicle.put("/update/vehicle/brand/" + id, payload).then((res) => {
		if (res.status === 200) {
			callback(res.data);
		}
	});
};

// delete vehicle brand
export const deleteVehicleBrand = (id, callback) => (dispatch) => {
	apiVehicle.delete("/delete/vehicle/brand/" + id).then((res) => {
		if (res.status === 200) {
			callback(res.data);
		}
	});
};

// get vehicle colors
export const fetchVehicleColors = (callback) => (dispatch) => {
	apiVehicle.get("/get-vehicle-color").then((res) => {
		if (res.status === 200) {
			dispatch(getVehicleColors(res.data.data));
		}
	});
};
// saveVehicleColor
export const saveVehicleColor = (payload, callback) => (dispatch) => {
	apiVehicle.post("/save/vehicle/color", payload).then((res) => {
		if (res.status === 200) {
			callback(res.data);
		}
	});
};
// get vehicle color by id
export const fetchVehicleColorByID = (id, callback) => (dispatch) => {
	apiVehicle.get("/vehicle/color/" + id).then((res) => {
		if (res.status === 200) {
			callback(res.data.data);
		}
	});
};

// update vehicle color
export const updateVehicleColor = (id, payload, callback) => (dispatch) => {
	apiVehicle.put("/update/vehicle/color/" + id, payload).then((res) => {
		if (res.status === 200) {
			callback(res.data);
		}
	});
};

// delete vehicle color
export const deleteVehicleColor = (id, callback) => (dispatch) => {
	apiVehicle.delete("/delete/vehicle/color/" + id).then((res) => {
		if (res.status === 200) {
			callback(res.data);
		}
	});
};

// get vehicle years

export const fetchVehicleYears = (callback) => (dispatch) => {
	apiVehicle.get("/get-vehicle-year").then((res) => {
		if (res.status === 200) {
			dispatch(getVehicleYears(res.data.data));
		}
	});
};

// get vehicles

export const fetchVehicles = (callback) => (dispatch) => {
	apiVehicle.get("/fetch").then((res) => {
		if (res.status === 200) {
			dispatch(setVehicles(res.data.data));
		}
	});
};

// delete vehicle

export const deleteVehicle = (id, callback) => (dispatch) => {
	apiVehicle.delete("/delete/" + id).then((res) => {
		if (res.status === 200) {
			callback(res.data);
		}
	});
};

// get vehicle by id

export const fetchVehicleById = (id, callback) => (dispatch) => {
	apiVehicle.get("/fetch/" + id).then((res) => {
		if (res.status === 200) {
			dispatch(getvehicelByID(res.data.data));
			callback(res.data.data);
		}
	});
};

// update vehicle

export const updateVehicle = (id, payload, callback) => (dispatch) => {
	apiVehicle.put("/update/" + id, payload).then((res) => {
		if (res.status === 200) {
			callback(res.data);
		}
	});
};
