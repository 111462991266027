import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
	getCustomerById,
	updateCustomerById,
} from "../../redux/api/apiCustomer";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

export default function EditCustomer() {
	const { id } = useParams();
	const navigate = useNavigate();

	const [formData, setFormData] = useState({
		cus_id: "",
		cus_name: "",
		cus_contact_number: "",
		cus_contact_number_two: "",
		cus_email: "",
		vat_or_trn_number: "",
		cus_address: "",
		cus_sale_price: "",
		cus_credit_price: "",
		cus_credit_limit: "",
		cus_is_tax_applicable: "",
		cus_website: "",
		cus_logo: "",
		cus_status: "",
		cus_price_type: "",
	});

	const setPriceType = (priceType) => {
		setFormData({
			...formData,
			cus_price_type: priceType,
		});
	};
	const setTaxApplicable = (taxApplicable) => {
		setFormData({
			...formData,
			cus_is_tax_applicable: taxApplicable,
		});
	};
	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const dispatch = useDispatch();
	// based on the id, get the customer
	useEffect(() => {
		getCustomerById(id, (customer) => {
			if (customer) {
				if (customer.status === 400) {
					Swal.fire({
						title: "Error",
						text: customer.message,
						icon: "error",
						confirmButtonText: "Ok",
					});
				} else {
					setFormData(customer);
					reset(customer);
				}
			}
		});
	}, []);

	const updateCustomerData = (data) => {
		Swal.fire({
			title: "Please wait...",
			text: "We are saving your data",
			icon: "info",
			showConfirmButton: false,
			allowOutsideClick: false,
		});
		const formData = new FormData();
		formData.append("cus_name", data.cus_name);
		formData.append("cus_contact_number", data.cus_contact_number);
		formData.append("cus_contact_number_two", data.cus_contact_number_two);
		formData.append("cus_email", data.cus_email);
		formData.append("vat_or_trn_number", data.vat_or_trn_number);
		formData.append("cus_address", data.cus_address);
		formData.append("cus_is_tax_applicable", data.cus_is_tax_applicable);
		formData.append("cus_website", data.cus_website);
		// formData.append("cus_logo", data.cus_logo[0]);
		formData.append("cus_status", data.cus_status);
		formData.append("cus_price_type", data.cus_price_type);
		dispatch(
			updateCustomerById(id, formData, (response) => {
				if (response) {
					Swal.close();
					if (response.status === 400) {
						Swal.fire({
							title: "Error",
							text: response.message,
							icon: "error",
							confirmButtonText: "Ok",
						});
					} else {
						Swal.fire({
							title: "Success",
							text: response.message,
							icon: "success",
							confirmButtonText: "Ok",
						});
						navigate("/manage-customer");
					}
				}
			})
		);
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Edit Customer</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<Link to="#">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-home"
												>
													<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
													<polyline points="9 22 9 12 15 12 15 22" />
												</svg>
											</Link>
										</li>
										<li className="breadcrumb-item">Customer</li>
										<li className="breadcrumb-item active">Edit</li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid project-create">
						<div className="row">
							<form
								id="addCustomerForm"
								className="theme-form"
								onSubmit={handleSubmit(updateCustomerData)}
							>
								<div className="col-sm-12">
									<div className="card">
										<div className="card-body">
											<div className="form theme-form">
												<div className="col-md-12">
													<div className="row">
														<div className="col-md-4">
															<div className="mb-3">
																<label>Customer ID (optional)</label>
																<input
																	type="text"
																	className="form-control"
																	{...register("cus_id", { required: false })}
																	placeholder="Customer ID "
																	value={formData.cus_id}
																	autoComplete="off"
																	onChange={(e) =>
																		setFormData({
																			...formData,
																			cus_id: e.target.value,
																		})
																	}
																	value={formData.cus_id}
																/>
															</div>
														</div>
														<div className="col-md-4">
															<div className="mb-3">
																<label>Customer Name</label>
																<input
																	type="text"
																	className="form-control"
																	{...register("cus_name", {
																		required: formData.cus_name ? false : true,
																	})}
																	placeholder="Customer Name *"
																	autoComplete="off"
																	onChange={(e) =>
																		setFormData({
																			...formData,
																			cus_name: e.target.value,
																		})
																	}
																	value={formData.cus_name}
																/>
																{errors.cus_name && (
																	<span style={{ color: "red" }}>
																		{" "}
																		Customer Name required{" "}
																	</span>
																)}
															</div>
														</div>
														<div className="col-md-4">
															<div className="mb-3">
																<label>VAT/TRN NO (Tax Number)</label>
																<input
																	type="text"
																	className="form-control"
																	{...register("vat_or_trn_number", {
																		required: formData.vat_or_trn_number
																			? false
																			: true,
																	})}
																	placeholder="TRN NO (Tax Number) *"
																	autoComplete="off"
																	onChange={(e) =>
																		setFormData({
																			...formData,
																			vat_or_trn_number: e.target.value,
																		})
																	}
																	value={formData.vat_or_trn_number}
																/>
																{errors.vat_or_trn_number && (
																	<span style={{ color: "red" }}>
																		{" "}
																		TRN NO(Tax Number)required{" "}
																	</span>
																)}{" "}
															</div>
														</div>
														<div className="col-md-4">
															<div className="mb-3">
																<label>Contact Number one</label>
																<input
																	type="text"
																	className="form-control"
																	{...register("cus_contact_number", {
																		required: formData.cus_contact_number
																			? false
																			: true,
																	})}
																	placeholder="Contact Number one *"
																	autoComplete="off"
																	onChange={(e) =>
																		setFormData({
																			...formData,
																			cus_contact_number: e.target.value,
																		})
																	}
																	value={formData.cus_contact_number}
																/>
																{errors.cus_contact_number && (
																	<span style={{ color: "red" }}>
																		{" "}
																		Contact Number one required{" "}
																	</span>
																)}{" "}
															</div>
														</div>
														<div className="col-md-4">
															<div className="mb-3">
																<label>Contact Number Two (optional)</label>
																<input
																	type="text"
																	className="form-control"
																	{...register("cus_contact_number_two", {
																		required: false,
																	})}
																	placeholder="Contact Number Two (optional)"
																	autoComplete="off"
																	onChange={(e) =>
																		setFormData({
																			...formData,
																			cus_contact_number_two: e.target.value,
																		})
																	}
																	value={formData.cus_contact_number_two}
																/>
															</div>
														</div>
														<div className="col-md-4">
															<div className="mb-3">
																<label>Email</label>
																<input
																	type="text"
																	className="form-control"
																	{...register("cus_email", {
																		required: formData.cus_email ? false : true,
																	})}
																	placeholder="Email *"
																	autoComplete="off"
																	onChange={(e) =>
																		setFormData({
																			...formData,
																			cus_email: e.target.value,
																		})
																	}
																	value={formData.cus_email}
																/>
																{errors.cus_email && (
																	<span style={{ color: "red" }}>
																		{" "}
																		Email required{" "}
																	</span>
																)}{" "}
															</div>
														</div>

														<div className="col-md-4">
															<div className="mb-3">
																<label>Tax Applicaple</label>
																<select
																	className="form-control  w-100"
																	{...register("cus_is_tax_applicable", {
																		required: true,
																	})}
																	onChange={(e) =>
																		setTaxApplicable(e.target.value)
																	}
																>
																	<option
																		value={1}
																		selected={
																			formData.cus_is_tax_applicable === 1
																				? true
																				: false
																		}
																	>
																		Yes
																	</option>
																	<option
																		value={2}
																		selected={
																			formData.cus_is_tax_applicable === 2
																				? true
																				: false
																		}
																	>
																		No
																	</option>
																</select>
																{errors.cus_is_tax_applicable && (
																	<span style={{ color: "red" }}>
																		{" "}
																		Tax Applicaple required{" "}
																	</span>
																)}{" "}
															</div>
														</div>
														<div className="col-md-4">
															<div className="mb-3">
																<label>Webisite (optional)</label>
																<input
																	type="text"
																	className="form-control"
																	{...register("cus_website", {
																		required: false,
																	})}
																	placeholder="Webisite (optional) *"
																	autoComplete="off"
																	onChange={(e) =>
																		setFormData({
																			...formData,
																			cus_website: e.target.value,
																		})
																	}
																	value={formData.cus_website}
																/>
															</div>
														</div>

														{/* <div className="col-md-4">
															<div className="mb-3">
																<label>Logo (optional)</label>
																<input
																	type="file"
																	className="form-control"
																	{...register("cus_logo", { required: false })}
																/>
															</div>
														</div> */}

														<div className="col-md-4 ">
															<label>Address (optional)</label>
															<textarea
																className="form-control"
																{...register("cus_address", {
																	required: false,
																})}
																placeholder="Enter Address"
																autoComplete="off"
																autoComplete="off"
																onChange={(e) =>
																	setFormData({
																		...formData,
																		cus_address: e.target.value,
																	})
																}
																value={formData.cus_address}
															/>
														</div>
														{/* <div className="col-md-4">
															<div className="card">
																<div className="card-header pb-0">
																	<label>Price Type</label>
																</div>
																<div className="card-body megaoptions-border-space-sm">
																	<form className="mega-inline">
																		<div className="row">
																			<div className="col-sm-6">
																				<div className="card">
																					<div className="d-flex p-20">
																						<div className="form-check radio radio-primary me-3">
																							<input
																								className="form-check-input"
																								id="radio14"
																								type="radio"
																								{...register("cus_price_type", {
																									required: true,
																								})}
																								defaultValue="gallon"
																								checked={
																									formData.cus_price_type ===
																									"gallon"
																								}
																								onChange={(e) =>
																									setPriceType(e.target.value)
																								}
																							/>

																							<label
																								className="form-check-label"
																								htmlFor="radio14"
																							/>
																						</div>
																						<div className="flex-grow-1">
																							<h6 className="mt-0 mega-title-badge">
																								Gallon
																							</h6>
																						</div>
																					</div>
																				</div>
																			</div>
																			<div className="col-sm-6">
																				<div className="card">
																					<div className="d-flex p-20">
																						<div className="form-check radio radio-secondary me-3">
																							<input
																								className="form-check-input"
																								id="radio13"
																								type="radio"
																								{...register("cus_price_type", {
																									required: true,
																								})}
																								defaultValue="liter"
																								checked={
																									formData.cus_price_type ===
																									"liter"
																								}
																								onChange={(e) =>
																									setPriceType(e.target.value)
																								}
																							/>

																							<label
																								className="form-check-label"
																								htmlFor="radio13"
																							/>
																						</div>
																						<div className="flex-grow-1">
																							<h6 className="mt-0 mega-title-badge">
																								Liter
																							</h6>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</form>
																</div>
															</div>
															{errors.cus_price_type && (
																<span style={{ color: "red" }}>
																	{" "}
																	Price Type required{" "}
																</span>
															)}{" "}
														</div> */}
													</div>
												</div>
												<div className="col-md-12">
													<div className="mb-3">
														<button type="submit" className="btn btn-primary">
															Submit
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
