import React, { useEffect, useState } from "react";
import {
	getTaxinvoiceById,
	getTransaction,
} from "../../redux/api/apiTaxinvoice";
import "./nw1.css";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./newi.css";
import html2pdf from "html2pdf.js";
export default function Print_invoice() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [formData, setFormData] = useState({
		cus_name: "",
		date: "",
		quantity: "",
		product_name: "",
		invoice_no: "",
		unit_price: "",
		sub_total: "",
		tax_amount: "",
		total: "",
		number_in_words: "",
		cus_phone1: "",
		cus_phone2: "",
		cus_email: "",
	});
	useEffect(() => {
		if (id) {
			getTaxinvoiceById(id, (data) => {
				setFormData({
					cus_name: data.data.customer.cus_name,
					vat_or_trn_number: data.data.customer.vat_or_trn_number,
					cus_email: data.data.customer.cus_email,
					cus_address: data.data.customer.cus_address,
					date: data.data.created_date,
					quantity: data.data.quantity,
					product_name: data.data.product_name,
					invoice_no: data.data.invoice_no,
					unit_price: data.data.unit_price,
					sub_total: data.data.sub_total,
					tax_amount: data.data.tax_amount,
					total: data.data.total,
					number_in_words: data.number_in_words,
				});
			});
		}
	}, [id]);
	const Print = () => {
		document.getElementById("print").style.display = "none";
		document.getElementById("back").style.display = "none";
		window.print();
		document.getElementById("print").style.display = "block";
		document.getElementById("back").style.display = "block";
	};

	const Back = () => {
		window.history.back();
	};

	const logoos = localStorage.getItem("c_logo");
	var logo = "";
	if (logoos === null) {
		logo = "";
	} else {
		logo = process.env.REACT_APP_IMAGE_URL + logoos.replace(/['"]+/g, "");
	}

	var company_name = localStorage.getItem("c_name");
	if (company_name) {
		company_name = company_name.replace(/['"]+/g, "");
	} else {
		company_name = "";
	}

	var company_address = localStorage.getItem("c_address");
	if (company_address) {
		company_address = company_address.replace(/['"]+/g, "");
	} else {
		company_address = "";
	}
	var company_phone = localStorage.getItem("c_phone");
	if (company_phone) {
		company_phone = company_phone.replace(/['"]+/g, "");
	} else {
		company_phone = "";
	}
	var company_email = localStorage.getItem("c_email");
	if (company_email) {
		company_email = company_email.replace(/['"]+/g, "");
	} else {
		company_email = "";
	}
	const [imageURL, setImageURL] = useState("");

	useEffect(() => {
		const fetchImage = async () => {
			try {
				const response = await fetch(logo);
				const blob = await response.blob();
				const imageUrl = URL.createObjectURL(blob);
				setImageURL(imageUrl);
			} catch (error) {
				console.error("Error fetching image:", error);
			}
		};

		fetchImage();
	}, []);

	const export_pdf = () => {
		const element = document.getElementById("divToPrint");
		const opt = {
			margin: 1,
			filename: "Invoice.pdf",
			image: { type: "jpeg", quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: "in", format: [15, 12], orientation: "landscape" },
		};

		//  i want to download first page only
		html2pdf().set(opt).from(element).save();
	};

	return (
		<div
			style={{
				margin: 50,
				border: "1px solid #f4f4f4",
				borderRadius: 5,
				padding: 20,
			}}
		>
			<div
				style={{
					padding: 10,
					textAlign: "center",
				}}
			>
				<button className="btn btn-primary" onClick={export_pdf}>
					Download PDF
				</button>
				<Link
					className="btn btn-primary"
					to="/taxinvoice"
					style={{
						marginLeft: 10,
					}}
				>
					BACK
				</Link>
			</div>
			<section id="divToPrint">
				<div
					className="comapny_logo_name"
					style={{
						borderBottom: "1px solid #efefef",
						display: "grid",
						gridTemplateColumns: "1fr 3fr",
						padding: 20,
					}}
				>
					<div className="logo">
						<img src="/1689772513.png" alt="logo" style={{ maxWidth: 300 }} />
						<div
							className="company_name"
							style={{
								marginTop: 25,
							}}
						>
							<h6>{company_name}</h6>
							<div className="addres">
								<p>{company_address}</p>
								<h6>
									{company_email},{company_phone}
								</h6>
							</div>
						</div>
					</div>
					<div className="company_name">
						<h2
							style={{
								marginLeft: 600,
							}}
						>
							TAX INVOICE
						</h2>
					</div>
					{/* <div className="company_name">
						<h2>{company_name}</h2>
						<div className="addres">
							<p>{company_address}</p>
							<h4>
								{company_email},{company_phone}
							</h4>
						</div>
					</div> */}
				</div>
				{/* invoice table */}
				<div className="invoice" style={{ margin: "20px 0px" }}>
					<table style={{ width: "100%" }}>
						<tbody>
							<tr>
								{/* <tr style={{ background: "#0000ff99", color: "#fff" }}> */}
								<th colSpan={3}>CUSTOMER DETAILS</th>
								<th style={{ background: "#0000ff99", color: "#fff" }}>
									INVOICE #
								</th>
								<th style={{ background: "#0000ff99", color: "#fff" }}>
									{" "}
									{formData.invoice_no}
								</th>
							</tr>
							<tr>
								<td>Customer Name</td>
								<td
									style={{
										width: 800,
									}}
									colSpan={2}
								>
									{formData.cus_name}
								</td>
								<td>Lpo #</td>
								<td>{formData.date}</td>
							</tr>
							<tr>
								<td>Customer Vat #:</td>
								<td colSpan={2}>{formData.vat_or_trn_number}</td>
								<td>Quatation</td>
								<td />
							</tr>
							<tr>
								<td>Address</td>
								<td colSpan={2}>{formData.cus_address}</td>
								<td>Paymet Terms</td>
								<td />
							</tr>
							<tr>
								<td>Customer Phone:</td>
								<td colSpan={2}>
									{formData.cus_phone1}, {formData.cus_phone2}
								</td>
								<td></td>
								<td />
							</tr>
							<tr>
								<td>Customer Email:</td>
								<td colSpan={2}>{formData.cus_email}</td>
								<td />
								<td />
							</tr>
						</tbody>
					</table>
				</div>
				{/* invoice table */}
				{/* description and amounts */}
				<div className="invoice" style={{ margin: "20px 0px" }}>
					<table style={{ width: "100%" }}>
						<tbody>
							<tr style={{ background: "#0000ff99", color: "#fff" }}>
								<th style={{ width: 470, textAlign: "center" }}>Description</th>
								<th
									style={{
										textAlign: "center",
									}}
								>
									Quantity
								</th>
								<th
									style={{
										textAlign: "center",
									}}
								>
									Unit Price
								</th>
								<th
									style={{
										textAlign: "center",
									}}
								>
									Taxable Value
								</th>
								<th
									style={{
										textAlign: "center",
									}}
								>
									Vat %
								</th>
								<th
									style={{
										textAlign: "center",
									}}
								>
									Vat Amount
								</th>
								<th
									style={{
										textAlign: "center",
									}}
								>
									Total
								</th>
							</tr>
							<tr>
								<td
									style={{
										textAlign: "center",
									}}
								>
									{formData.product_name}
								</td>
								<td
									style={{
										textAlign: "center",
									}}
								>
									{formData.quantity}
								</td>
								<td
									style={{
										textAlign: "center",
									}}
								>
									{formData.unit_price}
								</td>
								<td
									style={{
										textAlign: "center",
									}}
								>
									{formData.sub_total}
								</td>
								<td
									style={{
										textAlign: "center",
									}}
								>
									5.00%
								</td>
								<td
									style={{
										textAlign: "center",
									}}
								>
									{formData.tax_amount}
								</td>
								<td
									style={{
										textAlign: "center",
									}}
								>
									{formData.total}
								</td>
							</tr>
							<tr>
								<td />
								<td />
								<td />
								<td />
								<td />
								<td />
								<td />
							</tr>
							<tr>
								<td colSpan={3}>Total</td>
								<td style={{ background: "#cecece" }}>{formData.sub_total}</td>
								<td />
								<td style={{ background: "#cecece" }}>{formData.tax_amount}</td>
								<td style={{ background: "#cecece" }}>{formData.total}</td>
							</tr>
						</tbody>
					</table>
				</div>
				{/* description and amounts */}
				{/* taxable amount */}
				<div className="invoice" style={{ margin: "20px 0px" }}>
					<table style={{ width: "100%" }}>
						<tbody>
							<tr style={{ background: "#0000ff99", color: "#fff" }}>
								<th>Amount In Words</th>
								<th colSpan={3}>
									<h4>Summary</h4>
								</th>
							</tr>
							<tr>
								<td rowSpan={3}>{formData.number_in_words}</td>
								<td>Taxable value</td>
								<td>AED</td>
								<td>{formData.sub_total}</td>
							</tr>
							<tr>
								{/* <td>Twenty six thosand one hundred dirham only</td> */}
								<td>Total VAT</td>
								<td>AED</td>
								<td>{formData.tax_amount}</td>
							</tr>
							<tr>
								{/* <td>Twenty six thosand one hundred dirham only</td> */}
								<td>Total Amount (Incl. VAT)</td>
								<td>AED</td>
								<td>{formData.total}</td>
							</tr>
						</tbody>
					</table>
				</div>
				{/* taxable amount */}
				{/* terms and conditions */}
				<div className="terms_condition">
					{/* <h3 style={{ marginBottom: 30 }}>Terms and Conditions</h3> */}
					{/* <div className="terms" style={{ lineHeight: 27, marginBottom: 40 }}> */}
					{/* <p>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet
							incidunt harum nam atque, voluptates autem delectus quisquam
						</p>
						<p>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet
							incidunt harum nam atque, voluptates autem delectus quisquam
						</p>
						<p>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet
							incidunt harum nam atque, voluptates autem delectus quisquam
						</p>
						<p>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet
							incidunt harum nam atque, voluptates autem delectus quisquam
						</p>
						<p>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet
							incidunt harum nam atque, voluptates autem delectus quisquam
						</p>
						<p>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet
							incidunt harum nam atque, voluptates autem delectus quisquam
						</p> */}
					{/* </div> */}
					<div className="issued_by">
						<h3 style={{ marginBottom: 40 }}>Issued By</h3>
						<div className="name__company" style={{ lineHeight: 27 }}></div>
					</div>
				</div>
				{/* terms and conditions */}
			</section>
		</div>
	);
}
