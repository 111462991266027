import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getNotifications } from "../../../redux/api/apiSale";
import { notificationSelector } from "../../../redux/Sale";
import Dashboard from "../svg/Dashboard";

export default function Notification() {
	const { bellIcon } = Dashboard();
	const notification = useSelector(notificationSelector);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getNotifications());
	}, [dispatch]);
	return (
		<div>
			<li className="onhover-dropdown">
				<div className="notification-box">{bellIcon}</div>
				<ul className="notification-dropdown onhover-show-div">
					<li>
						<i data-feather="bell"></i>
						<h6 className="f-18 mb-0">Notitications</h6>
					</li>
					{notification.map((item, index) => {
						//  show limit 5 notification
						if (index > 2) return null;

						return (
							<li key={index}>
								<div className="d-flex align-items-center">
									<div className="flex-grow-1">
										<p>
											<span className="pull-right">{item.message}</span>
										</p>
									</div>
								</div>
							</li>
						);
					})}

					<li>
						<Link className="btn btn-primary" to="/get/all/notification">
							Check all notification
						</Link>
					</li>
				</ul>
			</li>
		</div>
	);
}
