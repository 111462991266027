import { createSlice } from "@reduxjs/toolkit";

const saleSlice = createSlice({
	name: "sale",
	initialState: {
		sales: [],
		tags: [],
		notification: [],
		lasttarnsaction: [],
	},
	reducers: {
		getSale: (state, action) => {
			state.sales = action.payload;
		},
		getTags: (state, action) => {
			state.tags = action.payload;
		},
		getNotification: (state, action) => {
			state.notification = action.payload;
		},
		getLastTransaction: (state, action) => {
			state.lasttarnsaction = action.payload;
		},
	},
});

export const { getSale, getTags, getNotification, getLastTransaction } =
	saleSlice.actions;

export const saleSelector = (state) => state.sale.sales;
export const tagsSelector = (state) => state.sale.tags;
export const notificationSelector = (state) => state.sale.notification;
export const lastTransactionSelector = (state) => state.sale.lasttarnsaction;

export default saleSlice.reducer;
