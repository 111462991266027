import { createSlice } from "@reduxjs/toolkit";

const taxInvoiceSlice = createSlice({
	name: "taxinvoice",
	initialState: {
		taxinvoices: [],
		transaction: [],
		byresult: [],
	},
	reducers: {
		getTaxInvoice: (state, action) => {
			state.taxinvoices = action.payload;
		},
		getTaxInvoiceTransaction: (state, action) => {
			state.transaction = action.payload;
		},
		getTaxInvoiceByResult: (state, action) => {
			state.byresult = action.payload;
		},
	},
});

export const {
	getTaxInvoice,
	getTaxInvoiceTransaction,
	getTaxInvoiceByResult,
} = taxInvoiceSlice.actions;

export const selectTaxInvoice = (state) => state.taxinvoice.taxinvoices;
export const selectTaxInvoiceTransaction = (state) =>
	state.taxInvoice.transaction;
export const selectTaxInvoiceByResult = (state) => state.taxinvoice.byresult;

export default taxInvoiceSlice.reducer;
