import React, { Component } from "react";
import Chart from "react-apexcharts";

class TopFuelUsage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			series: [
				{
					data: props.series,
				},
			],
			options: {
				chart: {
					type: "bar",
					height: 350,
				},
				plotOptions: {
					bar: {
						borderRadius: 4,
						horizontal: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				xaxis: {
					categories: props.categories,
				},
				yaxis: {
					labels: {
						formatter: function (value) {
							return value + "L";
						},
					},
				},
			},
		};
	}

	render() {
		return (
			<div id="chart">
				<Chart
					options={this.state.options}
					series={this.state.series}
					type="bar"
				/>
			</div>
		);
	}
}

export default TopFuelUsage;
