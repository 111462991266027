import { createSlice } from "@reduxjs/toolkit";

export const vehicleSlice = createSlice({
    name: "vehicle",
    initialState: {
        vehicles: [],
        vehicleTypes: [],
        vehicleModels: [],
        vehicleBrands: [],
        vehicleColors: [],
        vehicleYears: [],
        vehicelByID : [],
   
    },
    reducers: {
        setVehicles: (state, action) => {
            state.vehicles = action.payload;
        },
        getVehicleTypes: (state, action) => {
            state.vehicleTypes = action.payload;
        },
        getVehicleModels: (state, action) => {
            state.vehicleModels = action.payload;
        },
        getVehicleBrands: (state, action) => {
            state.vehicleBrands = action.payload;
        },
        getVehicleColors: (state, action) => {
            state.vehicleColors = action.payload;
        },
        getVehicleYears: (state, action) => {
            state.vehicleYears = action.payload;
        },
        getvehicelByID: (state, action) => {
            state.vehicelByID = action.payload;
        }

    },
});


export const { setVehicles,getVehicleTypes,getVehicleModels,getVehicleBrands,getVehicleColors,getVehicleYears,getvehicelByID } = vehicleSlice.actions;


export const selectVehicles = (state) => state.vehicle.vehicles;
export const selectVehicleTypes = (state) => state.vehicle.vehicleTypes;
export const selectVehicleModels = (state) => state.vehicle.vehicleModels;
export const selectVehicleBrands = (state) => state.vehicle.vehicleBrands;
export const selectVehicleColors = (state) => state.vehicle.vehicleColors;
export const selectVehicleYears = (state) => state.vehicle.vehicleYears;
export const selectvehicelByID = (state) => state.vehicle.vehicelByID;







export default vehicleSlice.reducer;