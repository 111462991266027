import { Route, Routes } from "react-router-dom";
import Accountadjustment from "./pages/accounts/Accountadjustment";
import Accounts from "./pages/accounts/Accounts";
import AccountTransfer from "./pages/accounts/AccountTransfer";
import Login from "./pages/auth/Login";
import AddCustomer from "./pages/customer/AddCustomer";
import Addvehicle from "./pages/customer/Addvehicle";
import EditCustomer from "./pages/customer/EditCustomer";
import ManageCustomer from "./pages/customer/ManageCustomer";
import ManageTag from "./pages/customer/ManageTag";
import ManageVehicle from "./pages/customer/ManageVehicle";
import PriceChanging from "./pages/customer/PriceChanging";
import AllNotification from "./pages/dashboard/components/AllNotification";
import Dashboard from "./pages/dashboard/Dashboard";
import AddDriver from "./pages/driver/AddDriver";
import Department from "./pages/driver/Department";
import AddSale from "./pages/sale/AddSale";
import ManageSale from "./pages/sale/ManageSale";
import Print from "./pages/sale/Print";
import Addusers from "./pages/users/Addusers";
import Manageusers from "./pages/users/Manageusers";
import Category from "./pages/expense/Category";
import Manage from "./pages/expense/Manage";
import Expense from "./pages/expense/Expense";
import Supplier from "./pages/supplier/Supplier";
import Managepurchase from "./pages/purchase/Managepurchase";
import AddPurchase from "./pages/purchase/AddPurchase";
import Taxinvoice from "./pages/taxinvoice/Taxinvoice";
import Managetaxinvoice from "./pages/taxinvoice/Managetaxinvoice";
import Print_invoice from "./pages/taxinvoice/Print_invoice";
import Customerpayment from "./pages/payment/Customerpayment";
import Supplierpayment from "./pages/payment/Supplierpayment";
import Transaction from "./pages/reports/Transaction";
import { PDFViewer } from "@react-pdf/renderer";
import TransactionList from "./pages/reports/TransactionList";
import Payment from "./pages/reports/Payment";
import Duepayment from "./pages/reports/Duepayment";
import Expenses from "./pages/reports/Expenses";
import Profit_Loss from "./pages/reports/Profit_Loss";
import Blancesheet from "./pages/reports/Blancesheet";
import Ledger from "./pages/reports/Ledger";
import Cash_Flow from "./pages/reports/Cash_Flow";
import Info from "./pages/settings/Info";
import Cahngepass from "./pages/settings/Cahngepass";
import PurchasePayment from "./pages/reports/PurchasePayment";
import Edit from "./pages/taxinvoice/Edit";
import Taxpayments from "./pages/taxinvoice/Taxpayments";
import Downloadtax from "./pages/taxinvoice/Downloadtax";
import PDFPreview from "./pages/purchase/PDFPreview";
import Vehicletype from "./pages/parameter/Vehicletype";
import VehicleBrand from "./pages/parameter/VehicleBrand";
import Vehiclemodel from "./pages/parameter/Vehiclemodel";
import Vehiclecolor from "./pages/parameter/Vehiclecolor";
function App() {
	return (
		<Routes>
			<Route path="/" element={<Login />} />
			<Route path="/login" element={<Login />} />
			<Route path="/dashboard" exact element={<Dashboard />} />
			<Route path="/edit-sale/:id" element={<AddSale />} />
			<Route path="/manage-sale" element={<ManageSale />} />
			<Route path="/add-customer" element={<AddCustomer />} />
			<Route path="/manage-customer" element={<ManageCustomer />} />
			<Route path="/edit-customer/:id" element={<EditCustomer />} />
			<Route path="/add-vehicle" element={<Addvehicle />} />
			<Route path="/manage-vehicle" element={<ManageVehicle />} />
			<Route path="/edit-vehicle/:id" element={<Addvehicle />} />
			<Route path="/price-changing" element={<PriceChanging />} />
			<Route path="/manage-tag" element={<ManageTag />} />
			<Route path="/manage-department" element={<Department />} />
			<Route path="/drivers" element={<AddDriver />} />
			<Route path="/print-reciept/:id" element={<Print />} />
			<Route path="/get/all/notification" element={<AllNotification />} />
			<Route path="/add-users" element={<Addusers />} />
			<Route path="/manage-users" element={<Manageusers />} />
			<Route path="/edit-users/:id" element={<Addusers />} />
			<Route path="/accounts" element={<Accounts />} />
			<Route path="/account-adjustment" element={<Accountadjustment />} />
			<Route path="/account-transfer" element={<AccountTransfer />} />
			<Route path="/expense-category" element={<Category />} />
			<Route path="/manage-expense" element={<Manage />} />
			<Route path="/expense" element={<Expense />} />
			<Route path="/edit-expense/:id" element={<Expense />} />
			<Route path="/supplier" element={<Supplier />} />
			<Route path="/add/purchase" element={<AddPurchase />} />
			<Route path="/edit/purchase/:id" element={<AddPurchase />} />
			<Route path="/purchase" element={<Managepurchase />} />
			<Route path="/add/taxinvoice" element={<Taxinvoice />} />
			<Route path="/edit/taxinvoice/:id" element={<Edit />} />
			<Route path="/taxinvoice" element={<Managetaxinvoice />} />
			<Route path="/print/taxinvoice/:id" element={<Print_invoice />} />
			<Route path="/customer/payment" element={<Customerpayment />} />
			<Route path="/supplier/payment" element={<Supplierpayment />} />
			<Route path="/report/transaction" element={<TransactionList />} />
			<Route path="/report/transaction/pdf/" element={<Transaction />} />
			<Route path="/report/payment" element={<Payment />} />
			<Route path="/report/due/payment" element={<Duepayment />} />
			<Route path="/report/expense" element={<Expenses />} />
			<Route path="/report/profit/and/loss" element={<Profit_Loss />} />
			<Route path="/report/balance/sheet" element={<Blancesheet />} />
			<Route path="/report/ledger" element={<Ledger />} />
			<Route path="/report/cash/flow" element={<Cash_Flow />} />

			<Route path="/info" element={<Info />} />
			<Route path="/view/data/:doctype/:id" element={<PDFPreview />} />
			<Route path="/account/info" element={<Cahngepass />} />
			<Route path="/purchase/payments" element={<PurchasePayment />} />
			<Route path="/tax/payments" element={<Taxpayments />} />
			<Route path="/tax/payments/downlaod/:id" element={<Downloadtax />} />
			<Route path="/vehicle/type" element={<Vehicletype />} />
			<Route path="/vehicle/brand" element={<VehicleBrand />} />
			<Route path="/vehicle/model" element={<Vehiclemodel />} />
			<Route path="/vehicle/color" element={<Vehiclecolor />} />
			<Route path="*" element={<h1>404: Not Found</h1>} />
		</Routes>
	);
}

export default App;
