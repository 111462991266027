import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import DataTable from "datatables.net-dt";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import Swal from "sweetalert2";
import {
	getAccount,
	insertAccount,
	getAccountById,
	updateAccountByID,
	deleteAccountByID,
} from "../../redux/api/apiAccounts";
import { accountsSelector } from "../../redux/Accounts";
import { useForm } from "react-hook-form";
export default function Accounts() {
	const dispatch = useDispatch();
	const accounts = useSelector(accountsSelector);
	useEffect(() => {
		dispatch(getAccount());
	}, []);
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const [modalIsOpen, setIsOpen] = useState(false);
	const [editID, setEditID] = useState("");
	function openModal() {
		setIsOpen(true);
		reset({
			account_name: "",
			account_number: "",
			account_branch: "",
			account_type: "",
			opening_date: "",
			notes: "",
			primary_account: "",
		});
	}

	function closeModal() {
		setIsOpen(false);
		reset();
		setEditID("");
	}
	const saveAccount = (data) => {
		closeModal();
		reset();
		Swal.fire({
			title: "Please wait...",
			html: "Saving Account",
			allowOutsideClick: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		insertAccount(data, (res) => {
			if (res.status === 200) {
				Swal.fire({
					title: "Success",
					text: "Account has been saved",
					icon: "success",
					timer: 2000,
					timerProgressBar: true,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				dispatch(getAccount());
			} else {
				Swal.fire({
					title: "Error",
					text: res.message,
					icon: "error",
					timer: 2000,
					timerProgressBar: true,
					didOpen: () => {
						Swal.showLoading();
					},
				});
			}
		});
	};
	const updateAccount = (data) => {
		closeModal();
		reset();
		Swal.fire({
			title: "Please wait...",
			html: "Updating Account",
			allowOutsideClick: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		dispatch(
			updateAccountByID(editID, data, (res) => {
				if (res.status === 200) {
					Swal.fire({
						title: "Success",
						text: "Account has been updated",
						icon: "success",
						timer: 2000,
						timerProgressBar: true,
						didOpen: () => {
							Swal.showLoading();
						},
					});
					dispatch(getAccount());
				}
			})
		);
	};
	let table = new DataTable("#accounts-table");
	useEffect(() => {
		table.destroy();
		table = new DataTable("#accounts-table", {
			data: accounts,
			columns: [
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.account_name) {
							return data.account_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.account_number) {
							return data.account_number;
						}
						return "";
					},
				},
				// {
				// 	data: null,
				// 	render: function (data, type) {
				// 		if (data.account_branch) {
				// 			return data.account_branch;
				// 		}
				// 		return "";
				// 	},
				// },
				// {
				// 	data: null,
				// 	render: function (data, type) {
				// 		if (data.account_type) {
				// 			return data.account_type;
				// 		}
				// 		return "";
				// 	},
				// },
				{
					data: null,
					render: function (data, type) {
						if (data.primary_account) {
							return data.primary_account;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.opening_balance) {
							return data.opening_balance;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.account_balance) {
							return data.account_balance;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.opening_date) {
							return data.opening_date;
						}
						return "";
					},
				},
				// {
				// 	data: "id",
				// 	render: function (data, type, row) {
				// 		return `
				// <div class="btn-group">
				// <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
				//   Action
				// </button>
				// <ul class="dropdown-menu">
				//   <li><a class="dropdown-item" href="#"  onclick="editAccount(${data})">Edit</a></li>
				//     <li><a class="dropdown-item" href="#" onclick="deleteAccount(${data})">Delete</a></li>
				// </ul>
				// </div>
				// `;
				// 	},
				// },
			],
		});
	}, [accounts]);

	window.editAccount = (id) => {
		setEditID(id);
		openModal();
		dispatch(
			getAccountById(id, (res) => {
				reset({
					account_name: res.data.account_name,
					account_number: res.data.account_number,
					account_branch: res.data.account_branch,
					account_type: res.data.account_type,
					opening_date: res.data.opening_date,
					notes: res.data.notes,
					primary_account: res.data.primary_account,
				});
			})
		);
	};
	window.deleteAccount = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(
					deleteAccountByID(id, (res) => {
						if (res.status === 200) {
							Swal.fire("Deleted!", "Your file has been deleted.", "success");
							dispatch(getAccount());
						}
					})
				);
			} else {
				Swal.fire("Cancelled", "Your imaginary file is safe :)", "error");
			}
		});
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Manage Accounts</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="index.html">
												<i data-feather="home" />
											</a>
										</li>
										<li className="breadcrumb-item">Manage</li>
										<li className="breadcrumb-item active"> Accounts </li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						contentLabel="Example Modal"
						className="modal-dialog modal-dialog-centered"
						closeTimeoutMS={500}
						style={{
							//  width 1200px
							overlay: {
								backgroundColor: "rgba(0, 0, 0, 0.5)",
								zIndex: 9999,
							},
						}}
					>
						<div
							className="modal-content"
							style={{
								width: "1200px",
								margin: "0 auto",
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title">
									{editID ? "Edit Account" : "Add Account"}
								</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
									onClick={closeModal}
								/>
							</div>
							<form
								onSubmit={handleSubmit(editID ? updateAccount : saveAccount)}
							>
								<div className="modal-body">
									<div className="col-md-12 row">
										<div className="col-md-6">
											<label>Account Name</label>
											<input
												type="text"
												className="form-control"
												name="account_name"
												id="account_name"
												placeholder="Enter Account Name"
												{...register("account_name", {
													required: "Account Name is required",
												})}
											/>
											{errors.account_name && (
												<span className="text-danger">
													{errors.account_name.message}
												</span>
											)}
										</div>
										{/* <div className="col-md-6">
											<label>Branch Name</label>
											<input
												type="text"
												className="form-control"
												name="account_branch"
												id="account_branch"
												placeholder="Enter Branch Name"
												{...register("account_branch", {
													required: "Branch Name is required",
												})}
											/>
											{errors.account_branch && (
												<span className="text-danger">
													{errors.account_branch.message}
												</span>
											)}
										</div> */}
										<div className="col-md-6">
											<label>Account ID</label>
											<input
												type="text"
												className="form-control"
												name="account_number"
												id="account_number"
												placeholder="Enter Account ID"
												{...register("account_number", {
													required: "Account ID is required",
												})}
											/>
											{errors.account_number && (
												<span className="text-danger">
													{errors.account_number.message}
												</span>
											)}
										</div>
										<div className="col-md-6">
											<label>Opening Date</label>
											<input
												type="date"
												className="form-control"
												name="opening_date"
												id="opening_date"
												placeholder="Enter Opening Date"
												{...register("opening_date", {
													required: "Opening Date is required",
												})}
											/>
											{errors.opening_date && (
												<span className="text-danger">
													{errors.opening_date.message}
												</span>
											)}
										</div>
										<div className="col-md-6">
											<label>Opening balance</label>
											<input
												type="number"
												className="form-control"
												name="opening_balance"
												id="opening_balance"
												placeholder="Enter Opening Balance"
												{...register("opening_balance", {
													required: "Opening Balance is required",
												})}
											/>
											{errors.opening_balance && (
												<span className="text-danger">
													{errors.opening_balance.message}
												</span>
											)}
										</div>
										{/* <div className="col-md-6">
											<label>Accounts Type</label>
											<select
												className="form-control"
												name="account_type"
												id="account_type"
												{...register("account_type", {
													required: "Account Type is required",
												})}
											>
												<option value="">Select Type</option>
												<option value="debit">Debit</option>
												<option value="credit">Credit</option>
												<option value="inventory">Inventory</option>
											</select>
											{errors.account_type && (
												<span className="text-danger">
													{errors.account_type.message}
												</span>
											)}
										</div> */}

										<div className="col-md-6">
											<label>Primary Accounts </label>
											<select
												className="form-control"
												name="primary_account"
												id="primary_account"
												{...register("primary_account", {
													required: "Primary Account is required",
												})}
											>
												<option value="">Select Type</option>
												<option value="asset">Asset</option>
												<option value="liability">Liablity</option>
												<option value="income">Income</option>
												<option value="expense">Expense</option>
												<option value="payable">Payable</option>
												<option value="recievable">Recievable</option>
											</select>
											{errors.primary_account && (
												<span className="text-danger">
													{errors.primary_account.message}
												</span>
											)}
										</div>
										<div className="col-md-6">
											<label>Notes</label>
											<textarea
												className="form-control"
												name="notes"
												id="notes"
												placeholder="Enter Notes"
												{...register("notes", {
													required: false,
												})}
											/>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-secondary"
										data-bs-dismiss="modal"
										onClick={closeModal}
									>
										Close
									</button>
									<button type="submit" className="btn btn-primary">
										Save changes
									</button>
								</div>
							</form>
						</div>
					</Modal>

					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<button
											class="btn btn-primary"
											type="button"
											onClick={openModal}
										>
											Create New Accounts
										</button>

										<div className="table-responsive">
											<table
												className="display"
												id="accounts-table"
												style={{
													width: "100%",
													textAlign: "center",
													border: "1px solid #dee2e6",
												}}
											>
												<thead>
													<tr>
														<th>Sl no</th>
														<th>Account Name</th>
														<th>Account ID</th>
														{/* <th>Bank Name</th>
														<th> Account Type</th> */}
														<th>Primary Account</th>
														<th>Opening Balance</th>
														<th>Current Balance</th>
														<th>Opening Date</th>
														{/* <th>Action</th> */}
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
