import { createSlice } from "@reduxjs/toolkit";

const supplierSlice = createSlice({
	name: "supplier",
	initialState: {
		supplier: [],
	},
	reducers: {
		getSupplier: (state, action) => {
			state.supplier = action.payload;
		},
	},
});

export const { getSupplier } = supplierSlice.actions;

export const supplierSelector = (state) => state.supplier.supplier;

export default supplierSlice.reducer;
