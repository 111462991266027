import React, { useEffect, useState } from "react";
import Footer from "../dashboard/components/Footer";
import Sidebar from "../dashboard/components/Sidebar";
import Header from "../dashboard/components/Header";
import { useForm } from "react-hook-form";
import DataTable from "datatables.net-dt";
import Swal from "sweetalert2";
import Modal from "react-modal";
import { selectVehicleBrands } from "../../redux/Vehicle";
import {
	fetchVehicleBrands,
	deleteVehicleBrand,
	fetchVehicleBrandByID,
	updateVehicleBrand,
	saveVehicleBrand,
} from "../../redux/api/apiVehicle";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
export default function VehicleBrand() {
	function openModal() {
		setIsOpen(true);
		reset({
			brand_name: "",
		});
		// setEditID("");
	}
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();

	const [editID, setEditID] = useState("");
	const [modalIsOpen, setIsOpen] = useState(false);
	function closeModal() {
		setIsOpen(false);
	}
	const save = (data) => {
		Swal.fire({
			title: "Please wait...",
			text: "We are saving your data",
			icon: "info",
			showConfirmButton: false,
			allowOutsideClick: false,
		});
		dispatch(
			saveVehicleBrand(data, (res) => {
				if (res.status === 200) {
					Swal.close();
					closeModal();
					dispatch(fetchVehicleBrands());
					Swal.fire("Saved!", "Vehicle Brand has been saved.", "success");
				}
			})
		);
	};
	const dispatch = useDispatch();

	const vehicleBrands = useSelector(selectVehicleBrands);
	useEffect(() => {
		dispatch(fetchVehicleBrands());
	}, [dispatch]);
	let table = new DataTable("#parameter-table");

	useEffect(
		() => {
			// console.log(priceList);
			table.destroy();
			table = new DataTable("#parameter-table", {
				data: vehicleBrands,
				columns: [
					{
						data: null,
						render: function (data, type, row, meta) {
							return meta.row + meta.settings._iDisplayStart + 1;
						},
					},
					{ data: "brand_name" },

					{
						data: null,
						render: function (data, type, row) {
							return `<button class="btn btn-primary" data-id="${row.id}">Edit</button>
							<button class="btn btn-danger" data-id="${row.id}">Delete</button>`;
						},
					},
				],
			});
			table.on("click", "button.btn-primary", function () {
				const id = this.getAttribute("data-id");
				setEditID(id);
				dispatch(
					fetchVehicleBrandByID(id, (res) => {
						openModal();
						reset({
							brand_name: res.brand_name,
						});

						setEditID(id);
					})
				);
			});

			table.on("click", "button.btn-danger", function () {
				const id = this.getAttribute("data-id");
				Swal.fire({
					title: "Are you sure?",
					text: "You will not be able to recover this imaginary file!",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Yes, delete it!",
					cancelButtonText: "No, keep it",
				}).then((result) => {
					if (result.isConfirmed) {
						// call delete api
						dispatch(
							deleteVehicleBrand(id, (res) => {
								if (res.status === 200) {
									Swal.fire(
										"Deleted!",
										"Your imaginary file has been deleted.",
										"success"
									);
									dispatch(fetchVehicleBrands());
								}
							})
						);
					} else if (result.dismiss === Swal.DismissReason.cancel) {
						Swal.fire("Cancelled", "Your imaginary file is safe :)", "error");
					}
				});
			});
		},
		// eslint-disable-next-line
		[vehicleBrands]
	);

	const update = (data) => {
		dispatch(
			updateVehicleBrand(editID, data, (res) => {
				if (res.status === 200) {
					closeModal();
					dispatch(fetchVehicleBrands());
					setEditID("");
					Swal.fire("Updated!", "Vehicle Brand has been Updated.", "success");
				}
			})
		);
	};

	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Manage Vehicle Brand</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="index.html">
												<i data-feather="home" />
											</a>
										</li>
										<li className="breadcrumb-item">Vehicle Brand</li>
										<li className="breadcrumb-item active">
											Manage Vehicle Brand
										</li>
									</ol>
								</div>
							</div>
						</div>
					</div>
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						contentLabel="Example Modal"
						className="modal-dialog modal-dialog-centered"
						closeTimeoutMS={500}
						style={{
							//  width 1200px
							overlay: {
								backgroundColor: "rgba(0, 0, 0, 0.5)",
								zIndex: 9999,
							},
						}}
					>
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">
									{editID ? "Update " : "Add "}
									Vehicle Brand
								</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
									onClick={closeModal}
								/>
							</div>
							<form onSubmit={handleSubmit(editID ? update : save)}>
								<div className="modal-body">
									<div className="col-md-12 row">
										<div className="col-md-12">
											<label className="form-label">Vehicle Brand</label>
											<input
												type="text"
												className="form-control"
												{...register("brand_name", {
													required: true,
												})}
											/>
											{errors.brand_name && (
												<span className="text-danger">
													This field is required
												</span>
											)}
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-secondary"
										data-bs-dismiss="modal"
										onClick={closeModal}
									>
										Close
									</button>
									<button type="submit" className="btn btn-primary">
										Save
									</button>
								</div>
							</form>
						</div>
					</Modal>

					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<button
											class="btn btn-primary"
											type="button"
											onClick={openModal}
										>
											Add Vehicle Brand
										</button>

										<div
											className="table-responsive"
											style={{
												marginTop: "10px",
											}}
										>
											<table
												className="display"
												id="parameter-table"
												style={{
													width: "100%",
													textAlign: "center",
													border: "1px solid #dee2e6",
												}}
											>
												<thead>
													<tr>
														<th>Sl no</th>
														<th> Name</th>

														<th>Action</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
