import axios from "axios";

import Swal from "sweetalert2";
import { getSupplier } from "../../redux/Supplier";
const apiSupplier = axios.create({
	baseURL: process.env.REACT_APP_BASEURL + "supplier",
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",

		"Access-Control-Allow-Origin": "*",
		"X-Server-ID": process.env.REACT_APP_SERVER_ID,
	},
});

apiSupplier.interceptors.request.use((config) => {
	const token = localStorage.getItem("token").replace(/['"]+/g, "");
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	return config;
});

// insert supplier
export const insertSupplier = (payload, callback) =>
	apiSupplier
		.post(`/insert`, payload)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data.status);
				}
			} else {
				callback(res.data);
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

// get supplier
export const getSupplierList = (payload, callback) => (dispatch) =>
	apiSupplier.get("/fetch", payload).then((res) => {
		// console.log(res);
		if (res.status === 200) {
			if (callback) {
				callback(res.data);
			}

			dispatch(getSupplier(res.data.data));
		}
	});

// get supplier by id
export const getSupplierById = (id, callback) =>
	apiSupplier.get(`/fetch/${id}`).then((res) => {
		if (res.data.status === 200) {
			// console.log(res.data.data);
			if (callback) {
				callback(res.data.data);
			}
		}
	});

// update supplier

export const updateSupplierByID = (id, payload, callback) =>
	apiSupplier
		.post(`/update/${id}`, payload)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

// delete supplier
export const deleteSupplier = (id, callback) =>
	apiSupplier
		.delete(`/delete/${id}`)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});
