import axios from "axios";

import Swal from "sweetalert2";
import { getAccounts, getAdjustments, getTransfer } from "../Accounts";
const apiAccounts = axios.create({
	baseURL: process.env.REACT_APP_BASEURL + "accounts",
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",

		"Access-Control-Allow-Origin": "*",
		"X-Server-ID": process.env.REACT_APP_SERVER_ID,
	},
});

apiAccounts.interceptors.request.use((config) => {
	const token = localStorage.getItem("token").replace(/['"]+/g, "");
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	return config;
});

export const insertAccount = (payload, callback) =>
	apiAccounts
		.post(`/insert`, payload)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

// get account

export const getAccount = (payload, callback) => (dispatch) =>
	apiAccounts.get("/fetch", payload).then((res) => {
		if (res.data.status === 200) {
			if (callback) {
				callback(res.data);
			}

			dispatch(getAccounts(res.data.data));
		}
	});
// get account by id
export const getAccountById = (id, callback) => (dispatch) =>
	apiAccounts
		.get("/fetch/" + id)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			}).then(() => {
				window.location = "/manage-account";
			});
		});
// update account by id

export const updateAccountByID = (id, payload, callback) => (dispatch) =>
	apiAccounts
		.put("/update/" + id, payload)
		.then((res) => {
			if (callback) {
				callback(res.data);
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

// delete account by id
export const deleteAccountByID = (id, callback) => (dispatch) =>
	apiAccounts
		.delete("/delete/" + id)
		.then((res) => {
			if (callback) {
				callback(res.data);
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});
//  account adjustment insert
export const insertAccountAdjustment = (payload, callback) =>
	apiAccounts
		.post(`/adj/insert`, payload)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

// get account adjustment transaction
export const getAccountAdjustment = (payload, callback) => (dispatch) =>
	apiAccounts.get("/adj/fetch", payload).then((res) => {
		if (res.data.status === 200) {
			if (callback) {
				callback(res.data);
			}

			dispatch(getAdjustments(res.data.data));
		}
	});

// get account adjustment transaction by id
export const getAccountAdjustmentById = (id, callback) => (dispatch) =>
	apiAccounts
		.get("/adj/fetch/" + id)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			}).then(() => {
				window.location = "/manage-account";
			});
		});

// update account adjustment transaction by id
export const updateAccountAdjustmentByID =
	(id, payload, callback) => (dispatch) =>
		apiAccounts
			.put("/adj/update/" + id, payload)
			.then((res) => {
				if (callback) {
					callback(res.data);
				}
			})
			.catch((err) => {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: err.response.data.message,
				});
			});

// delete account adjustment transaction by id

export const deleteAccountAdjustmentByID = (id, callback) => (dispatch) =>
	apiAccounts
		.delete("/adj/delete/" + id)
		.then((res) => {
			if (callback) {
				callback(res.data);
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

//  create account transfer
export const insertAccountTransfer = (payload, callback) =>
	apiAccounts
		.post(`/transfer/insert`, payload)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

// get account transfer transaction
export const getAccountTransfer = (payload, callback) => (dispatch) =>
	apiAccounts.get("/transfer/fetch", payload).then((res) => {
		if (res.data.status === 200) {
			if (callback) {
				callback(res.data);
			}

			dispatch(getTransfer(res.data.data));
		}
	});

// delete account transfer transaction by id
export const deleteAccountTransferByID = (id, callback) => (dispatch) =>
	apiAccounts
		.delete("/transfer/delete/" + id)
		.then((res) => {
			if (callback) {
				callback(res.data);
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});
// get account transfer transaction by id
export const getAccountTransferById = (id, callback) => (dispatch) =>
	apiAccounts.get("/transfer/fetch/" + id).then((res) => {
		if (res.data.status === 200) {
			if (callback) {
				callback(res.data);
			}
		}
	});

// update account transfer transaction by id
export const updateAccountTransferByID = (id, payload, callback) =>
	apiAccounts
		.post("/transfer/update/" + id, payload)
		.then((res) => {
			if (callback) {
				callback(res.data);
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});
// account balance by id
export const getAccountBalanceById = (id, callback) => (dispatch) =>
	apiAccounts
		.get("/account/balance/by/" + id)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});
