import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import DataTable from "datatables.net-dt";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Modal from "react-modal";
import { useForm, Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { selectCustomers, selectPrice } from "../../redux/Customers";
import {
	getAllCustomers,
	customerPriceInsert,
	getCustomerPrice,
	deleteCustomerPrice,
	getCustomerPriceById,
	updateCustomerPriceById,
} from "../../redux/api/apiCustomer";

Modal.setAppElement("#root");
export default function PriceChanging() {
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const customerList = useSelector(selectCustomers);
	const priceList = useSelector(selectPrice);
	useEffect(() => {
		dispatch(getAllCustomers());
		dispatch(getCustomerPrice());
	}, []);
	const customerLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				customerList.filter((i) =>
					i.cus_name.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};
	const [formData, setFormData] = useState({
		cus_id: "",
		cus_credit_price: "",
		cus_sale_price: "",
		datefrom: "",
		dateto: "",
	});

	const custmerValue = (e) => {
		if (e === null) {
			setFormData({
				...formData,
				cus_id: "",
			});
			return;
		}
		setFormData({
			...formData,
			cus_id: e.id,
		});
	};

	let table = new DataTable("#price-table");

	useEffect(() => {
		// console.log(priceList);
		table.destroy();
		table = new DataTable("#price-table", {
			data: priceList,
			columns: [
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},
				{
					data: null,
					render: function (data, type, row) {
						if (row.customer) {
							return row.customer.cus_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type, row) {
						if (row.cus_sale_price) {
							return row.cus_sale_price;
						}
					},
				},
				{
					data: null,
					render: function (data, type, row) {
						if (row.cus_credit_price) {
							return row.cus_credit_price;
						}
					},
				},
				{
					data: null,
					render: function (data, type, row) {
						if (row.datefrom) {
							return row.datefrom;
						}
					},
				},
				{
					data: null,
					render: function (data, type, row) {
						if (row.dateto) {
							return row.dateto;
						}
					},
				},

				{
					data: null,
					render: function (data, type, row) {
						if (row.cus_price_date) {
							return row.cus_price_date;
						}
					},
				},

				{
					data: "id",
					render: function (data, type, row) {
						return `
                <div class="btn-group">
                <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  Action
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#" onClick="editprice(${data})">Edit</a></li>
                  <li><a class="dropdown-item" href="#" onClick="deletePrice(${data})">Delete</a></li>
                </ul>
              </div>


                `;
					},
				},
			],
		});
	}, [priceList]);
	const [modalIsOpen, setIsOpen] = useState(false);
	function openModal() {
		setIsOpen(true);
	}
	function closeModal() {
		setIsOpen(false);
	}
	const savePrice = (data) => {
		closeModal();
		reset();
		dispatch(
			customerPriceInsert(formData, (res) => {
				if (res.status === 200) {
					Swal.fire({
						title: "Success!",
						text: res.message,
						icon: "success",
						confirmButtonText: "OK",
					}).then((result) => {
						if (result.isConfirmed) {
							dispatch(getCustomerPrice());
						}
					});
				} else if (res.status === 301) {
					Swal.fire({
						title: "Error!",
						text: res.message,
						icon: "error",
						confirmButtonText: "OK",
					});
				}
			})
		);
	};

	window.deletePrice = function (id) {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(
					deleteCustomerPrice(id, (res) => {
						if (res.status === 200) {
							Swal.fire("Deleted!", res.message, "success").then((result) => {
								if (result.isConfirmed) {
									dispatch(getCustomerPrice());
								}
							});
						}
					})
				);
			}
		});
	};

	const [editID, setEditID] = useState("");

	window.editprice = function (id) {
		setEditID(id);

		dispatch(
			getCustomerPriceById(id, (res) => {
				// console.log(res);
				if (res.status === 200) {
					console.log(res.data);
					openModal();
					setFormData({
						...formData,
						cus_id: res.data.cus_id,
						cus_sale_price: res.data.cus_sale_price,
						cus_credit_price: res.data.cus_credit_price,
						datefrom: res.data.datefrom,
						dateto: res.data.dateto,
					});

					reset({
						cus_id: res.data.cus_id,
						cus_sale_price: res.data.cus_sale_price,
						cus_credit_price: res.data.cus_credit_price,
						datefrom: res.data.datefrom,
						dateto: res.data.dateto,
					});
				}
			})
		);
	};

	const updatePrice = (data) => {
		closeModal();
		reset();
		dispatch(
			updateCustomerPriceById(editID, formData, (res) => {
				if (res.status === 200) {
					Swal.fire({
						title: "Success!",
						text: res.message,
						icon: "success",
						confirmButtonText: "OK",
					}).then((result) => {
						if (result.isConfirmed) {
							dispatch(getCustomerPrice());
						}
					});
				}
			})
		);
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Update Price</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a
												href="index.html"
												data-bs-original-title="data-bs-original-title"
												title="title"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-home"
												>
													<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
													<polyline points="9 22 9 12 15 12 15 22" />
												</svg>
											</a>
										</li>
										<li className="breadcrumb-item">Price</li>
										<li className="breadcrumb-item active">Update</li>
									</ol>
								</div>
							</div>
						</div>
					</div>
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						contentLabel="Example Modal"
						className="modal-dialog modal-dialog-centered"
						closeTimeoutMS={500}
						style={{
							//  width 1200px
							overlay: {
								backgroundColor: "rgba(0, 0, 0, 0.5)",
								zIndex: 9999,
							},
						}}
					>
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">
									{editID ? "Update " : "Add "}
									Price
								</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
									onClick={closeModal}
								/>
							</div>
							<form onSubmit={handleSubmit(editID ? updatePrice : savePrice)}>
								<div className="modal-body">
									<div className="col-md-12 row">
										{editID ? (
											""
										) : (
											<div className="col-md-12">
												<div className="form-group">
													<label htmlFor="department_id">Client *</label>
													<Controller
														name="cus_id"
														control={control}
														rules={{ required: true }}
														render={({ field }) => (
															<AsyncSelect
																{...field}
																isClearable
																defaultOptions={customerList}
																loadOptions={customerLoadOptions}
																getOptionLabel={(option) => option.cus_name}
																getOptionValue={(option) => option.id}
																placeholder="Select Customer"
																value={customerList.find(
																	(obj) => obj.id === field.value
																)}
																onChange={(e) => {
																	field.onChange(e);
																	custmerValue(e);
																}}
															/>
														)}
													/>
												</div>
											</div>
										)}
										<div className="col-md-12">
											<label className="form-label">Sale Price</label>
											<input
												type="number"
												className="form-control"
												{...register("cus_sale_price", {
													required: true,
												})}
												onChange={(e) => {
													setFormData({
														...formData,
														cus_sale_price: e.target.value,
													});
												}}
												step="any"
											/>
											{errors.cus_sale_price && (
												<span className="text-danger">
													This field is required
												</span>
											)}
										</div>
										<div className="col-md-12 ">
											<label className="form-label">Credit Price</label>
											<input
												type="number"
												className="form-control"
												{...register("cus_credit_price", {
													required: true,
												})}
												onChange={(e) => {
													setFormData({
														...formData,
														cus_credit_price: e.target.value,
													});
												}}
												step="any"
											/>

											{errors.cus_credit_price && (
												<span className="text-danger">
													This field is required
												</span>
											)}
										</div>
										<div className="col-md-12 ">
											<label className="form-label">Date From</label>
											<input
												type="date"
												className="form-control"
												{...register("datefrom", {
													required: true,
												})}
												onChange={(e) => {
													setFormData({
														...formData,
														datefrom: e.target.value,
													});
												}}
												step="any"
												readOnly={editID ? true : false}
											/>

											{errors.datefrom && (
												<span className="text-danger">
													This field is required
												</span>
											)}
										</div>
										<div className="col-md-12 ">
											<label className="form-label">Date To</label>
											<input
												type="date"
												className="form-control"
												{...register("dateto", {
													required: true,
												})}
												onChange={(e) => {
													setFormData({
														...formData,
														dateto: e.target.value,
													});
												}}
												step="any"
												readOnly={editID ? true : false}
											/>

											{errors.dateto && (
												<span className="text-danger">
													This field is required
												</span>
											)}
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-secondary"
										data-bs-dismiss="modal"
										onClick={closeModal}
									>
										Close
									</button>
									<button type="submit" className="btn btn-primary">
										Save changes
									</button>
								</div>
							</form>
						</div>
					</Modal>
					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<button
											class="btn btn-primary"
											type="button"
											onClick={openModal}
										>
											ADD PRICE
										</button>
										<div className="table-responsive">
											<table className="display" id="price-table">
												<thead>
													<tr>
														<th>Sl no</th>
														<th>Customer Name</th>
														<th>Sale Price</th>
														<th>Credit Price</th>
														<th>Date from</th>
														<th>Date To</th>
														<th>Created At</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
