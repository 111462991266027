import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import DataTable from "datatables.net-dt";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getAllCustomers,
	getCustomerTags,
	getVehicleByCustomerId,
	saveCustomerTags,
	deleteCustomerTags,
	getTagsById,
	updateTagsById,
	getTanks,
	getTagInfoById,
	changeTagStatus,
} from "../../redux/api/apiCustomer";
import {
	selectCustomerByVehicle,
	selectCustomers,
	selectTags,
	selectTanks,
} from "../../redux/Customers";
import Modal from "react-modal";
import AsyncSelect from "react-select/async";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
const tdStyle = {
	padding: "0.5rem",
	verticalAlign: "top",
	borderBottom: "1px solid #dee2e6",
};
const thStyle = {
	verticalAlign: "bottom",
	borderBottom: "2px solid #dee2e6",
	padding: "0.5rem",
};

export default function ManageTag() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();

	const [modalIsOpen, setIsOpen] = useState(false);
	const [editID, setEditID] = useState("");
	const customerList = useSelector(selectCustomers);
	const vehicleData = useSelector(selectCustomerByVehicle);
	const tankList = useSelector(selectTanks);
	function openModal() {
		setIsOpen(true);
		reset({
			cus_id: "",
			vehicle_id: "",
			identification_code: "",
			type: "",
			absLimit: 0,
			absBalance: 0,
			shiftLimit: 0,
			shiftBalance: 0,
			dayLimit: 0,
			dayBalance: 0,
			weekLimit: 0,
			weekBalance: 0,
			monthLimit: 0,
			monthBalance: 0,
		});
	}
	function closeModal() {
		setIsOpen(false);
		reset();
		setEditID("");
	}
	const dispatch = useDispatch();
	const tags = useSelector(selectTags);

	useEffect(() => {
		dispatch(getAllCustomers());
		dispatch(getCustomerTags());
		dispatch(getTanks());
	}, [dispatch]);
	const customerLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				customerList.filter((i) =>
					i.cus_name.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};
	// const vehicleLoadOptions = (inputValue, callback) => {
	// 	setTimeout(() => {
	// 		callback(
	// 			vehicleData.filter((i) =>
	// 				i.vehicle_name.toLowerCase().includes(inputValue.toLowerCase())
	// 			)
	// 		);
	// 	}, 1000);
	// };
	const vehicleLoadOptions = (inputValue, callback) => {
		// Simulate an API call or data fetch. Replace this with your actual data fetching logic.
		setTimeout(() => {
			const filteredOptions = vehicleData.filter((vehicle) => {
				const nameMatches = vehicle.vehicle_name
					.toLowerCase()
					.includes(inputValue.toLowerCase());
				const plateMatches = vehicle.vehicle_plate_number
					.toLowerCase()
					.includes(inputValue.toLowerCase());
				const numberMatches =
					vehicle.vehicle_number &&
					vehicle.vehicle_number
						.toLowerCase()
						.includes(inputValue.toLowerCase());

				// Combine the conditions using logical OR (||) to include non-null fields in the search.
				return nameMatches || plateMatches || numberMatches;
			});

			// Call the callback function with the filtered options.
			callback(filteredOptions);
		}, 1000); // Adjust the delay as needed.
	};

	const vehicleValue = (e) => {
		if (e === null || e === undefined || e.id === null || e.id === undefined) {
			setFormData({
				...formData,
				vehicle_id: "",
			});
			return;
		}

		setFormData({
			...formData,
			vehicle_id: e.id,
		});
	};
	const [formData, setFormData] = useState({
		cus_id: "",
		vehicle_id: "",
		identification_code: "",
		type: "",
		absLimit: 0,
		absBalance: 0,
		shiftLimit: 0,
		shiftBalance: 0,
		dayLimit: 0,
		dayBalance: 0,
		weekLimit: 0,
		weekBalance: 0,
		monthLimit: 0,
		monthBalance: 0,
	});

	const custmerValue = (e) => {
		if (e === null) {
			setFormData({
				...formData,
				cus_id: "",
			});
			return;
		}
		setFormData({
			...formData,
			cus_id: e.id,
		});
		dispatch(getVehicleByCustomerId(e.id));
	};

	let table = new DataTable("#tag-table");

	useEffect(() => {
		table.destroy();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		table = new DataTable("#tag-table", {
			data: tags,
			columns: [
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.customer) {
							return data.customer.cus_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type, row) {
						if (data.vehicle) {
							return data.vehicle.vehicle_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type, row) {
						if (data.vehicle) {
							return data.vehicle.vehicle_number;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type, row) {
						if (data.vehicle) {
							return data.vehicle.vehicle_plate_number;
						}
						return "";
					},
				},

				{
					data: null,
					render: function (data, type, row) {
						if (data.identification_code) {
							return data.identification_code;
						}
						return "";
					},
				},
				{
					data: "geotek_key_id",
					render: function (data, type, row) {
						// checkbox
						return (
							//  sync tag
							`
              <button type="button" class="btn btn-primary" onClick="syncTag(${data})">Sync Tag</button>

              `
						);
					},
				},
				{
					data: "id",
					render: function (data, type, row) {
						return `
                <div class="btn-group">
                <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  Action
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#" onClick="editTag(${data})">Edit</a></li>
                  <li><a class="dropdown-item" href="#" onClick="deleteTag(${data})">Delete</a></li>
                </ul>
              </div>


                `;
					},
				},
			],
		});
	}, [tags]);

	const saveTag = (data) => {
		reset();
		closeModal();
		Swal.fire({
			title: "Please wait...",
			text: "We are saving your data",
			icon: "info",
			showConfirmButton: false,
			allowOutsideClick: false,
		});

		dispatch(
			saveCustomerTags(formData, (res) => {
				// console.log(res);
				if (res.status === 200) {
					Swal.close();
					Swal.fire({
						title: "Success!",
						text: "Tag has been saved successfully",
						icon: "success",
						confirmButtonText: "Ok",
					}).then((result) => {
						if (result.isConfirmed) {
							dispatch(getCustomerTags());
						}
					});
				} else if (res.status === 301) {
					Swal.close();
					Swal.fire({
						title: "Error!",
						text: res.message,
						icon: "error",
						confirmButtonText: "Ok",
					});
				} else {
					Swal.close();
					Swal.fire({
						title: "Error!",
						text: "Something went wrong",
						icon: "error",
						confirmButtonText: "Ok",
					});
					dispatch(getCustomerTags());
				}
				setFormData({
					cus_id: "",
					vehicle_id: "",
					identification_code: "",
					type: "",
					absLimit: 0,
					absBalance: 0,
					shiftLimit: 0,
					shiftBalance: 0,
					dayLimit: 0,
					dayBalance: 0,
					weekLimit: 0,
					weekBalance: 0,
					monthLimit: 0,
					monthBalance: 0,
				});
			})
		);
	};

	window.deleteTag = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You will not be able to recover this Tag!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, delete it!",
			cancelButtonText: "No, keep it",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(
					deleteCustomerTags(id, (res) => {
						if (res.status === 200) {
							Swal.fire({
								title: "Deleted!",
								text: "Tag has been deleted.",
								icon: "success",
								confirmButtonText: "Ok",
							}).then((result) => {
								if (result.isConfirmed) {
									dispatch(getCustomerTags());
								}
							});
						} else if (res.status === 301) {
							Swal.fire({
								title: "Error!",
								text: res.message,
								icon: "error",
								confirmButtonText: "Ok",
							});
						}
					})
				);
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire("Cancelled", "Tag is safe :)", "error");
			}
		});
	};

	window.editTag = (id) => {
		dispatch(
			getTagsById(id, (res) => {
				if (res.status === 200) {
					dispatch(getVehicleByCustomerId(res.data.cus_id));
					setEditID(id);
					openModal();
					setFormData({
						...formData,
						cus_id: res.data.cus_id,
						vehicle_id: res.data.vehicle_id,
						identification_code: res.data.identification_code,
						type: res.data.type,
						absLimit: res.geoData.absLimit,
						absBalance: res.geoData.absBalance,
						shiftLimit: res.geoData.shiftLimit,
						shiftBalance: res.geoData.shiftBalance,
						dayLimit: res.geoData.dayLimit,
						dayBalance: res.geoData.dayBalance,
						weekLimit: res.geoData.weekLimit,
						weekBalance: res.geoData.weekBalance,
						monthLimit: res.geoData.monthLimit,
						monthBalance: res.geoData.monthBalance,
					});
					reset({
						cus_id: res.data.cus_id,
						vehicle_id: res.data.vehicle_id,
						identification_code: res.data.identification_code,
						type: res.data.type,
						absLimit: res.geoData.absLimit,
						absBalance: res.geoData.absBalance,
						shiftLimit: res.geoData.shiftLimit,
						shiftBalance: res.geoData.shiftBalance,
						dayLimit: res.geoData.dayLimit,
						dayBalance: res.geoData.dayBalance,
						weekLimit: res.geoData.weekLimit,
						weekBalance: res.geoData.weekBalance,
						monthLimit: res.geoData.monthLimit,
						monthBalance: res.geoData.monthBalance,
					});
				}
			})
		);
	};

	const updateTag = (data) => {
		reset();
		closeModal();
		Swal.fire({
			title: "Please wait...",
			text: "We are saving your data",
			icon: "info",
			showConfirmButton: false,
			allowOutsideClick: false,
		});
		dispatch(
			updateTagsById(editID, formData, (res) => {
				if (res.status === 200) {
					Swal.close();
					Swal.fire({
						title: "Success!",
						text: "Tag has been updated successfully",
						icon: "success",
						confirmButtonText: "Ok",
					}).then((result) => {
						if (result.isConfirmed) {
							dispatch(getCustomerTags());
						}
					});
				} else if (res.status === 301) {
					Swal.close();
					Swal.fire({
						title: "Error!",
						text: res.message,
						icon: "error",
						confirmButtonText: "Ok",
					});
				} else if (res.status === 401) {
					Swal.close();
					Swal.fire({
						title: "Error!",
						text: res.error,
						icon: "error",
						confirmButtonText: "Ok",
					});
				}
			})
		);
	};
	const [modalSyncOpen, setModalSyncOpen] = useState(false);
	function closeSyncModal() {
		setModalSyncOpen(false);
	}

	const [tagInfo, setTagInfo] = useState([]);
	const [tagId, setTagId] = useState("");
	const [checkedValues, setCheckedValues] = useState([]);
	let table2 = new DataTable("#sync-tag-table");
	window.syncTag = (id) => {
		setTagId(id);
		Swal.fire({
			title: "Syncing Tag",
			html: "Please wait...", // add html attribute if you want or remove
			allowOutsideClick: false,
			onBeforeOpen: () => {
				Swal.showLoading();
			},
		});
		dispatch(
			getTagInfoById(id, (res) => {
				setTimeout(() => {
					table2.destroy();
					// eslint-disable-next-line react-hooks/exhaustive-deps
					table2 = new DataTable("#sync-tag-table");
				}, 100);
				if (res.status === 200) {
					Swal.close();
					setModalSyncOpen(true);
					setTagInfo(res.data);

					const objectId = res.data.map((item) => item.objectId);
					setCheckedValues(objectId);

					resetSync({
						trackerId: objectId,
					});

					// after 1 second
				}
			})
		);
	};

	const {
		handleSubmit: handleSubmitSync,
		register: registerSync,
		reset: resetSync,
	} = useForm();
	let values;
	const syncTagData = (data) => {
		closeSyncModal();
		Swal.fire({
			title: "Please wait...",
			text: "We are saving your data",
			icon: "info",
			showConfirmButton: false,
			allowOutsideClick: false,
		});
		if (checkedValues.length === 0) {
			values = 13;
		} else {
			values = checkedValues;
		}

		dispatch(
			changeTagStatus(tagId, values, (res) => {
				if (res.status === 200) {
					Swal.fire({
						title: "Success!",
						text: "Tag request has been sent successfully | it may be will take 0-30 seconds to sync",
						icon: "success",
						confirmButtonText: "Ok",
					}).then((result) => {
						if (result.isConfirmed) {
							dispatch(getCustomerTags());
						}
					});
				}
			})
		);
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Manage Tag</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="index.html">
												<i data-feather="home" />
											</a>
										</li>
										<li className="breadcrumb-item">Tag</li>
										<li className="breadcrumb-item active">Manage Tag </li>
									</ol>
								</div>
							</div>
						</div>
					</div>
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						contentLabel="Example Modal"
						className="modal-dialog modal-dialog-centered"
						closeTimeoutMS={500}
						style={{
							//  width 1200px
							overlay: {
								backgroundColor: "rgba(0, 0, 0, 0.5)",
								zIndex: 9999,
							},
						}}
					>
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">
									{editID ? "Edit Tag" : "Add Tag"}
								</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
									onClick={closeModal}
								/>
							</div>
							<form onSubmit={handleSubmit(editID ? updateTag : saveTag)}>
								<div className="modal-body">
									<div className="col-md-12 row">
										<div className="col-md-6">
											<div className="form-group">
												<label htmlFor="department_id">Client *</label>
												<Controller
													name="cus_id"
													control={control}
													rules={{ required: true }}
													render={({ field }) => (
														<AsyncSelect
															{...field}
															isClearable
															defaultOptions={customerList}
															loadOptions={customerLoadOptions}
															getOptionLabel={(option) => option.cus_name}
															getOptionValue={(option) => option.id}
															placeholder="Select Customer"
															value={customerList.find(
																(obj) => obj.id === field.value
															)}
															onChange={(e) => {
																field.onChange(e);
																custmerValue(e);
															}}
														/>
													)}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label htmlFor="documents">Vehicle </label>
												<Controller
													name="vehicle_id"
													control={control}
													rules={{ required: true }}
													render={({ field }) => (
														<AsyncSelect
															{...field}
															isClearable
															defaultOptions={vehicleData}
															loadOptions={vehicleLoadOptions}
															getOptionLabel={(option) =>
																`${option.vehicle_name} - ${option.vehicle_plate_number}
																- ${option.vehicle_number}
																`
															}
															getOptionValue={(option) => option.id}
															placeholder="Select Vehicle"
															value={vehicleData.find(
																(obj) => obj.id === field.value
															)}
															onChange={(e) => {
																field.onChange(e);
																vehicleValue(e);
															}}
														/>
													)}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label htmlFor="exampleInputEmail1">
													Vehicle Identification Code
												</label>
												<input
													type="text"
													className="form-control"
													placeholder="Enter Vehicle Identification Code"
													autoComplete="off"
													{...register("identification_code", {
														required: true,
													})}
													onChange={(e) => {
														setFormData({
															...formData,
															identification_code: e.target.value,
														});
													}}
												/>
											</div>
											{errors.identification_code && (
												<span style={{ color: "red" }}>
													Vehicle Identification Code required
												</span>
											)}
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label htmlFor="documents">Identification Type</label>
												<select
													className="form-control select2 form-select select2-hidden-accessible"
													{...register("type", { required: true })}
													onChange={(e) => {
														setFormData({
															...formData,
															type: e.target.value,
														});
													}}
												>
													<option value="">Select Type</option>

													<option value="iButton">iButton</option>
													<option value="RFID">RFID</option>
													<option value="Code">Code</option>
												</select>
											</div>
											{errors.type && (
												<span style={{ color: "red" }}>
													Identification Type required
												</span>
											)}
										</div>

										<div className="col-md-12">
											<label htmlFor="exampleInputEmail1">Limit</label>
											<table
												className="table table-bordered  border-bottom table-responsive"
												id="user-table"
											>
												<thead>
													<tr style={{ textAlign: "center" }}>
														<th
															className="border-bottom-0"
															style={{ width: 500 }}
														>
															-{" "}
														</th>
														<th
															className="border-bottom-0"
															style={{ width: 500 }}
														>
															LIMIT
														</th>
														<th
															className="border-bottom-0"
															style={{ width: 500 }}
														>
															BALANCE
														</th>
													</tr>
												</thead>
												<tbody style={{ textAlign: "center" }}>
													<tr>
														<td> Absolute </td>
														<td>
															<input
																type="text"
																name="absLimit"
																className="minimal"
																{...register("absLimit", {
																	required: false,
																})}
																onChange={(e) => {
																	setFormData({
																		...formData,
																		absLimit: e.target.value,
																	});
																}}
															/>
														</td>
														<td>
															<input
																type="text"
																name="absBalance"
																className="minimal"
																{...register("absBalance", {
																	required: false,
																})}
																onChange={(e) => {
																	setFormData({
																		...formData,
																		absBalance: e.target.value,
																	});
																}}
																readOnly
															/>
														</td>
													</tr>
													<tr>
														<td> Shift </td>
														<td>
															<input
																type="text"
																name="shiftLimit"
																className="minimal"
																{...register("shiftLimit", {
																	required: false,
																})}
																onChange={(e) => {
																	setFormData({
																		...formData,
																		shiftLimit: e.target.value,
																	});
																}}
															/>
														</td>
														<td>
															<input
																type="text"
																name="shiftBalance"
																className="minimal"
																{...register("shiftBalance", {
																	required: false,
																})}
																onChange={(e) => {
																	setFormData({
																		...formData,
																		shiftBalance: e.target.value,
																	});
																}}
																readOnly
															/>
														</td>
													</tr>
													<tr>
														<td> Day </td>
														<td>
															<input
																type="text"
																name="dayLimit"
																className="minimal"
																{...register("dayLimit", {
																	required: false,
																})}
																onChange={(e) => {
																	setFormData({
																		...formData,
																		dayLimit: e.target.value,
																	});
																}}
															/>
														</td>
														<td>
															<input
																type="text"
																name="dayBalance"
																className="minimal"
																{...register("dayBalance", {
																	required: false,
																})}
																onChange={(e) => {
																	setFormData({
																		...formData,
																		dayBalance: e.target.value,
																	});
																}}
																readOnly
															/>
														</td>
													</tr>
													<tr>
														<td> Week </td>
														<td>
															<input
																type="text"
																name="weekLimit"
																className="minimal"
																{...register("weekLimit", {
																	required: false,
																})}
																onChange={(e) => {
																	setFormData({
																		...formData,
																		weekLimit: e.target.value,
																	});
																}}
															/>
														</td>
														<td>
															<input
																type="text"
																name="weekBalance"
																className="minimal"
																{...register("weekBalance", {
																	required: false,
																})}
																onChange={(e) => {
																	setFormData({
																		...formData,
																		weekBalance: e.target.value,
																	});
																}}
																readOnly
															/>
														</td>
													</tr>
													<tr>
														<td> Month </td>
														<td>
															<input
																type="text"
																name="monthLimit"
																className="minimal"
																{...register("monthLimit", {
																	required: false,
																})}
																onChange={(e) => {
																	setFormData({
																		...formData,
																		monthLimit: e.target.value,
																	});
																}}
															/>
														</td>
														<td>
															<input
																type="text"
																name="monthBalance"
																className="minimal"
																{...register("monthBalance", {
																	required: false,
																})}
																onChange={(e) => {
																	setFormData({
																		...formData,
																		monthBalance: e.target.value,
																	});
																}}
																readOnly
															/>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-secondary"
										data-bs-dismiss="modal"
										onClick={closeModal}
									>
										Close
									</button>
									<button type="submit" className="btn btn-primary">
										Save changes
									</button>
								</div>
							</form>
						</div>
					</Modal>
					<Modal
						isOpen={modalSyncOpen}
						onRequestClose={closeSyncModal}
						contentLabel="Example Modal"
						className="modal-dialog modal-dialog-centered"
						closeTimeoutMS={500}
						style={{
							//  width 1200px
							overlay: {
								backgroundColor: "rgba(0, 0, 0, 0.5)",
								zIndex: 9999,
							},
						}}
					>
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Sync Tag</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
									onClick={closeSyncModal}
								/>
							</div>
							<form onSubmit={handleSubmitSync(syncTagData)}>
								<div className="modal-body">
									<div className="col-md-12 row">
										<div className="col-md-12">
											<div className="table-responsive">
												<table className="display" id="sync-tag-table">
													<thead>
														<tr
															style={{
																backgroundColor: "#f2f2f2",
																color: "#000",
																border: "1px solid #000",
															}}
														>
															<th style={thStyle} scope="col">
																Sl no
															</th>
															<th style={thStyle} scope="col">
																Tank Name
															</th>
															<th style={thStyle} scope="col">
																Activate / InActivate
															</th>
															<th style={thStyle} scope="col">
																Status
															</th>
														</tr>
													</thead>
													<tbody style={tdStyle}>
														{tankList.map((item, index) => {
															let syncMessage = (
																<span style={{ color: "red" }}>
																	Not Synchronized
																</span>
															);
															for (let i = 0; i < tagInfo.length; i++) {
																const item1 = tagInfo[i];
																if (item.tank_id === item1.objectId) {
																	if (item1.needUpdate || item1.needAdd) {
																		syncMessage = (
																			<span style={{ color: "red" }}>
																				Synchronization Required
																			</span>
																		);
																	} else if (item1.needDelete) {
																		syncMessage = (
																			<span style={{ color: "red" }}>
																				Waiting To Be Removed
																			</span>
																		);
																	} else {
																		syncMessage = (
																			<span style={{ color: "green" }}>
																				Synchronized
																			</span>
																		);
																	}
																	break; // Exit the loop once a match is found
																}
															}

															return (
																<tr
																	key={index}
																	style={{
																		backgroundColor: "#fff",
																		color: "#000",
																		border: "1px solid #000",
																	}}
																>
																	<td style={tdStyle}>{index + 1}</td>
																	<td style={tdStyle}>{item.description}</td>
																	<td style={tdStyle}>
																		<input
																			type="checkbox"
																			value={item.tank_id}
																			checked={checkedValues.includes(
																				item.tank_id
																			)}
																			onChange={(event) => {
																				if (event.target.checked) {
																					setCheckedValues([
																						...checkedValues,
																						item.tank_id,
																					]);
																				} else {
																					setCheckedValues(
																						checkedValues.filter(
																							(val) => val !== item.tank_id
																						)
																					);
																				}
																			}}
																		/>
																	</td>
																	<td style={tdStyle}>{syncMessage}</td>
																</tr>
															);
														})}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-secondary"
										data-bs-dismiss="modal"
										onClick={closeSyncModal}
									>
										Close
									</button>
									<button type="submit" className="btn btn-primary">
										Submit
									</button>
								</div>
							</form>
						</div>
					</Modal>
					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<button
											class="btn btn-primary"
											type="button"
											onClick={openModal}
										>
											Add Tag
										</button>

										<div className="table-responsive">
											<table
												className="display"
												id="tag-table"
												style={{
													width: "100%",
													textAlign: "center",
													border: "1px solid #dee2e6",
												}}
											>
												<thead>
													<tr>
														<th>Sl no</th>
														<th>Customer Name</th>
														<th>Vehicle Name</th>
														<th>Vehicle Number</th>
														<th>Vehicle PlateNumber</th>
														<th>Identification Code</th>
														<th style={{ width: "25%" }}>Sync</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
