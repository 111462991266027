import { createSlice } from "@reduxjs/toolkit";

const accountsSlice = createSlice({
	name: "accounts",
	initialState: {
		accounts: [],
		adjustments: [],
		transfer: [],
	},
	reducers: {
		getAccounts: (state, action) => {
			state.accounts = action.payload;
		},
		getAdjustments: (state, action) => {
			state.adjustments = action.payload;
		},
		getTransfer: (state, action) => {
			state.transfer = action.payload;
		},
	},
});

export const { getAccounts, getAdjustments, getTransfer } =
	accountsSlice.actions;

export const accountsSelector = (state) => state.accounts.accounts;
export const adjustmentsSelector = (state) => state.accounts.adjustments;
export const transferSelector = (state) => state.accounts.transfer;

export default accountsSlice.reducer;
