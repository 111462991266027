import { createSlice } from "@reduxjs/toolkit";

const expenseSlice = createSlice({
	name: "expense",
	initialState: {
		expenses: [],
		category: [],
	},
	reducers: {
		getExpense: (state, action) => {
			state.expenses = action.payload;
		},
		getCategory: (state, action) => {
			state.category = action.payload;
		},
	},
});

export const { getExpense, getCategory } = expenseSlice.actions;

export const expenseSelector = (state) => state.expense.expenses;
export const categorySelector = (state) => state.expense.category;

export default expenseSlice.reducer;
