import { createSlice } from "@reduxjs/toolkit";

const driverSlice = createSlice({
	name: "driver",
	initialState: {
		driver: [],
		driverCategory: [],
	},
	reducers: {
		getDriver: (state, action) => {
			state.driver = action.payload;
		},
		getDriverCategory: (state, action) => {
			state.driverCategory = action.payload;
		},
	},
});

export const { getDriver, getDriverCategory } = driverSlice.actions;

export const selectDriver = (state) => state.driver.driver;
export const selectDriverCategory = (state) => state.driver.driverCategory;

export default driverSlice.reducer;
