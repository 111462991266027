import React, { Component } from "react";
import Chart from "react-apexcharts";

class StockAnalysis extends Component {
constructor(props) {
          super(props);

          this.state = {
          
            series: [{
              name: "STOCK ABC",
              data: [31, 40, 28, 51, 42, 109, 100]
            }],
            options:{
    chart: {
      height: 350,
      type: 'area',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
 
    title: {
      text: 'Fundamental Analysis of Stocks',
      align: 'left',
    },
    subtitle: {
      text: 'Price Movements',
      align: 'left',
    },
    labels: ['01/01/2003', '02/01/2003', '03/01/2003', '04/01/2003', '05/01/2003', '06/01/2003', '07/01/2003'],
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      opposite: true,
    },
    legend: {
      horizontalAlign: 'left',
    },
    colors: ['#008FFB']
  }
          
          
          };
        }


  render() {
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="area"
              width="100%"
            />
    
          </div>
        </div>
      </div>
    );
  }
}

export default StockAnalysis;