import axios from "axios";
import { getSale, getTags, getNotification, getLastTransaction } from "../Sale";
import Swal from "sweetalert2";

const apiSale = axios.create({
	baseURL: process.env.REACT_APP_BASEURL + "sales",
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",

		"Access-Control-Allow-Origin": "*",
		"X-Server-ID": process.env.REACT_APP_SERVER_ID,
	},
});

apiSale.interceptors.request.use((config) => {
	const token = localStorage.getItem("token").replace(/['"]+/g, "");
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	return config;
});

export const getAllSales = (payload, callback) => (dispatch) =>
	apiSale.post("/get/data", payload).then((res) => {
		// console.log(res.data);
		if (res.data.status === 200) {
			if (callback) {
				callback(res.data);
			}
			// console.log(res.data.data);
			dispatch(getSale(res.data));
		}
	});

// get sale by id
export const getSaleById = (id, callback) => (dispatch) =>
	apiSale
		.get("/get/data/" + id)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			}).then(() => {
				window.location = "/manage-sale";
			});
		});

// update transaction type by id
export const updateTransactionType = (payload, callback) => (dispatch) =>
	apiSale
		.put("/update/transactiontype", payload)
		.then((res) => {
			if (callback) {
				callback(res.data);
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

// get tags by vehicle id

export const getTagsByVehicleId = (id, callback) => (dispatch) =>
	apiSale.get("/get/tags/" + id).then((res) => {
		if (callback) {
			callback(res.data);
		}
		dispatch(getTags(res.data.data));
	});

//  update sale by id
export const updateSaleById = (id, payload, callback) => (dispatch) =>
	apiSale.put("/update/" + id, payload).then((res) => {
		if (callback) {
			callback(res.data);
		}
	});

// delete sale by id
export const deleteSaleById = (id, callback) => (dispatch) =>
	apiSale.delete("/delete/" + id).then((res) => {
		if (callback) {
			callback(res.data);
		}
	});
// get notification
export const getNotifications = (callback) => (dispatch) =>
	apiSale.get("/get/notification").then((res) => {
		if (callback) {
			callback(res.data);
		}
		dispatch(getNotification(res.data));
	});
// get last transactions
export const getLastTransactions = (callback) => (dispatch) =>
	apiSale.get("/get/last/transaction").then((res) => {
		if (callback) {
			callback(res.data);
		}
		dispatch(getLastTransaction(res.data));
	});
// update cancel transaction
export const updateCancelTransaction = (id, payload, callback) => (dispatch) =>
	apiSale.get("/cancel/transaction/" + id, payload).then((res) => {
		if (callback) {
			callback(res.data);
		}
	});
