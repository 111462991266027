import axios from "axios";
import { getDriver, getDriverCategory } from "../Driver";

const apiDriver = axios.create({
	baseURL: process.env.REACT_APP_BASEURL + "driver",
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",

		"Access-Control-Allow-Origin": "*",
		"X-Server-ID": process.env.REACT_APP_SERVER_ID,
	},
});

apiDriver.interceptors.request.use((config) => {
	const token = localStorage.getItem("token").replace(/['"]+/g, "");
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	return config;
});

export const getAllDriver = (callback) => (dispatch) =>
	apiDriver
		.get("/fetch")
		.then((res) => {
			// console.log(res.data.data);
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data.data);
				}
				dispatch(getDriver(res.data.data));
			}
		})
		.catch((err) => {
			// console.log(err);
		});

// create driver category
export const createDriverDepartment = (data, callback) => (dispatch) =>
	apiDriver
		.post("/category/insert", data)
		.then((res) => {
			if (res.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			// console.log(err);
		});

// get driver category

export const getDriverCategoryList = (callback) => (dispatch) =>
	apiDriver
		.get("/category/fetch")
		.then((res) => {
			// console.log(res);
			if (res.status === 200) {
				if (callback) {
					callback(res.data.data);
				}
				dispatch(getDriverCategory(res.data.data));
			}
		})
		.catch((err) => {
			// console.log(err);
		});

// delete driver category

export const deleteDriverCategory = (id, callback) => (dispatch) =>
	apiDriver
		.delete("/category/delete/" + id)
		.then((res) => {
			if (res.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			// console.log(err);
		});

// update driver category

export const updateDriverCategory = (id, data, callback) => (dispatch) =>
	apiDriver
		.put("/category/update/" + id, data)
		.then((res) => {
			if (res.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			// console.log(err);
		});

// get driver category by id

export const getDriverCategoryById = (id, callback) => (dispatch) =>
	apiDriver
		.get("/category/fetch/" + id)
		.then((res) => {
			if (res.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			// console.log(err);
		});

// get driver by id

export const getDriverById = (id, callback) => (dispatch) =>
	apiDriver
		.get("/fetch/" + id)
		.then((res) => {
			if (res.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			// console.log(err);
		});

// update driver

export const updateDriverInfo = (id, data, callback) => (dispatch) =>
	apiDriver
		.put("/update/" + id, data)
		.then((res) => {
			console.log(res.data.status);
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			// console.log(err);
		});

// delete driver

export const deleteDriver = (id, callback) => (dispatch) =>
	apiDriver
		.delete("/delete/" + id)
		.then((res) => {
			if (res.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			// console.log(err);
		});

// create driver

export const createDriver = (data, callback) => (dispatch) =>
	apiDriver
		.post("/insert", data)
		.then((res) => {
			// console.log(res.data.status);
			if (callback) {
				callback(res.data);
			}
		})
		.catch((err) => {
			// console.log(err);
		});
