import React, { useState } from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";

import { getBalanceSheet } from "../../redux/api/apiReports";
import { useDispatch, useSelector } from "react-redux";

export default function Blancesheet() {
	const [exportToPdf, setExportToPdf] = useState(false);
	const exportPDFWithComponent = () => {};
	let defaultDate = new Date();
	defaultDate.setDate(defaultDate.getDate());
	// defaultDate to year month date
	defaultDate = defaultDate.toISOString().substr(0, 10);
	// setCurrentDate(defaultDate);
	// console.log(defaultDate);
	const [filterList, setFilterList] = useState({
		datefrom: defaultDate,
		dateto: defaultDate,
	});

	const dispatch = useDispatch();
	const getReport = () => {
		dispatch(
			getBalanceSheet(filterList, (res) => {
				// console.log(res);
				setExportToPdf(true);
			})
		);
	};
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />

				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Balance Sheet Report</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="index.html">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-home"
												>
													<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
													<polyline points="9 22 9 12 15 12 15 22" />
												</svg>
											</a>
										</li>
										<li className="breadcrumb-item"> Reports</li>
										<li className="breadcrumb-item active">
											Balance Sheet Report
										</li>
									</ol>
								</div>
							</div>
						</div>
					</div>
					{/* Container-fluid starts*/}
					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<label htmlFor="vehicle">DateFrom</label>
													<input
														type="date"
														className="form-control"
														id="dateFrom"
														onChange={(e) =>
															setFilterList({
																...filterList,
																datefrom: e.target.value,
															})
														}
														defaultValue={defaultDate}
														value={filterList.datefrom}
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<label htmlFor="vehicle">DateTo</label>
													<input
														type="date"
														className="form-control"
														id="dateTo"
														onChange={(e) =>
															setFilterList({
																...filterList,
																dateto: e.target.value,
															})
														}
														defaultValue={defaultDate}
														value={filterList.dateto}
													/>
												</div>
											</div>
											<div
												className="col-md-3 mt-4"
												style={{
													display: "inline-grid",
													marginLeft: "10px",
												}}
											>
												<button
													type="button"
													className="btn btn-primary btn-sm"
													onClick={getReport}
												>
													Search
												</button>
											</div>
											<div
												className="col-md-3 mt-4"
												style={{
													display: exportToPdf ? "inline-grid" : "none",
													marginLeft: "10px",
												}}
											>
												<button
													type="button"
													className="btn btn-success btn-sm"
													id="export_to_pdf"
													onClick={exportPDFWithComponent}
												>
													PDF
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div
								className="col-sm-12"
								style={{
									display: exportToPdf ? "inline-grid" : "none",
								}}
							>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-12 invoice p-3 mb-3">
												<div className="row invoice-info">
													<div className="col-sm-4 m-auto invoice-col">
														<div className="company-logo text-center">
															<img
																src="http://127.0.0.1:8000/creation_billing.png"
																alt="Acculance"
																className="lg-logo"
															/>
															<address className="mt-2">
																{" "}
																<strong>Phone:</strong>
																0170000000
																<br /> <strong>Email: </strong>
																support@creationfms.com
																<br /> <strong>Address: </strong>
																Mohammed Salem Bu Khamis street Star Tower 1 ,
																Floor 11 - Office 1107 - Ajman
															</address>
														</div>
													</div>
												</div>
												<div className="row mt-3 position-relative">
													<div
														className="position-absolute w-100 h-100 opacity-50 z-10 tbl-loading"
														style={{ display: "none" }}
													>
														<div className="loader07" />
													</div>
													<div className="table-responsive w-100">
														<table className="table table-striped table-bordered">
															<thead>
																<tr className="text-center">
																	<th colSpan={2}>
																		<h5>Incomes</h5>
																	</th>
																	<th colSpan={2} className="red">
																		<h5>Liabilities</h5>
																	</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<th>Total Security/Asset=&gt;</th>
																	<th className="text-right">$250000.00</th>
																	<th>Supplier's Dues=&gt;</th>
																	<th className="text-right">$128109.00</th>
																</tr>
																<tr>
																	<th>Inventory Value=&gt;</th>
																	<th className="text-right">$65681.80</th>
																	<th>Bank Loan=&gt;</th>
																	<th className="text-right">$190454.60</th>
																</tr>
																<tr>
																	<th>Client's Dues=&gt;</th>
																	<th className="text-right">$109495.25</th>
																</tr>
																<tr>
																	<th>Bank Balance=&gt;</th>
																	<th className="text-right">$424878.08</th>
																</tr>
																<tr className="text-right">
																	<th>Total=&gt;</th>
																	<th>$850055.13</th>
																	<th>Total=&gt;</th>
																	<th>$318563.60</th>
																</tr>
															</tbody>
															<tfoot>
																<tr>
																	<td colSpan={4} className="text-center">
																		<strong>(Income - Liabilities)</strong>
																		<br />
																		<h4 className="mt-2">
																			Total Asset:
																			<span className="text-success">
																				$531491.53
																			</span>
																		</h4>
																	</td>
																</tr>
															</tfoot>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Footer />
			</div>
		</div>
	);
}
