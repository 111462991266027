import { configureStore } from "@reduxjs/toolkit";

import customers from "./Customers";
import vehicle from "./Vehicle";
import sale from "./Sale";
import driver from "./Driver";
import user from "./Users";
import accounts from "./Accounts";
import expense from "./Expense";
import Supplier from "./Supplier";
import Purchase from "./Purchase";
import taxinvoice from "./TaxInvoice";
import report from "./Reports";
export const store = configureStore({
	reducer: {
		customers: customers,
		vehicle: vehicle,
		sale: sale,
		driver: driver,
		user: user,
		accounts: accounts,
		expense: expense,
		supplier: Supplier,
		purchase: Purchase,
		taxinvoice: taxinvoice,
		report: report,
	},
});

export default store;
