import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAllCustomers } from "../../redux/api/apiCustomer";
import { selectCustomers } from "../../redux/Customers";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { createUser, fetchUserById, updateUser } from "../../redux/api/apiUser";
import Swal from "sweetalert2";

export default function Addusers() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const customerList = useSelector(selectCustomers);
	useEffect(() => {
		dispatch(getAllCustomers());
	}, [dispatch]);
	const [formData, setFormData] = useState({
		cus_id: "",
		username: "",
		password: "",
		permission: [],
	});
	const customerLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				customerList.filter((i) =>
					i.cus_name.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};
	const custmerValue = (e) => {
		if (e === null) {
			setFormData({
				...formData,
				cus_id: "",
			});
			return;
		}
		setFormData({
			...formData,
			cus_id: e.id,
		});
	};
	const [customer_field, setCustomer] = useState(false);
	const setShowCustomer = (e) => {
		if (e.target.value === "customer") {
			setCustomer(true);
			document.getElementById("customer").style.display = "block";
			document.getElementById("permission").style.display = "block";
			document.getElementById("username").style.display = "block";
			document.getElementById("password").style.display = "block";
		} else {
			setCustomer(false);
			document.getElementById("customer").style.display = "none";
			document.getElementById("permission").style.display = "block";
			document.getElementById("username").style.display = "block";
			document.getElementById("password").style.display = "block";
		}
	};
	const saveUsers = (data) => {
		const formData = new FormData();
		formData.append("username", data.username);
		if (data.user_type === "customer") {
			formData.append("cus_id", data.cus_id.id);
		}
		formData.append("user_type", data.user_type);
		formData.append("permission", data.permission);
		formData.append("password", data.password);

		Swal.fire({
			title: "Please wait...",
			text: "We are saving your data",
			icon: "info",
			showConfirmButton: false,
			allowOutsideClick: false,
		});
		dispatch(
			createUser(formData, (res) => {
				if (res.status === 200) {
					Swal.close();
					Swal.fire({
						title: "Success",
						text: "User has been created",
						icon: "success",
						showConfirmButton: false,
						timer: 1500,
					});

					reset();
				}
			})
		);
	};

	const { id } = useParams();
	const [permission, setPermission] = useState({});
	useEffect(() => {
		if (id) {
			dispatch(
				fetchUserById(id, (res) => {
					if (res.status === 200) {
						var cus_id = "";
						var username = "";
						var permission = [];

						// permissionToArray(res.data.permission);
						var user_type = "";
						if (res.data.account_type === "customer") {
							setCustomer(true);
							document.getElementById("customer").style.display = "block";
							document.getElementById("permission").style.display = "block";
							document.getElementById("username").style.display = "block";
							document.getElementById("password").style.display = "none";
							cus_id = res.data.cus_name.cus_name;
							user_type = "customer";
							permission = res.data.permission;
							permission = permission.split(",");
							username = res.data.email;
						} else {
							setCustomer(false);
							user_type = "employee";
							document.getElementById("customer").style.display = "none";
							document.getElementById("permission").style.display = "block";
							document.getElementById("username").style.display = "block";
							document.getElementById("password").style.display = "none";
							username = res.data.email;
							permission = res.data.permission;
							permission = permission.split(",");
						}

						reset({
							user_type: user_type,
							cus_id: cus_id,
							username: username,
							password: "",
							permission: permission,
						});

						setFormData({
							...formData,
							cus_id: cus_id,
							username: username,
							permission: permission,
						});
					}
				})
			);
		}
	}, [dispatch, id, reset]);

	const setCheckValue = (e) => {
		if (e.target.checked) {
			setFormData({
				...formData,
				permission: [...formData.permission, e.target.value],
			});
		} else {
			setFormData({
				...formData,
				permission: formData.permission.filter(
					(item) => item !== e.target.value
				),
			});
		}
	};

	const editUser = (data) => {
		Swal.fire({
			title: "Please wait...",
			text: "We are saving your data",
			icon: "info",
			showConfirmButton: false,
			allowOutsideClick: false,
		});

		const formData = new FormData();

		formData.append("permission", data.permission);
		formData.append("password", data.password);
		dispatch(
			updateUser(id, formData, (res) => {
				if (res.status === 200) {
					Swal.close();
					Swal.fire({
						title: "Success",
						text: "User has been updated",
						icon: "success",
						showConfirmButton: false,
						timer: 1500,
					});

					reset();

					navigate("/manage-users");
				}
			})
		);
	};

	// if id not exist then reset form
	useEffect(() => {
		if (!id) {
			reset();
		}
	}, [id, reset]);
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Users</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<Link href="#">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-home"
												>
													<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
													<polyline points="9 22 9 12 15 12 15 22" />
												</svg>
											</Link>
										</li>
										<li className="breadcrumb-item">
											{id ? "Edit" : "Create"}
										</li>
										<li className="breadcrumb-item active">Users </li>
									</ol>
								</div>
							</div>
						</div>
					</div>
					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="form theme-form">
											<form
												className="theme-form"
												onSubmit={handleSubmit(id ? editUser : saveUsers)}
											>
												<div className="col-md-12">
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor>User Type</label>
																<select
																	className="form-control"
																	{...register("user_type", { required: true })}
																	onChange={(e) => {
																		setShowCustomer(e);
																	}}
																	disabled={id ? true : false}
																>
																	<option value="">Select User Type</option>
																	<option value="customer">Customer</option>
																	<option value="employee">Employee</option>
																</select>
																{errors.user_type && (
																	<span className="text-danger">
																		This field is required
																	</span>
																)}
															</div>
														</div>
														<div
															className="col-md-6"
															id="username"
															style={{
																display: "none",
															}}
														>
															<div className="form-group">
																<label htmlFor>Username</label>
																<input
																	className="form-control"
																	type="text"
																	{...register("username", {
																		required: false,
																	})}
																	autoComplete="off"
																	disabled={id ? true : false}
																/>
																{errors.username && (
																	<span className="text-danger">
																		This field is required
																	</span>
																)}
															</div>
														</div>

														<div
															className="col-md-6 "
															id="password"
															style={{
																display: "none",
															}}
														>
															<div className="form-group">
																<label htmlFor>Password</label>
																<input
																	className="form-control"
																	type="password"
																	{...register("password", {
																		required: false,
																	})}
																	autoComplete="off"
																/>
																{errors.password && (
																	<span className="text-danger">
																		This field is required
																	</span>
																)}
															</div>
														</div>
														<div
															id="customer"
															style={{
																display: "none",
															}}
															className="col-md-12 row"
														>
															{id ? (
																<div className="col-md-4">
																	<div className="form-group">
																		<label htmlFor>Customer Name</label>
																		<input
																			className="form-control"
																			type="text"
																			{...register("cus_id", {
																				required: false,
																			})}
																			autoComplete="off"
																			disabled={id ? true : false}
																		/>
																	</div>
																</div>
															) : (
																<div className="col-md-4 " id="customer">
																	<div className="form-group">
																		<label htmlFor>Customer Name</label>
																		<Controller
																			name="cus_id"
																			control={control}
																			rules={{ required: false }}
																			render={({ field }) => (
																				<AsyncSelect
																					{...field}
																					isClearable
																					defaultOptions={customerList}
																					loadOptions={customerLoadOptions}
																					getOptionLabel={(option) =>
																						option.cus_name
																					}
																					getOptionValue={(option) =>
																						option.cus_name
																					}
																					placeholder="Select Customer"
																					value={customerList.find(
																						(obj) =>
																							obj.cus_name === field.value
																					)}
																					onChange={(e) => {
																						field.onChange(e);
																						custmerValue(e);
																					}}
																				/>
																			)}
																		/>

																		{errors.cus_id && (
																			<span className="text-danger">
																				This field is required
																			</span>
																		)}
																	</div>
																</div>
															)}
														</div>
														<div
															className="col-md-12"
															id="permission"
															style={{
																display: "none",
															}}
														>
															<table
																className="table table-bordered  border-bottom table-responsive"
																id="user-table"
															>
																<thead>
																	<tr style={{ textAlign: "center" }}>
																		<th
																			className="border-bottom-0"
																			style={{ width: 500 }}
																		>
																			MODULE{" "}
																		</th>
																		<th
																			className="border-bottom-0"
																			style={{ width: 500 }}
																		>
																			CREATE
																		</th>
																		<th
																			className="border-bottom-0"
																			style={{ width: 500 }}
																		>
																			UPDATE
																		</th>
																		<th
																			className="border-bottom-0"
																			style={{ width: 500 }}
																		>
																			VIEW
																		</th>
																		<th
																			className="border-bottom-0"
																			style={{ width: 500 }}
																		>
																			DELETE
																		</th>
																	</tr>
																</thead>
																<tbody style={{ textAlign: "center" }}>
																	<tr
																		style={{
																			display: customer_field
																				? "none"
																				: "table-row",
																		}}
																	>
																		<td> Sale </td>
																		<td>-</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="update_sale"
																				{...register("permission[]", {
																					required: false,
																				})}
																				onChange={(e) => {
																					setCheckValue(e);
																				}}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="view_sale"
																				{...register("permission[]", {
																					required: false,
																				})}
																				onChange={(e) => {
																					setCheckValue(e);
																				}}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="delete_sale"
																				{...register("permission[]", {
																					required: false,
																				})}
																				onChange={(e) => {
																					setCheckValue(e);
																				}}
																			/>
																		</td>
																	</tr>
																	<tr
																		style={{
																			display: customer_field
																				? "none"
																				: "table-row",
																		}}
																	>
																		<td> Customer</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="create_customer"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="update_customer"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="view_customer"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="delete_customer"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																	</tr>

																	{/* <tr
																		style={{
																			display: customer_field
																				? "none"
																				: "table-row",
																		}}
																	>
																		<td> Payment </td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="create_payment"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="update_payment"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="view_payment"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="delete_payment"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																	</tr> */}
																	<tr
																		style={{
																			display: customer_field
																				? "none"
																				: "table-row",
																		}}
																	>
																		<td> Purchase </td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="create_purchase"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="update_purchase"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="view_purchase"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="delete_purchase"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																	</tr>
																	<tr
																		style={{
																			display: customer_field
																				? "none"
																				: "table-row",
																		}}
																	>
																		<td> Tax Invoice </td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="create_tax_invoice"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="update_tax_invoice"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="view_tax_invoice"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="delete_tax_invoice"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																	</tr>
																	<tr
																		style={{
																			display: customer_field
																				? "none"
																				: "table-row",
																		}}
																	>
																		<td>Chart Accounts </td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="create_account"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="update_account"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="view_account"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="delete_account"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																	</tr>
																	<tr
																		style={{
																			display: customer_field
																				? "none"
																				: "table-row",
																		}}
																	>
																		<td> Supplier </td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="create_supplier"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="update_supplier"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="view_supplier"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="delete_supplier"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																	</tr>
																	<tr
																		style={{
																			display: customer_field
																				? "none"
																				: "table-row",
																		}}
																	>
																		<td> Expense </td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="create_expense"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="update_expense"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="view_expense"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="delete_expense"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																	</tr>
																	<tr
																		style={{
																			display: customer_field
																				? "none"
																				: "table-row",
																		}}
																	>
																		<td> Drivers </td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="create_driver"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="update_driver"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="view_driver"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="delete_driver"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																	</tr>
																	<tr>
																		<td> Parameters </td>
																		<td>-</td>
																		<td>-</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="view_parameter"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>-</td>
																	</tr>
																	<tr>
																		<td> Transaction Reports </td>
																		<td>-</td>
																		<td>-</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="view_transaction_report"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>-</td>
																	</tr>

																	<tr
																		style={{
																			display: customer_field
																				? "none"
																				: "table-row",
																		}}
																	>
																		<td> Expense Reports </td>
																		<td>-</td>
																		<td>-</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="view_expense_report"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>-</td>
																	</tr>

																	<tr
																		style={{
																			display: customer_field
																				? "none"
																				: "table-row",
																		}}
																	>
																		<td> Ledger Reports </td>
																		<td>-</td>
																		<td>-</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="view_ledger_report"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>-</td>
																	</tr>
																	{/* <tr
																		style={{
																			display: customer_field
																				? "none"
																				: "table-row",
																		}}
																	>
																		<td> Cash Flow Reports </td>
																		<td>-</td>
																		<td>-</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="view_cash_flow_report"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>-</td>
																	</tr> */}
																	<tr>
																		<td> Payment Reports </td>
																		<td>-</td>
																		<td>-</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="view_payment_report"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>-</td>
																	</tr>

																	<tr>
																		<td> Due Payment Reports </td>
																		<td>-</td>
																		<td>-</td>
																		<td>
																			<input
																				type="checkbox"
																				name="permission[]"
																				id="permission"
																				className="minimal"
																				defaultValue="view_due_payment_report"
																				{...register("permission[]", {
																					required: false,
																				})}
																			/>
																		</td>
																		<td>-</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
												<br></br>
												<div className="col-md-12">
													<div className="mb-3">
														<button type="submit" className="btn btn-primary">
															Submit
														</button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
