import { createSlice } from "@reduxjs/toolkit";

const purchaseSlice = createSlice({
	name: "purchase",
	initialState: {
		purchases: [],
	},
	reducers: {
		getPurchase: (state, action) => {
			state.purchases = action.payload;
		},
	},
});

export const { getPurchase } = purchaseSlice.actions;

export const purchaseSelector = (state) => state.purchase.purchases;

export default purchaseSlice.reducer;
