import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchUserPermission,
	fetchCompanyInfo,
} from "../../../redux/api/apiUser";
import {
	selectUserPermission,
	selectAccountType,
	selectCompanyInfo,
} from "../../../redux/Users";
import { useLocation } from "react-router-dom";
export default function Sidebar() {
	const location = useLocation();
	const dispatch = useDispatch();
	const userPermission = useSelector(selectUserPermission);

	const accountType = useSelector(selectAccountType);

	useEffect(() => {
		dispatch(fetchUserPermission());
	}, [dispatch]);
	const companyInfo = useSelector(selectCompanyInfo);
	React.useEffect(() => {
		dispatch(fetchCompanyInfo((res) => {}));
	}, []);

	const [isSaleActive, setIsSaleActive] = useState(false);
	const [isSaleSubActive, setIsSaleSubActive] = useState(false);

	useEffect(() => {
		if (location.pathname === "/manage-sale") {
			setIsSaleActive(true); // Set "isSaleActive" to true when on the "/manage-sale" page
		} else {
			setIsSaleActive(false); // Set "isSaleActive" to false for other pages
		}
	}, [location.pathname]);

	const handleSaleClick = () => {
		setIsSaleActive(!isSaleActive);
	};

	const handleSaleSubClick = () => {
		setIsSaleSubActive(!isSaleSubActive);
	};
	const [isCustomerActive, setIsCustomerActive] = useState(false);
	useEffect(() => {
		if (location.pathname.startsWith("/add-customer")) {
			setIsCustomerActive(true); // Set "isCustomerActive" to true when on a customer-related page
		}
		if (location.pathname.startsWith("/manage-customer")) {
			setIsCustomerActive(true); // Set "isCustomerActive" to true when on a customer-related page
		}
		if (location.pathname.startsWith("/add-vehicle")) {
			setIsCustomerActive(true); // Set "isCustomerActive" to true when on a customer-related page
		}

		if (location.pathname.startsWith("/manage-vehicle")) {
			setIsCustomerActive(true); // Set "isCustomerActive" to true when on a customer-related page
		}
		if (location.pathname.startsWith("/price-changing")) {
			setIsCustomerActive(true); // Set "isCustomerActive" to true when on a customer-related page
		}
		if (location.pathname.startsWith("/manage-tag")) {
			setIsCustomerActive(true); // Set "isCustomerActive" to true when on a customer-related page
		}
		if (location.pathname.startsWith("/manage-sale")) {
			setIsCustomerActive(true); // Set "isCustomerActive" to true when on a customer-related page
		}
	}, [location.pathname]);
	const handleCustomerClick = () => {
		setIsCustomerActive(!isCustomerActive);
	};

	const [isPurchaseActive, setIsPurchaseActive] = useState(false);

	useEffect(() => {
		if (
			location.pathname === "/add/purchase" ||
			location.pathname === "/purchase"
		) {
			setIsPurchaseActive(true);
		} else {
			setIsPurchaseActive(false);
		}
	}, [location.pathname]);

	const handlePurchaseClick = () => {
		setIsPurchaseActive(!isPurchaseActive);
	};

	const [isTaxInvoiceActive, setIsTaxInvoiceActive] = useState(false);
	useEffect(() => {
		if (
			location.pathname === "/add/taxinvoice" ||
			location.pathname === "/taxinvoice" ||
			location.pathname === "/tax/payments"
		) {
			setIsTaxInvoiceActive(true);
		} else {
			setIsTaxInvoiceActive(false);
		}
	}, [location.pathname]);

	const handleTaxInvoiceClick = () => {
		setIsTaxInvoiceActive(!isTaxInvoiceActive);
	};

	const [isAccountActive, setIsAccountActive] = useState(false);
	useEffect(() => {
		if (
			location.pathname === "/accounts" ||
			location.pathname === "/account-adjustment" ||
			location.pathname === "/account-transfer"
		) {
			setIsAccountActive(true);
		} else {
			setIsAccountActive(false);
		}
	}, [location.pathname]);

	const handleAccountsClick = () => {
		setIsAccountActive(!isAccountActive);
	};
	const handleParameterClick = () => {
		setIsParameterActive(!isParameterActive);
	};
	const [isParameterActive, setIsParameterActive] = useState(false);

	useEffect(() => {
		if (
			location.pathname === "/vehicle/type" ||
			location.pathname === "/vehicle/brand" ||
			location.pathname === "/vehicle/model" ||
			location.pathname === "/vehicle/color"
		) {
			setIsParameterActive(true);
		}
	}, [location.pathname]);

	const [isSupplierActive, setIsSupplierActive] = useState(false);
	useEffect(() => {
		if (location.pathname === "/supplier") {
			setIsSupplierActive(true);
		} else {
			setIsSupplierActive(false);
		}
	}, [location.pathname]);

	const handleSupplierClick = () => {
		setIsSupplierActive(!isSupplierActive);
	};

	const [isExpenseActive, setIsExpenseActive] = useState(false);
	useEffect(() => {
		if (
			location.pathname === "/expense" ||
			location.pathname === "/manage-expense" ||
			location.pathname === "/expense-category"
		) {
			setIsExpenseActive(true);
		} else {
			setIsExpenseActive(false);
		}
	}, [location.pathname]);

	const handleExpenseClick = () => {
		setIsExpenseActive(!isExpenseActive);
	};
	const [isReportsActive, setIsReportsActive] = useState(false);
	useEffect(() => {
		if (
			location.pathname === "/report/transaction" ||
			location.pathname === "/report/expense" ||
			location.pathname === "/report/ledger" ||
			location.pathname === "/report/cash/flow" ||
			location.pathname === "/report/payment" ||
			location.pathname === "/purchase/payments" ||
			location.pathname === "/report/due/payment"
		) {
			setIsReportsActive(true);
		} else {
			setIsReportsActive(false);
		}
	}, [location.pathname]);
	const handleReportsClick = () => {
		setIsReportsActive(!isReportsActive);
	};

	const [isEmployeeActive, setIsEmployeeActive] = useState(false);
	useEffect(() => {
		if (
			location.pathname === "/drivers" ||
			location.pathname === "/manage-department"
		) {
			setIsEmployeeActive(true);
		} else {
			setIsEmployeeActive(false);
		}
	}, [location.pathname]);
	const handleEmployeeClick = () => {
		setIsEmployeeActive(!isEmployeeActive);
	};
	const [isAuthenthicationActive, setAuthenthicationActive] = useState(false);
	useEffect(() => {
		if (
			location.pathname === "/add-users" ||
			location.pathname === "/manage-users"
		) {
			setAuthenthicationActive(true);
		} else {
			setAuthenthicationActive(false);
		}
	}, [location.pathname]);

	const authenthication = () => {
		setAuthenthicationActive(!isAuthenthicationActive);
	};

	const [isSettingsActive, setSettingsActive] = useState(false);
	useEffect(() => {
		if (location.pathname === "/info") {
			setSettingsActive(true);
		} else {
			setSettingsActive(false);
		}
	}, [location.pathname]);
	const settings = () => {
		setSettingsActive(!isSettingsActive);
	};

	const [isSideMoving, setSideMoving] = useState(false);

	const changeSideBar = () => {
		setSideMoving(!isSideMoving);
	};

	// get sessionStorage data
	const isSideMovingSession = sessionStorage.getItem("isSideMoving");

	return (
		<div
			className={
				isSideMoving ? "sidebar-wrapper  close_icon" : "sidebar-wrapper"
			}
		>
			<div className="logo-wrapper">
				<Link to="/dashboard" onClick={changeSideBar}>
					<img
						className="img-fluid_changes for-light"
						src="/1689772513.png"
						alt="logo"
						style={{
							width: "150px",
						}}
					/>
				</Link>
				<div className="back-btn">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width={24}
						height={24}
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						strokeWidth={2}
						strokeLinecap="round"
						strokeLinejoin="round"
						className="feather feather-grid"
						onClick={changeSideBar}
					>
						<rect x={3} y={3} width={7} height={7} />
						<rect x={14} y={3} width={7} height={7} />
						<rect x={14} y={14} width={7} height={7} />
						<rect x={3} y={14} width={7} height={7} />
					</svg>
				</div>
				<div
					className="toggle-sidebar icon-box-sidebar 123"
					// checked="checked"
					onClick={changeSideBar}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width={24}
						height={24}
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						strokeWidth={2}
						strokeLinecap="round"
						strokeLinejoin="round"
						className="feather feather-grid status_toggle middle sidebar-toggle"
					>
						<rect x={3} y={3} width={7} height={7} />
						<rect x={14} y={3} width={7} height={7} />
						<rect x={14} y={14} width={7} height={7} />
						<rect x={3} y={14} width={7} height={7} />
					</svg>
				</div>
			</div>
			<div className="logo-icon-wrapper">
				<Link to="admin/dashboard">
					<div className="icon-box-sidebar">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth={2}
							strokeLinecap="round"
							strokeLinejoin="round"
							className="feather feather-grid"
						>
							<rect x={3} y={3} width={7} height={7} />
							<rect x={14} y={3} width={7} height={7} />
							<rect x={14} y={14} width={7} height={7} />
							<rect x={3} y={14} width={7} height={7} />
						</svg>
					</div>
				</Link>
			</div>
			<nav className="sidebar-main">
				<div className="left-arrow disabled" id="left-arrow">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width={24}
						height={24}
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						strokeWidth={2}
						strokeLinecap="round"
						strokeLinejoin="round"
						className="feather feather-arrow-left"
					>
						<line x1={19} y1={12} x2={5} y2={12} />
						<polyline points="12 19 5 12 12 5" />
					</svg>
				</div>
				<div id="sidebar-menu">
					<ul className="sidebar-links" id="simple-bar" data-simplebar="init">
						<div
							className="simplebar-wrapper"
							style={{ margin: "-24px -20px" }}
						>
							<div className="simplebar-height-auto-observer-wrapper">
								<div className="simplebar-height-auto-observer" />
							</div>
							<div className="simplebar-mask">
								<div
									className="simplebar-offset"
									style={{ right: 0, bottom: 0 }}
								>
									<div
										className="simplebar-content-wrapper"
										style={{ height: "100%", overflow: "hidden scroll" }}
									>
										<div
											className="simplebar-content"
											style={{ padding: "24px 20px" }}
										>
											<li className="back-btn">
												<div className="mobile-back text-end">
													<span>Back</span>
													<i
														className="fa fa-angle-right ps-2"
														aria-hidden="true"
													/>
												</div>
											</li>

											{userPermission.find(
												(item) => item === "view_account"
											) ? (
												<li className="menu-box">
													<ul>
														<li className="sidebar-list">
															<Link
																className="sidebar-link sidebar-"
																to="#"
																onClick={handleAccountsClick}
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width={24}
																	height={24}
																	viewBox="0 0 24 24"
																	fill="none"
																	stroke="currentColor"
																	strokeWidth={2}
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	className="feather feather-layout"
																>
																	<rect
																		x={3}
																		y={3}
																		width={18}
																		height={18}
																		rx={2}
																		ry={2}
																	/>
																	<line x1={3} y1={9} x2={21} y2={9} />
																	<line x1={9} y1={21} x2={9} y2={9} />
																</svg>
																<span>Chart Accounts</span>
																<div className="according-menu">
																	<i className="fa fa-angle-right" />
																</div>
															</Link>
															<ul
																className="sidebar-submenu"
																style={{
																	display: isAccountActive ? "block" : "none",
																}}
															>
																<li>
																	<Link
																		to="/accounts"
																		style={{
																			color:
																				location.pathname === "/accounts"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Accounts Group
																	</Link>
																</li>
															</ul>
														</li>
													</ul>
												</li>
											) : null}
											{userPermission.find(
												(item) => item === "view_parameter"
											) ? (
												<li className="menu-box">
													<ul>
														<li className="sidebar-list">
															<Link
																className="sidebar-link sidebar-"
																to="#"
																onClick={handleParameterClick}
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width={24}
																	height={24}
																	viewBox="0 0 24 24"
																	fill="none"
																	stroke="currentColor"
																	strokeWidth={2}
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	className="feather feather-layout"
																>
																	<rect
																		x={3}
																		y={3}
																		width={18}
																		height={18}
																		rx={2}
																		ry={2}
																	/>
																	<line x1={3} y1={9} x2={21} y2={9} />
																	<line x1={9} y1={21} x2={9} y2={9} />
																</svg>
																<span>Parameter</span>
																<div className="according-menu">
																	<i className="fa fa-angle-right" />
																</div>
															</Link>
															<ul
																className="sidebar-submenu"
																style={{
																	display: isParameterActive ? "block" : "none",
																}}
															>
																<li>
																	<Link
																		to="/vehicle/type"
																		style={{
																			color:
																				location.pathname === "/vehicle/type"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Vehicle Type
																	</Link>
																</li>
																<li>
																	<Link
																		to="/vehicle/brand"
																		style={{
																			color:
																				location.pathname === "/vehicle/brand"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Vehicle Brand
																	</Link>
																</li>
																<li>
																	<Link
																		to="/vehicle/model"
																		style={{
																			color:
																				location.pathname === "/vehicle/model"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Vehicle Model
																	</Link>
																</li>
																<li>
																	<Link
																		to="/vehicle/color"
																		style={{
																			color:
																				location.pathname === "/vehicle/color"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Vehicle Color
																	</Link>
																</li>
															</ul>
														</li>
													</ul>
												</li>
											) : null}

											{/* {userPermission.find(
												(item) => item === "view_supplier"
											) ? (
												<li className="menu-box">
													<ul>
														<li className="sidebar-list">
															<Link
																className="sidebar-link sidebar-"
																to="#"
																onClick={handleSupplierClick}
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width={24}
																	height={24}
																	viewBox="0 0 24 24"
																	fill="none"
																	stroke="currentColor"
																	strokeWidth={2}
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	className="feather feather-users"
																>
																	<path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
																	<circle cx={9} cy={7} r={4} />
																	<path d="M23 21v-2a4 4 0 0 0-3-3.87" />
																	<path d="M16 3.13a4 4 0 0 1 0 7.75" />
																</svg>
																<span>Supplier</span>
																<div className="according-menu">
																	<i className="fa fa-angle-right" />
																</div>
															</Link>
															<ul
																className="sidebar-submenu"
																style={{
																	display: isSupplierActive ? "block" : "none",
																}}
															>
																<li>
																	<Link
																		to="/supplier"
																		style={{
																			color: isSupplierActive
																				? "floralwhite"
																				: "white",
																		}}
																	>
																		<i className="bx bx-right-arrow-alt" />
																		Manage Supplier
																	</Link>
																</li>
															</ul>
														</li>
													</ul>
												</li>
											) : null} */}

											{userPermission.find(
												(item) =>
													item === "view_purchase" || item === "view_supplier"
											) ? (
												<li className="menu-box">
													<ul>
														<li className="sidebar-list">
															<Link
																className="sidebar-link sidebar-"
																to="#"
																onClick={handlePurchaseClick}
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width={24}
																	height={24}
																	viewBox="0 0 24 24"
																	fill="none"
																	stroke="currentColor"
																	strokeWidth={2}
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	className="feather feather-shopping-bag"
																>
																	<path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z" />
																	<line x1={3} y1={6} x2={21} y2={6} />
																	<path d="M16 10a4 4 0 0 1-8 0" />
																</svg>
																<span>Purchase</span>
																<div className="according-menu">
																	<i className="fa fa-angle-right" />
																</div>
															</Link>
															<ul
																className="sidebar-submenu"
																style={{
																	display:
																		isPurchaseActive || isSupplierActive
																			? "block"
																			: "none",
																}}
															>
																<li>
																	<Link
																		to="/supplier"
																		style={{
																			color: isSupplierActive
																				? "floralwhite"
																				: "white",
																		}}
																	>
																		<i className="bx bx-right-arrow-alt" />
																		Manage Supplier
																	</Link>
																</li>
																<li>
																	<Link
																		to="/add/purchase"
																		style={{
																			color:
																				location.pathname === "/add/purchase"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Add Purchase
																	</Link>
																</li>
																<li>
																	<Link
																		to="/purchase"
																		style={{
																			color:
																				location.pathname === "/purchase"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Manage Purchase
																	</Link>
																</li>
															</ul>
														</li>
													</ul>
												</li>
											) : null}

											{/* {userPermission.find((item) => item === "view_sale") ? (
												<li className="menu-box">
													<ul>
														<li className="sidebar-list">
															<Link
																className="sidebar-link sidebar-"
																to="#"
																onClick={handleSaleClick}
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 24 24"
																	fill="none"
																	stroke="currentColor"
																	strokeWidth={2}
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	className="feather feather-shopping-cart"
																>
																	<circle cx={9} cy={21} r={1} />
																	<circle cx={20} cy={21} r={1} />
																	<path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" />
																</svg>
																<span>Sale</span>
																<div className="according-menu">
																	<i className="fa fa-angle-right" />
																</div>
															</Link>
															<ul
																className="sidebar-submenu"
																style={{
																	display: isSaleActive ? "block" : "none",
																}}
															>
																<li>
																	<Link
																		to="/manage-sale"
																		className={
																			isSaleSubActive
																				? "sidebar-submenu active"
																				: "sidebar-submenu"
																		}
																		onClick={handleSaleSubClick}
																	>
																		<span
																			style={{
																				color:
																					location.pathname === "/manage-sale"
																						? "floralwhite"
																						: "white",
																			}}
																		>
																			Manage Sale
																		</span>
																	</Link>
																</li>
															</ul>
														</li>
													</ul>
												</li>
											) : null} */}

											{userPermission.find(
												(item) => item === "view_customer"
											) ? (
												<li class="menu-box">
													<ul>
														<li className="sidebar-list">
															<Link
																className="sidebar-link sidebar-"
																to="#"
																onClick={handleCustomerClick}
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 24 24"
																	fill="none"
																	stroke="currentColor"
																	strokeWidth={2}
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	className="feather feather-users"
																>
																	<path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
																	<circle cx="9" cy="7" r="4"></circle>
																	<path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
																	<path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
																</svg>
																<span>Customer</span>
																<div className="according-menu">
																	<i className="fa fa-angle-right" />
																</div>
															</Link>
															<ul
																className="sidebar-submenu"
																style={{
																	display:
																		isCustomerActive || isSaleSubActive
																			? "block"
																			: "none",
																}}
															>
																<li className="sidebar-list">
																	<Link
																		to="/add-customer"
																		style={{
																			color:
																				location.pathname === "/add-customer"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Add Customers
																	</Link>
																</li>
																<li>
																	<Link
																		to="/manage-customer"
																		style={{
																			color:
																				location.pathname === "/manage-customer"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Manage Customers
																	</Link>
																</li>
																<li>
																	<Link
																		to="/add-vehicle"
																		style={{
																			color:
																				location.pathname === "/add-vehicle"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Add Vehicle
																	</Link>
																</li>
																<li>
																	<Link
																		to="/manage-vehicle"
																		style={{
																			color:
																				location.pathname === "/manage-vehicle"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Manage Vehicle
																	</Link>
																</li>
																<li>
																	<Link
																		to="/price-changing"
																		style={{
																			color:
																				location.pathname === "/price-changing"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Price Changing
																	</Link>
																</li>
																<li>
																	<Link
																		to="/manage-tag"
																		style={{
																			color:
																				location.pathname === "/manage-tag"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Manage Tag
																	</Link>
																</li>
																<li>
																	<Link
																		to="/manage-sale"
																		className={
																			isSaleSubActive
																				? "sidebar-submenu active"
																				: "sidebar-submenu"
																		}
																		onClick={handleSaleSubClick}
																	>
																		<span
																			style={{
																				color:
																					location.pathname === "/manage-sale"
																						? "floralwhite"
																						: "white",
																			}}
																		>
																			Sale Transaction
																		</span>
																	</Link>
																</li>
															</ul>
														</li>
													</ul>
												</li>
											) : null}

											{userPermission.find(
												(item) => item === "view_tax_invoice"
											) ? (
												<li className="menu-box">
													<ul>
														<li className="sidebar-list">
															<Link
																className="sidebar-link sidebar-"
																to="#"
																onClick={handleTaxInvoiceClick}
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width={24}
																	height={24}
																	viewBox="0 0 24 24"
																	fill="none"
																	stroke="currentColor"
																	strokeWidth={2}
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	className="feather feather-plus-square"
																>
																	<rect
																		x={3}
																		y={3}
																		width={18}
																		height={18}
																		rx={2}
																		ry={2}
																	/>
																	<line x1={12} y1={8} x2={12} y2={16} />
																	<line x1={8} y1={12} x2={16} y2={12} />
																</svg>
																<span>Invoice</span>
																<div className="according-menu">
																	<i className="fa fa-angle-right" />
																</div>
															</Link>
															<ul
																className="sidebar-submenu"
																style={{
																	display: isTaxInvoiceActive
																		? "block"
																		: "none",
																}}
															>
																<li>
																	<Link
																		to="/add/taxinvoice"
																		style={{
																			color:
																				location.pathname === "/add/taxinvoice"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Tax Invoice
																	</Link>
																</li>
																<li>
																	<Link
																		to="/taxinvoice"
																		style={{
																			color:
																				location.pathname === "/taxinvoice"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Manage Tax Invoice
																	</Link>
																</li>
																<li>
																	<Link
																		to="/tax/payments"
																		style={{
																			color:
																				location.pathname === "/tax/payments"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Tax Payments
																	</Link>
																</li>
															</ul>
														</li>
													</ul>
												</li>
											) : null}

											{userPermission.find(
												(item) => item === "view_expense"
											) ? (
												<li className="menu-box">
													<ul>
														<li className="sidebar-list">
															<Link
																className="sidebar-link sidebar-"
																to="#"
																onClick={handleExpenseClick}
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width={24}
																	height={24}
																	viewBox="0 0 24 24"
																	fill="none"
																	stroke="currentColor"
																	strokeWidth={2}
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	className="feather feather-edit"
																>
																	<path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34" />
																	<polygon points="18 2 22 6 12 16 8 16 8 12 18 2" />
																</svg>
																<span>Expense</span>
																<div className="according-menu">
																	<i className="fa fa-angle-right" />
																</div>
															</Link>
															<ul
																className="sidebar-submenu"
																style={{
																	display: isExpenseActive ? "block" : "none",
																}}
															>
																<li>
																	<Link
																		to="/expense"
																		style={{
																			color:
																				location.pathname === "/expense"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		<i className="bx bx-right-arrow-alt" />
																		Add Expense
																	</Link>
																</li>
																<li>
																	<Link
																		to="/manage-expense"
																		style={{
																			color:
																				location.pathname === "/manage-expense"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		<i className="bx bx-right-arrow-alt" />
																		Manage Expense
																	</Link>
																</li>
																<li>
																	<Link
																		to="/expense-category"
																		style={{
																			color:
																				location.pathname ===
																				"/expense-category"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		<i className="bx bx-right-arrow-alt" />
																		Expense Category
																	</Link>
																</li>
															</ul>
														</li>
													</ul>
												</li>
											) : null}
											<li className="menu-box">
												<ul>
													<li className="sidebar-list">
														<Link
															className="sidebar-link sidebar-"
															to="#"
															onClick={handleReportsClick}
														>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width={24}
																height={24}
																viewBox="0 0 24 24"
																fill="none"
																stroke="currentColor"
																strokeWidth={2}
																strokeLinecap="round"
																strokeLinejoin="round"
																className="feather feather-bar-chart-2"
															>
																<line x1={18} y1={20} x2={18} y2={10} />
																<line x1={12} y1={20} x2={12} y2={4} />
																<line x1={6} y1={20} x2={6} y2={14} />
															</svg>
															<span>Reports</span>
															<div className="according-menu">
																<i className="fa fa-angle-right" />
															</div>
														</Link>
														<ul
															className="sidebar-submenu"
															style={{
																display: isReportsActive ? "block" : "none",
															}}
														>
															{userPermission.find(
																(item) => item === "view_transaction_report"
															) ? (
																<li>
																	<Link
																		to="/report/transaction"
																		style={{
																			color:
																				location.pathname ===
																				"/report/transaction"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Transaction
																	</Link>
																</li>
															) : null}

															{userPermission.find(
																(item) => item === "view_expense_report"
															) ? (
																<li>
																	<Link
																		to="/report/expense"
																		style={{
																			color:
																				location.pathname === "/report/expense"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Expense
																	</Link>
																</li>
															) : null}

															{userPermission.find(
																(item) => item === "view_ledger_report"
															) ? (
																<li>
																	<Link
																		to="/report/ledger"
																		style={{
																			color:
																				location.pathname === "/report/ledger"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Ledger
																	</Link>
																</li>
															) : null}
															{/* {userPermission.find(
																(item) => item === "view_cash_flow_report"
															) ? (
																<li>
																	<Link
																		to="/report/cash/flow"
																		style={{
																			color:
																				location.pathname ===
																				"/report/cash/flow"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Cash Flow
																	</Link>
																</li>
															) : null} */}
															{userPermission.find(
																(item) => item === "view_payment_report"
															) ? (
																<li>
																	<Link
																		to="/report/payment"
																		style={{
																			color:
																				location.pathname === "/report/payment"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Sale Payment
																	</Link>
																</li>
															) : null}
															{userPermission.find(
																(item) => item === "view_discount_report"
															) ? (
																<li>
																	<Link
																		to="/purchase/payments"
																		style={{
																			color:
																				location.pathname ===
																				"/purchase/payments"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Purchase Payment
																	</Link>
																</li>
															) : null}

															{userPermission.find(
																(item) => item === "view_due_payment_report"
															) ? (
																<li>
																	<Link
																		to="/report/due/payment"
																		style={{
																			color:
																				location.pathname ===
																				"/report/due/payment"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Due Payment
																	</Link>
																</li>
															) : null}
														</ul>
													</li>
												</ul>
											</li>

											{userPermission.find((item) => item === "view_driver") ? (
												<li className="menu-box">
													<ul>
														<li className="sidebar-list">
															<Link
																className="sidebar-link sidebar-"
																to="#"
																onClick={handleEmployeeClick}
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width={24}
																	height={24}
																	viewBox="0 0 24 24"
																	fill="none"
																	stroke="currentColor"
																	strokeWidth={2}
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	className="feather feather-user-plus"
																>
																	<path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
																	<circle cx="8.5" cy={7} r={4} />
																	<line x1={20} y1={8} x2={20} y2={14} />
																	<line x1={23} y1={11} x2={17} y2={11} />
																</svg>
																<span>Drivers</span>
																<div className="according-menu">
																	<i className="fa fa-angle-right" />
																</div>
															</Link>
															<ul
																className="sidebar-submenu"
																style={{
																	display: isEmployeeActive ? "block" : "none",
																}}
															>
																<li>
																	<Link
																		to="/drivers"
																		style={{
																			color:
																				location.pathname === "/drivers"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Drivers
																	</Link>
																</li>
																<li>
																	<Link
																		to="/manage-department"
																		style={{
																			color:
																				location.pathname ===
																				"/manage-department"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Department
																	</Link>
																</li>
															</ul>
														</li>
													</ul>
												</li>
											) : null}

											{accountType === "admin" || accountType === "user" ? (
												<li className="menu-box">
													<ul>
														<li className="sidebar-list">
															<Link
																className="sidebar-link sidebar-"
																to="#"
																onClick={authenthication}
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width={24}
																	height={24}
																	viewBox="0 0 24 24"
																	fill="none"
																	stroke="currentColor"
																	strokeWidth={2}
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	className="feather feather-box"
																>
																	<path d="M12.89 1.45l8 4A2 2 0 0 1 22 7.24v9.53a2 2 0 0 1-1.11 1.79l-8 4a2 2 0 0 1-1.79 0l-8-4a2 2 0 0 1-1.1-1.8V7.24a2 2 0 0 1 1.11-1.79l8-4a2 2 0 0 1 1.78 0z" />
																	<polyline points="2.32 6.16 12 11 21.68 6.16" />
																	<line x1={12} y1="22.76" x2={12} y2={11} />
																</svg>
																<span>Users</span>
																<div className="according-menu">
																	<i className="fa fa-angle-right" />
																</div>
															</Link>
															<ul
																className="sidebar-submenu"
																style={{
																	display: isAuthenthicationActive
																		? "block"
																		: "none",
																}}
															>
																<li>
																	<Link
																		to="/add-users"
																		style={{
																			color:
																				location.pathname === "/add-users"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Add Users
																	</Link>
																</li>
																<li>
																	<Link
																		to="/manage-users"
																		style={{
																			color:
																				location.pathname === "/manage-users"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Manage Users
																	</Link>
																</li>
															</ul>
														</li>
													</ul>
												</li>
											) : null}

											{accountType === "admin" ? (
												<li className="menu-box">
													<ul>
														<li className="sidebar-list">
															<Link
																className="sidebar-link sidebar-"
																to="#"
																onClick={settings}
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width={24}
																	height={24}
																	viewBox="0 0 24 24"
																	fill="none"
																	stroke="currentColor"
																	strokeWidth={2}
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	className="feather feather-settings"
																>
																	<circle cx={12} cy={12} r={3} />
																	<path d="M19.94 12.06a8 8 0 1 1-1.42-1.42M12 6a6 6 0 1 1 0 12 6 6 0 0 1 0-12z" />
																</svg>

																<span>Settings</span>
																<div className="according-menu">
																	<i className="fa fa-angle-right" />
																</div>
															</Link>
															<ul
																className="sidebar-submenu"
																style={{
																	display: isSettingsActive ? "block" : "none",
																}}
															>
																<li>
																	<Link
																		to="/info"
																		style={{
																			color:
																				location.pathname === "/info"
																					? "floralwhite"
																					: "white",
																		}}
																	>
																		Company Info
																	</Link>
																</li>
															</ul>
														</li>
													</ul>
												</li>
											) : null}
										</div>
									</div>
								</div>
							</div>
						</div>
					</ul>
				</div>
			</nav>
		</div>
	);
}
