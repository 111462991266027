import React, { useEffect, useState } from "react";
import Footer from "../dashboard/components/Footer";
import Sidebar from "../dashboard/components/Sidebar";
import Header from "../dashboard/components/Header";
import { getExpenseReportList } from "../../redux/api/apiReports";
import { useDispatch, useSelector } from "react-redux";
import { expenseSelector } from "../../redux/Reports";
import DataTable from "datatables.net-dt";
import html2pdf from "html2pdf.js";
import Swal from "sweetalert2";
export default function Expenses() {
	const [currentDate, setCurrentDate] = useState(new Date());
	let defaultDate = new Date();
	defaultDate.setDate(defaultDate.getDate() + 3);
	// console.log(defaultDate);

	const [filterList, setFilterList] = useState({
		datefrom: "",
		dateto: "",
	});
	const dispatch = useDispatch();
	const [exportToPdf, setExportToPdf] = useState(false);
	const expenseList = useSelector(expenseSelector);
	let table = new DataTable("#expense-table");
	useEffect(() => {
		dispatch(getExpenseReportList());
	}, [dispatch]);

	useEffect(() => {
		table.destroy();
		table = new DataTable("#expense-table", {
			data: expenseList,
			columns: [
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.expense_reason) {
							return data.expense_reason;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.expense_category.cat_name) {
							return data.expense_category.cat_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.amount) {
							return data.amount;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.account.account_name) {
							return data.account.account_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.date) {
							return data.date;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.notes) {
							return data.notes;
						}
						return "";
					},
				},
			],
			lengthMenu: [[-1], ["All"]],
		});
	}, [expenseList]);

	const getExpenseList = () => {
		setExportToPdf(false);
		Swal.fire(
			"Loading...",
			"Please wait while we are fetching the data",
			"info"
		);
		Swal.showLoading();

		dispatch(
			getExpenseReportList(filterList, (res) => {
				if (res) {
					Swal.close();
					setExportToPdf(true);
				}
			})
		);
	};

	const logoos = localStorage.getItem("c_logo");
	var logo = "";
	if (logoos === null) {
		logo = "";
	} else {
		logo = process.env.REACT_APP_IMAGE_URL + logoos.replace(/['"]+/g, "");
	}

	var company_name = localStorage.getItem("c_name");
	if (company_name) {
		company_name = company_name.replace(/['"]+/g, "");
	} else {
		company_name = "";
	}

	var company_address = localStorage.getItem("c_address");
	if (company_address) {
		company_address = company_address.replace(/['"]+/g, "");
	} else {
		company_address = "";
	}
	var company_phone = localStorage.getItem("c_phone");
	if (company_phone) {
		company_phone = company_phone.replace(/['"]+/g, "");
	} else {
		company_phone = "";
	}
	var company_email = localStorage.getItem("c_email");
	if (company_email) {
		company_email = company_email.replace(/['"]+/g, "");
	} else {
		company_email = "";
	}

	const exportPDFWithComponent = async () => {
		try {
			const printArea = document.createElement("div");

			// Add your HTML code above the table
			printArea.innerHTML = `
            <div class="col-lg-12">
                <div class="invoice p-3 mb-3">
                    <div class="row invoice-info">
                        <div class="col-sm-4 invoice-col">
                            <div class="company-logo">
                                <img src="/1689772513.png" alt="logo" style="height:200;width:250" class="lg-logo">
                            </div>
                        </div>
                        <h3 style="text-align:center">Expense Reports </h3>
                    </div>
                    <hr>
                </div>
            </div>
        `;

			// Clone the table
			const originalTable = document.getElementById("expense-table");
			const clonedTable = originalTable.cloneNode(true);

			// Style the table header
			const tableHeader = clonedTable.getElementsByTagName("thead")[0];
			tableHeader.style.backgroundColor = "blue";
			tableHeader.style.color = "white";

			// Calculate the total sum
			let totalSum = 0;
			const rows = clonedTable.getElementsByTagName("tr");
			for (let i = 1; i < rows.length; i++) {
				// Start from 1 to skip the header row
				const cells = rows[i].getElementsByTagName("td");
				if (cells.length >= 4) {
					const cellValue = parseFloat(cells[3].textContent); // Assuming column 4 contains the values to sum
					if (!isNaN(cellValue)) {
						totalSum += cellValue;
					}
				}
			}

			// Create a new row for the total sum
			const totalRow = document.createElement("tr");
			totalRow.style.backgroundColor = "gray";
			totalRow.style.color = "white";
			for (let i = 0; i < 4; i++) {
				const totalCell = document.createElement("td");
				if (i === 3) {
					totalCell.textContent = `Total Amount: ${totalSum.toFixed(2)}`;
				} else {
					totalCell.textContent = "";
				}
				totalRow.appendChild(totalCell);
			}

			// Append the total row to the cloned table
			clonedTable.querySelector("tbody").appendChild(totalRow);

			// Append the cloned table to the printArea
			printArea.appendChild(clonedTable);

			// Define PDF generation options
			const pdfOptions = {
				margin: 1,
				filename: "Expense.pdf",
				image: { type: "jpeg", quality: 0.95 },
				html2canvas: { scale: 2 },
				jsPDF: { unit: "in", format: [20.4, 20], orientation: "landscape" },
			};

			// Generate the PDF and save it
			await html2pdf().set(pdfOptions).from(printArea).save();
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	const exportExcelWithComponent = async () => {
		const table = document.getElementById("expense-table");
		const rows = Array.from(table.getElementsByTagName("tr"));

		// Extract table headers
		const headers = Array.from(rows.shift().getElementsByTagName("th")).map(
			(header) => header.textContent
		);

		// Extract table data
		const data = rows.map((row) => {
			const cells = Array.from(row.getElementsByTagName("td")).map(
				(cell) => cell.textContent
			);
			return cells.join(",");
		});

		let totalSum = 0;
		rows.forEach((row) => {
			const cells = Array.from(row.getElementsByTagName("td"));
			if (cells.length >= 1) {
				const cellValue = parseFloat(cells[3].textContent); // Assuming column 7 is 0-based
				if (!isNaN(cellValue)) {
					totalSum += cellValue;
				}
			}
		});
		const totalRow = ["Total", "", "", "", "", "", `${totalSum.toFixed(2)}`]; // Add the total sum as the 8th column

		// Create report header
		const reportHeader =
			"Report Name: Expense Report\nDate From : " +
			filterList.datefrom +
			"\nDate To : " +
			filterList.dateto +
			"\n\n";

		// Create CSV content with report header
		const csvContent =
			reportHeader +
			`${headers.join(",")}\n${data.join("\n")}\n${totalRow.join(",")}`;

		// Create a download link
		const downloadLink = document.createElement("a");
		const blob = new Blob([csvContent], { type: "text/csv" });
		const url = URL.createObjectURL(blob);
		downloadLink.href = url;
		downloadLink.download = "expense_reports.csv";

		// Trigger the download
		downloadLink.click();

		// Cleanup
		URL.revokeObjectURL(url);
	};

	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3> Expense</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="index.html">
												<i data-feather="home" />
											</a>
										</li>
										<li className="breadcrumb-item">Reports</li>
										<li className="breadcrumb-item active"> Expense</li>
									</ol>
								</div>
							</div>
						</div>
					</div>
					<input
						type="hidden"
						id="logovalue"
						value="http://206.189.81.148/uploads/cm_logo/1688632832.png"
					/>

					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-md-4">
												<div className="form-group">
													<label htmlFor="vehicle">DateFrom</label>
													<input
														type="date"
														className="form-control"
														id="dateFrom"
														onChange={(e) =>
															setFilterList({
																...filterList,
																datefrom: e.target.value,
															})
														}
														defaultValue={
															currentDate.getFullYear() /
															currentDate.getMonth() /
															currentDate.getDate()
														}
													/>
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group">
													<label htmlFor="vehicle">DateTo</label>
													<input
														type="date"
														className="form-control"
														id="dateTo"
														onChange={(e) =>
															setFilterList({
																...filterList,
																dateto: e.target.value,
															})
														}
														defaultValue={
															currentDate.getFullYear() /
															currentDate.getMonth() /
															currentDate.getDate()
														}
													/>
												</div>
											</div>
											<div className="col-md-4"></div>
											<div
												className="col-md-2 mt-4"
												style={{
													display: "inline-grid",
													marginLeft: "10px",
												}}
											>
												<button
													type="button"
													className="btn btn-primary"
													onClick={() => getExpenseList()}
												>
													Search
												</button>
											</div>

											<div
												className="col-md-2 mt-4"
												style={{
													display: exportToPdf ? "inline-grid" : "none",
													marginLeft: "10px",
												}}
											>
												<button
													type="button"
													className="btn btn-success ml-2"
													id="export_to_pdf"
													onClick={exportPDFWithComponent}
												>
													PDF
												</button>
											</div>
											<div
												className="col-md-2 mt-4"
												style={{
													display: exportToPdf ? "inline-grid" : "none",
													marginLeft: "10px",
												}}
											>
												<button
													type="button"
													className="btn btn-info ml-2"
													id="export_to_pdf"
													onClick={exportExcelWithComponent}
												>
													Excel
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table
												className="display"
												id="expense-table"
												style={{
													width: "100%",
													textAlign: "center",
													border: "1px solid #dee2e6",
												}}
											>
												<thead>
													<tr>
														<th>Sl no</th>
														<th>Expense Reason</th>
														<th>Category</th>
														<th>Amount</th>
														<th>Account</th>
														<th>Date</th>
														<th>Notes</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
