import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { getAccount, getAccountBalanceById } from "../../redux/api/apiAccounts";
import { accountsSelector } from "../../redux/Accounts";
import { getAllCustomers } from "../../redux/api/apiCustomer";
import { selectCustomers } from "../../redux/Customers";
import {
	getRemainingBalance,
	saveCustomerPayment,
} from "../../redux/api/apiTaxinvoice";
export default function Customerpayment() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const accounts = useSelector(accountsSelector);
	const customers = useSelector(selectCustomers);
	const { id } = useParams();
	useEffect(() => {
		dispatch(getAccount());
		dispatch(getAllCustomers());
	}, []);
	const [formData, setFormData] = useState({
		account_id: "",
		account_balance: 0,
		cus_id: "",
		payment_date: "",
		payment_array: [],
	});
	const accountBalance = (e) => {
		if (e === null) {
			setFormData({
				...formData,
				account_id: "",
				account_balance: "",
			});
			reset({
				...formData,
				account_id: "",
				account_balance: 0.0,
			});
			return;
		}
		setFormData({
			...formData,
			account_id: e.id,
		});

		dispatch(
			getAccountBalanceById(e.id, (res) => {
				if (res.status === 200) {
					reset({
						...formData,
						account_id: e.id,
						account_balance: res.data.account_balance,
					});
				}
			})
		);
	};

	const [dueBill, setDueBill] = useState([]);
	const checkBalance = () => {
		if (formData.cus_id === "" || formData.cus_id === undefined) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please select a customer",
			});
			return false;
		}

		setDueBill([]);
		dispatch(
			getRemainingBalance(formData.cus_id, (res) => {
				setDueBill(res);
			})
		);
	};

	const addCusPayment = (data) => {
		// console.log(data);
		const formDatas = new FormData();
		formDatas.append("cus_id", data.cus_id);
		formDatas.append("account_id", data.account_id);
		formDatas.append("payment_date", data.payment_date);
		formDatas.append("notes", data.notes);
		formDatas.append("receipt_no", data.receipt_no);
		formDatas.append("cheque_no", data.cheque_no);
		formDatas.append("payment_array", JSON.stringify(data.paid_amount));
		var payment = [];
		var invoiceid = [];
		for (let i = 0; i < data.paid_amount.length; i++) {
			payment = data.paid_amount[i];
		}
		formDatas.append("payment_amount[]", payment);
		for (let i = 0; i < data.pay_id.length; i++) {
			invoiceid = data.pay_id[i];
		}
		formDatas.append("invoice_id[]", invoiceid);
		if (data.cus_id === "" || data.cus_id === undefined) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please select a customer",
			});
			return false;
		}
		if (data.account_id === "" || data.account_id === undefined) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please select an account",
			});
			return false;
		}
		Swal.fire({
			title: "Please wait...",
			text: "We are saving your data",
			icon: "info",
			showConfirmButton: false,
			allowOutsideClick: false,
		});
		dispatch(
			saveCustomerPayment(formDatas, (res) => {
				if (res.status === 200) {
					Swal.fire({
						title: "Done!",
						text: "Customer Payment Saved",
						icon: "success",
						timer: 2000,
						showConfirmButton: false,
					});
					navigate("/customerpayment");
				}
			})
		);
	};
	const updateCusPayment = (e) => {};
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>
										{id ? "Edit Customer Payment" : "Add Customer Payment"}
									</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<Link to="#">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-home"
												>
													<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
													<polyline points="9 22 9 12 15 12 15 22" />
												</svg>
											</Link>
										</li>
										<li className="breadcrumb-item">Customer</li>
										<li className="breadcrumb-item active">
											{id ? "Edit  Payment" : "Add  Payment"}
										</li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<form
											onSubmit={handleSubmit(
												id ? updateCusPayment : addCusPayment
											)}
										>
											<div className="form theme-form">
												<div className="col-md-12">
													<div className="row">
														<div className="col-md-12">
															<label>Cutsomer </label>
															<Controller
																name="cus_id"
																control={control}
																rules={{ required: false }}
																render={({ field }) => (
																	<AsyncSelect
																		{...field}
																		isClearable
																		defaultOptions={customers}
																		loadOptions={(inputValue) =>
																			new Promise((resolve) => {
																				setTimeout(() => {
																					resolve(
																						customers.filter((i) =>
																							i.cus_name
																								.toLowerCase()
																								.includes(
																									inputValue.toLowerCase()
																								)
																						)
																					);
																				}, 1000);
																			})
																		}
																		getOptionLabel={(option) => option.cus_name}
																		getOptionValue={(option) => option.id}
																		placeholder="Select Customer"
																		value={customers.find(
																			(obj) => obj.id === field.value
																		)}
																		onChange={(e) => {
																			field.onChange(e);

																			setFormData({
																				...formData,
																				cus_id: e.id,
																			});
																		}}
																	/>
																)}
															/>
															{errors.account_id && (
																<span className="text-danger">
																	This field is required
																</span>
															)}
														</div>

														<div className="col-md-12 ">
															<div className="form-group">
																<h4 className="headstyle">Due Payment Bills</h4>
																<button
																	type="button"
																	className="btn btn-primary"
																	onClick={() => {
																		checkBalance();
																	}}
																>
																	Fetch Due Invoice
																</button>
															</div>
														</div>
														<div className="col-md-12 ">
															<div className="d-flex align-items-center justify-content-center">
																<div className="table-responsive">
																	<table className="table align-middle mb-0">
																		<thead className="table-light">
																			<tr>
																				<th>Invoice No</th>
																				<th>Invoice Total</th>
																				<th>Invoice Due</th>
																				<th>Paid Amount</th>
																			</tr>
																		</thead>
																		<tbody>
																			{dueBill.map((info) => (
																				<tr>
																					<td>
																						<input
																							type="text"
																							className="form-control"
																							defaultValue={0}
																							readOnly
																							value={info.invoice_no}
																						/>
																					</td>
																					<td>
																						<input
																							type="text"
																							className="form-control"
																							defaultValue={0}
																							readOnly
																							value={info.total}
																						/>
																					</td>
																					<td>
																						<input
																							type="text"
																							className="form-control"
																							defaultValue={0}
																							readOnly
																						/>
																					</td>
																					<td>
																						<input
																							type="text"
																							className="form-control"
																							defaultValue={0}
																							// onChange={(e) => {
																							// 	setFormData({
																							// 		paid_amount: e.target.value,
																							// 	});
																							// }}

																							{...register(`paid_amount[]`, {
																								required: false,
																							})}
																						/>
																						<input
																							type="hidden"
																							name="invoice_id"
																							value={info.id}
																							{...register(
																								`pay_id[${info.id}]`,
																								{
																									required: false,
																								}
																							)}
																						/>
																					</td>
																				</tr>
																			))}
																		</tbody>
																	</table>
																</div>
															</div>
														</div>

														<div className="col-md-4">
															<label>Accounts </label>
															<Controller
																name="account_id"
																control={control}
																rules={{ required: false }}
																render={({ field }) => (
																	<AsyncSelect
																		{...field}
																		isClearable
																		defaultOptions={accounts}
																		loadOptions={(inputValue) =>
																			new Promise((resolve) => {
																				setTimeout(() => {
																					resolve(
																						accounts.filter((i) =>
																							i.account_name
																								.toLowerCase()
																								.includes(
																									inputValue.toLowerCase()
																								)
																						)
																					);
																				}, 1000);
																			})
																		}
																		getOptionLabel={(option) =>
																			option.account_name
																		}
																		getOptionValue={(option) => option.id}
																		placeholder="Select Accounts"
																		value={accounts.find(
																			(obj) => obj.id === field.value
																		)}
																		onChange={(e) => {
																			field.onChange(e);
																			accountBalance(e);
																		}}
																	/>
																)}
															/>
															{errors.account_id && (
																<span className="text-danger">
																	This field is required
																</span>
															)}
														</div>
														<div className="col-md-4">
															<label>Available Balance</label>
															<input
																type="text"
																className="form-control"
																name="available-balance"
																id="available-balance"
																defaultValue={0}
																readOnly
																{...register("account_balance", {
																	required: false,
																})}
															/>
														</div>
														<div className="col-md-4 ">
															<label className="form-label">Cheque No</label>
															<input
																type="text"
																className="form-control"
																{...register("cheque_no", {
																	required: false,
																})}
															/>
														</div>
														<div className="col-md-3 ">
															<label className="form-label">Receipt No </label>
															<input
																type="text"
																className="form-control"
																{...register("receipt_no", {
																	required: false,
																})}
															/>
														</div>
														<div className="col-md-3 ">
															<label className="form-label">Payment Date</label>
															<input
																type="date"
																className="form-control"
																required
																{...register("payment_date", {
																	required: true,
																})}
															/>
															{errors.payment_date && (
																<span className="text-danger">
																	This field is required
																</span>
															)}
														</div>

														<div className="col-md-6 ">
															<label className="form-label">Notes</label>
															<textarea
																className="form-control"
																name="notes"
																id="notes"
																placeholder="Enter Notes"
																{...register("notes", {
																	required: false,
																})}
															/>
														</div>
													</div>
												</div>
												<div className="col-md-12">
													<div className="mb-3">
														<button type="submit" className="btn btn-primary">
															Submit
														</button>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
