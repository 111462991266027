import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import DataTable from "datatables.net-dt";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchUsers, deleteUser } from "../../redux/api/apiUser";
import { selectUser } from "../../redux/Users";

export default function Manageusers() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const usersList = useSelector(selectUser);
	useEffect(() => {
		dispatch(fetchUsers());
	}, [dispatch]);
	let table = new DataTable("#users-table");
	useEffect(() => {
		table.destroy();
		table = new DataTable("#users-table", {
			data: usersList,
			columns: [
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},
				{
					data: null,
					render: function (data, type, row) {
						if (data.email) {
							return data.email;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type, row) {
						if (data.account_type === "user") {
							return "Employee";
						}
						return "Customer";
					},
				},
				{
					data: "id",
					render: function (data, type, row) {
						return `
	            <div class="btn-group">
	            <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
	              Action
	            </button>
	            <ul class="dropdown-menu">
				  <li><a class="dropdown-item" onclick="editUser(${data})" href="#">Edit</a></li>
				  <li><a class="dropdown-item" href="#" onclick="deleteUser(${data})">Delete</a></li>

				  				</ul>

											</div>
											`;
					},
				},
			],
		});
	}, [usersList]);

	window.deleteUser = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(
					deleteUser(id, (res) => {
						if (res.status === 200) {
							Swal.fire("Deleted!", "User has been deleted.", "success");
							dispatch(fetchUsers());
						}
					})
				);
			} else {
				Swal.fire("Cancelled", "User is safe :)", "error");
			}
		});
	};

	window.editUser = (id) => {
		navigate(`/edit-users/${id}`);
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Manage Users</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a
												href="index.html"
												data-bs-original-title="data-bs-original-title"
												title="title"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-home"
												>
													<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
													<polyline points="9 22 9 12 15 12 15 22" />
												</svg>
											</a>
										</li>
										<li className="breadcrumb-item">Users</li>
										<li className="breadcrumb-item active">Manage Users</li>
									</ol>
								</div>
							</div>
						</div>
					</div>
					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table className="display" id="users-table">
												<thead>
													<tr>
														<th class="border-bottom-0">Sl.no</th>
														<th class="border-bottom-0">Users</th>
														<th class="border-bottom-0">Type</th>

														<th class="border-bottom-0">Action</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
