import axios from "axios";

import Swal from "sweetalert2";
import {
	getTaxInvoice,
	getTaxInvoiceTransaction,
	getTaxInvoiceByResult,
} from "../../redux/TaxInvoice";
const apiTaxinvoice = axios.create({
	baseURL: process.env.REACT_APP_BASEURL + "taxinvoice",
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",

		"Access-Control-Allow-Origin": "*",
		"X-Server-ID": process.env.REACT_APP_SERVER_ID,
	},
});

apiTaxinvoice.interceptors.request.use((config) => {
	const token = localStorage.getItem("token").replace(/['"]+/g, "");
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	return config;
});

// insert taxinvoice
export const insertTaxinvoice = (payload, callback) =>
	apiTaxinvoice
		.post(`/insert`, payload)
		.then((res) => {
			console.log(res);
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			} else {
				callback(res.data);
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

// get taxinvoice
export const getTaxinvoiceList = (payload, callback) => (dispatch) =>
	apiTaxinvoice.get("/fetch", payload).then((res) => {
		// console.log(res);
		if (res.status === 200) {
			if (callback) {
				callback(res.data.data);
			}

			dispatch(getTaxInvoice(res.data.data));
		}
	});

// get taxinvoice by id
export const getTaxinvoiceById = (id, callback) =>
	apiTaxinvoice.get(`/fetch/${id}`).then((res) => {
		if (res.data.status === 200) {
			// console.log(res.data.data);
			if (callback) {
				callback(res.data);
			}
		}
	});

// update taxinvoice
export const updateTaxinvoice = (id, payload, callback) =>
	apiTaxinvoice
		.post(`/update/${id}`, payload)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

// delete taxinvoice
export const deleteTaxinvoice = (id, callback) =>
	apiTaxinvoice.delete(`/delete/${id}`).then((res) => {
		if (res.data.status === 200) {
			if (callback) {
				callback(res.data);
			}
		}
	});

// transaction fetch
export const getTransaction = (payload, callback) => (dispatch) =>
	apiTaxinvoice.post("/transaction", payload).then((res) => {
		// console.log(res);
		if (res.status === 200) {
			if (callback) {
				callback(res.data);
			}

			dispatch(getTaxInvoiceTransaction(res.data.data));
		}
	});

// remainining balance by id
export const getRemainingBalance = (id, callback) =>
	apiTaxinvoice.get(`/remaining/balance/${id}`).then((res) => {
		if (res.data.status === 200) {
			if (callback) {
				callback(res.data.data);
			}
		}
	});

// save customer payment
export const saveCustomerPayment = (payload, callback) => (dispatch) =>
	apiTaxinvoice
		.post(`/save/customer/payment`, payload)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			} else {
				callback(res.data);
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});
export const getPaymentInfoById = (id, callback) => (dispatch) =>
	apiTaxinvoice.get(`/payment/info/by/${id}`).then((res) => {
		if (callback) {
			callback(res.data.data);
		}

		dispatch(getTaxInvoiceByResult(res.data));
	});
