import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
	return (
		<footer className="footer">
			{/* <div className="container-fluid">
				<div className="row">
					<div className="col-md-6 p-0 footer-left">
						<p className="mb-0">
							Copyright © Brother Gas. All rights reserved.
						</p>
					</div>
					<div className="col-md-6 p-0 footer-right">
						<p className="mb-0">
							Design &amp; Developed by
							<i className="fa fa-heart font-danger" />
							<Link to="#">Brother Gas</Link>
						</p>
					</div>
				</div>
			</div> */}
		</footer>
	);
}
