import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import { selectDriver } from "../../redux/Driver";
import { getAllDriver, getDriverById } from "../../redux/api/apiDriver";
import {
	selectCustomerByVehicle,
	selectCustomers,
} from "../../redux/Customers";
import {
	getAllCustomers,
	getVehicleByCustomerId,
} from "../../redux/api/apiCustomer";
import {
	getSaleById,
	getTagsByVehicleId,
	updateSaleById,
} from "../../redux/api/apiSale";
import AsyncSelect from "react-select/async";
import { tagsSelector } from "../../redux/Sale";
export default function AddSale() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const customerList = useSelector(selectCustomers);
	const driverList = useSelector(selectDriver);
	const vehicleData = useSelector(selectCustomerByVehicle);
	const tags = useSelector(tagsSelector);
	const customerLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				customerList.filter((i) =>
					i.cus_name.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};
	const vehicleLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				vehicleData.filter((i) =>
					i.vehicle_name.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};
	const vehicleValue = (e) => {
		if (e === null || e === undefined || e.id === null || e.id === undefined) {
			setFormData({
				...formData,
				vehicle_id: "",
			});
			return;
		}

		setFormData({
			...formData,
			vehicle_id: e.id,
		});
	};
	const tagValue = (e) => {
		if (e === null || e === undefined || e.id === null || e.id === undefined) {
			setFormData({
				...formData,
				vehicle_code: "",
			});
			return;
		}

		setFormData({
			...formData,
			vehicle_code: e.id,
		});
	};

	const tagLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(tags.filter((i) => i.identification_code.includes(inputValue)));
		}, 1000);
	};

	useEffect(() => {
		dispatch(getAllCustomers());
		dispatch(getAllDriver());
	}, [dispatch]);

	const { id } = useParams();
	// console.log(id);
	const [formData, setFormData] = useState({
		transaction_date: "",
		cus_id: "",
		vehicle_id: "",
		vehicle_code: "",
		driver_id: "",
		quantity: "",
		odometer_value: "",
		payment_type: "",
	});

	useEffect(() => {
		dispatch(
			getSaleById(id, (res) => {
				dispatch(getVehicleByCustomerId(res.data.cus_id));
				dispatch(getTagsByVehicleId(res.data.vehicle_id));
				// console.log(res.data.vehicle_id);
				reset({
					cus_id: res.data.cus_id,
					transaction_date: res.data.transaction_date,
					vehicle_id: res.data.vehicle_id,
					vehicle_code: res.data.vehicle_code,
					driver_id: res.data.driver_id,
					quantity: res.data.quantity,
					odometer_value: res.data.odometer_value,
					payment_type: res.data.payment_type,
				});
			})
		);
	}, [dispatch, id, reset]);
	const updateSale = (data) => {
		Swal.fire("Loading...", "Please wait while we are updating sale", "info");
		Swal.showLoading();
		dispatch(
			updateSaleById(
				id,
				data,
				(res) => {
					if (res.status === 200) {
						Swal.close();
						Swal.fire({
							title: "Success!",
							text: "Sale Updated Successfully",
							icon: "success",
							confirmButtonText: "Ok",
						}).then((result) => {
							if (result.isConfirmed) {
								navigate("/manage-sale");
							}
						});
					}
				},
				(err) => {
					Swal.fire({
						title: "Error!",
						text: "Sale Not Updated",
						icon: "error",
						confirmButtonText: "Ok",
					});
				}
			)
		);
	};
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3> {id ? "Edit Sale" : "Add Sale"}</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<Link to="#">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-home"
												>
													<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
													<polyline points="9 22 9 12 15 12 15 22" />
												</svg>
											</Link>
										</li>
										<li className="breadcrumb-item">Sale</li>
										<li className="breadcrumb-item active">
											{id ? "Edit Sale" : "Add Sale"}
										</li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<form onSubmit={handleSubmit(id ? updateSale : "")}>
											<div className="form theme-form">
												<div className="col-md-12">
													<div className="row">
														<div className="col-md-4 myform">
															<label className>Transaction Date *</label>
															<input
																type="date"
																className="form-control"
																name="transaction_date"
																onChange={(e) => {
																	setFormData({
																		...formData,
																		transaction_date: e.target.value,
																	});
																}}
																{...register("transaction_date", {
																	required: true,
																})}
															/>
															{errors.transaction_date && (
																<span className="text-danger">
																	This field is required
																</span>
															)}
														</div>
														<div className="col-md-4 myform mt-2">
															<div className="form-group">
																<label htmlFor="department_id">Client *</label>
																<Controller
																	name="cus_id"
																	control={control}
																	rules={{ required: true }}
																	render={({ field }) => (
																		<AsyncSelect
																			{...field}
																			isClearable
																			defaultOptions={customerList}
																			loadOptions={customerLoadOptions}
																			getOptionLabel={(option) =>
																				option.cus_name
																			}
																			getOptionValue={(option) => option.id}
																			placeholder="Select Customer"
																			value={customerList.find(
																				(obj) => obj.id === field.value
																			)}
																			onChange={(e) => {
																				field.onChange(e);
																				setFormData({
																					...formData,
																					cus_id: e.id,
																				});
																				dispatch(getVehicleByCustomerId(e.id));
																				dispatch(getTagsByVehicleId(0));
																			}}
																		/>
																	)}
																/>
															</div>
														</div>
														<div className="col-md-4 myform mt-2">
															<div className="form-group">
																<label htmlFor="department_id">Vehicle *</label>
																<Controller
																	name="vehicle_id"
																	control={control}
																	rules={{ required: true }}
																	render={({ field }) => (
																		<AsyncSelect
																			{...field}
																			isClearable
																			defaultOptions={vehicleData}
																			loadOptions={vehicleLoadOptions}
																			getOptionLabel={(option) =>
																				option.vehicle_name
																			}
																			getOptionValue={(option) => option.id}
																			placeholder="Select Vehicle"
																			value={vehicleData.find(
																				(obj) => obj.id === field.value
																			)}
																			onChange={(e) => {
																				field.onChange(e);
																				vehicleValue(e);
																				dispatch(getTagsByVehicleId(e.id));
																			}}
																		/>
																	)}
																/>
															</div>
														</div>
														<div className="col-md-4 myform mt-2">
															<div className="form-group">
																<label htmlFor="department_id">Tags *</label>
																<Controller
																	name="vehicle_code"
																	control={control}
																	rules={{ required: true }}
																	render={({ field }) => (
																		<AsyncSelect
																			{...field}
																			isClearable
																			defaultOptions={tags}
																			loadOptions={tagLoadOptions}
																			getOptionLabel={(option) =>
																				option.identification_code
																			}
																			getOptionValue={(option) =>
																				option.identification_code
																			}
																			placeholder="Select Tag"
																			value={tags.find(
																				(obj) =>
																					obj.identification_code ===
																					field.value
																			)}
																			onChange={(e) => {
																				field.onChange(e);
																				tagValue(e);
																			}}
																		/>
																	)}
																/>
															</div>
														</div>
														<div className="col-md-4 myform mt-2">
															<div className="form-group">
																<label htmlFor="department_id">
																	Driver Code
																</label>
																<input
																	type="text"
																	className="form-control"
																	name="driver_id"
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			driver_id: e.target.value,
																		});
																	}}
																	{...register("driver_id", {
																		required: false,
																	})}
																/>
															</div>
														</div>

														<div className="col-md-4 myform">
															<label className>Quantity *</label>
															<input
																type="number"
																className="form-control"
																placeholder="Enter a Quantity  "
																onChange={(e) => {
																	setFormData({
																		...formData,
																		quantity: e.target.value,
																	});
																}}
																{...register("quantity", {
																	required: true,
																})}
																step="any"
															/>
															{errors.quantity && (
																<span className="text-danger">
																	This field is required
																</span>
															)}
														</div>
														<div className="col-md-6 myform">
															<label className>Odometer </label>
															<input
																type="number"
																className="form-control"
																onChange={(e) => {
																	setFormData({
																		...formData,
																		odometer_value: e.target.value,
																	});
																}}
																{...register("odometer_value", {
																	required: false,
																})}
																step="any"
															/>
														</div>

														<div className="col-md-6 myform ">
															<div className="form-group">
																<label htmlFor="payment_mode">
																	Payment Mode
																</label>
																<select
																	className="form-control  w-100"
																	name="payment_type"
																	id="payment_type"
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			payment_type: e.target.value,
																		});
																	}}
																	{...register("payment_type", {
																		required: true,
																	})}
																>
																	<option value="cash">Cash</option>
																	<option value="credit">Credit</option>
																</select>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12">
													<div className="mb-3">
														<button type="submit" className="btn btn-primary">
															Submit
														</button>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
