import React from "react";
import Footer from "../dashboard/components/Footer";
import { useDispatch } from "react-redux";
import Header from "../dashboard/components/Header";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Sidebar from "../dashboard/components/Sidebar";
import Swal from "sweetalert2";
import { updateChangePassword } from "../../redux/api/apiUser";

export default function Cahngepass() {
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();

	const savePass = (data) => {
		// check password match
		if (data.newPassword !== data.confirmPassword) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Password does not match",
			});
			return;
		}

		dispatch(
			updateChangePassword(data, (callback) => {
				if (callback.status === 200) {
					reset();
					Swal.fire({
						icon: "success",
						title: "Success",
						text: "Password updated successfully",
					});
				}
			})
		);
	};

	// console.log(companyInfo);
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Account</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<Link to="#">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-home"
												>
													<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
													<polyline points="9 22 9 12 15 12 15 22" />
												</svg>
											</Link>
										</li>
										<li className="breadcrumb-item">Account</li>
										<li className="breadcrumb-item active">Change Password</li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid project-create">
						<div className="row">
							<form
								id="addCustomerForm"
								className="theme-form"
								onSubmit={handleSubmit(savePass)}
							>
								<div className="col-sm-12">
									<div className="card">
										<div className="card-body">
											<div className="form theme-form">
												<div className="col-md-12">
													<div className="row">
														<div className="col-md-6 ">
															<label className="form-label">
																New Password{" "}
															</label>
															<input
																type="text"
																className="form-control"
																placeholder="New Password"
																{...register("newPassword", { required: true })}
															/>

															{errors.newPassword && (
																<span className="text-danger">
																	This field is required
																</span>
															)}
														</div>

														<div className="col-md-6 ">
															<label className="form-label">
																Confirm Password{" "}
															</label>
															<input
																type="text"
																className="form-control"
																placeholder="Confirm Password"
																{...register("confirmPassword", {
																	required: true,
																})}
															/>
															{errors.confirmPassword && (
																<span className="text-danger">
																	This field is required
																</span>
															)}
														</div>
													</div>
												</div>

												<div className="col-md-12">
													<div className="mb-3">
														<button type="submit" className="btn btn-primary">
															Submit
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
