import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import DataTable from "datatables.net-dt";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	createDriverDepartment,
	getDriverCategoryList,
	deleteDriverCategory,
	getDriverCategoryById,
	updateDriverCategory,
} from "../../redux/api/apiDriver";
import { selectDriverCategory } from "../../redux/Driver";
import Modal from "react-modal";

import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

export default function Department() {
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();

	const [modalIsOpen, setIsOpen] = useState(false);
	const [editID, setEditID] = useState("");
	const department = useSelector(selectDriverCategory);

	useEffect(() => {
		dispatch(getDriverCategoryList());
	}, [dispatch]);

	function openModal() {
		setIsOpen(true);
	}
	function closeModal() {
		setIsOpen(false);
	}

	let table = new DataTable("#department-table");

	useEffect(() => {
		table.destroy();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		table = new DataTable("#department-table", {
			data: department,
			columns: [
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},

				{
					data: null,
					render: function (data, type, row) {
						if (data.name) {
							return data.name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type, row) {
						if (data.description) {
							return data.description;
						}
						return "";
					},
				},

				{
					data: "id",
					render: function (data, type, row) {
						return `
                <div class="btn-group">
                <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  Action
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#" onClick="editCategory(${data})">Edit</a></li>
                  <li><a class="dropdown-item" href="#" onClick="deleteCategory(${data})">Delete</a></li>
                </ul>
              </div>


                `;
					},
				},
			],
		});
	}, [department, table]);

	const addDepartment = (data) => {
		closeModal();
		reset();

		Swal.fire({
			title: "Please wait...",
			html: "Adding Department",
			allowOutsideClick: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		dispatch(
			createDriverDepartment(data, (res) => {
				if (res.status === 200) {
					Swal.close();
					dispatch(getDriverCategoryList());
					Swal.fire({
						title: "Success!",
						text: "Department Added Successfully",
						icon: "success",
						confirmButtonText: "Ok",
					});
				}
			})
		);
	};

	const updateDepartment = (data) => {
		closeModal();
		reset();

		Swal.fire({
			title: "Please wait...",
			html: "Updating Department",
			allowOutsideClick: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		dispatch(
			updateDriverCategory(editID, data, (res) => {
				if (res.status === 200) {
					Swal.close();
					dispatch(getDriverCategoryList());
					Swal.fire({
						title: "Success!",
						text: "Department Updated Successfully",
						icon: "success",
						confirmButtonText: "Ok",
					});
				}
			})
		);
	};

	window.editCategory = (id) => {
		setEditID(id);
		openModal();
		dispatch(
			getDriverCategoryById(id, (res) => {
				if (res.status === 200) {
					reset({
						name: res.data.name,
						description: res.data.description,
					});
				}
			})
		);
	};

	window.deleteCategory = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You will not be able to recover this imaginary file!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, delete it!",
			cancelButtonText: "No, keep it",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(
					deleteDriverCategory(id, (res) => {
						if (res.status === 200) {
							dispatch(getDriverCategoryList());
							Swal.fire({
								title: "Success!",
								text: "Department Deleted Successfully",
								icon: "success",
								confirmButtonText: "Ok",
							});
						}
					})
				);
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire("Cancelled", "Your imaginary file is safe :)", "error");
			}
		});
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Manage Department</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="index.html">
												<i data-feather="home" />
											</a>
										</li>
										<li className="breadcrumb-item">Department</li>
										<li className="breadcrumb-item active">
											Manage Department
										</li>
									</ol>
								</div>
							</div>
						</div>
					</div>
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						contentLabel="Example Modal"
						className="modal-dialog modal-dialog-centered"
						closeTimeoutMS={500}
						style={{
							//  width 1200px
							overlay: {
								backgroundColor: "rgba(0, 0, 0, 0.5)",
								zIndex: 9999,
							},
						}}
					>
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">
									{editID ? "Edit Department" : "Add Department"}
								</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
									onClick={closeModal}
								/>
							</div>
							<form
								onSubmit={handleSubmit(
									editID ? updateDepartment : addDepartment
								)}
							>
								<div className="modal-body">
									<div className="col-md-12 row">
										<div className="col-md-12">
											<div className="form-group">
												<label htmlFor="department_id">Department Name*</label>
												<input
													type="text"
													className="form-control"
													id="name"
													placeholder="Enter Department Name"
													{...register("name", {
														required: true,
													})}
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-group">
												<label htmlFor="description">Description</label>
												<textarea
													className="form-control"
													id="description"
													rows="3"
													{...register("description")}
												></textarea>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-secondary"
										data-bs-dismiss="modal"
										onClick={closeModal}
									>
										Close
									</button>
									<button type="submit" className="btn btn-primary">
										Save changes
									</button>
								</div>
							</form>
						</div>
					</Modal>

					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<button
											class="btn btn-primary"
											type="button"
											onClick={openModal}
										>
											Add Department
										</button>

										<div className="table-responsive">
											<table
												className="display"
												id="department-table"
												style={{
													width: "100%",
													textAlign: "center",
													border: "1px solid #dee2e6",
												}}
											>
												<thead>
													<tr>
														<th>Sl no</th>

														<th>Department </th>
														<th>Description</th>

														<th>Action</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
