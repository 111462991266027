import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import DataTable from "datatables.net-dt";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import AsyncSelect from "react-select/async";
import Swal from "sweetalert2";
import {
	getTaxinvoiceList,
	deleteTaxinvoice,
	getRemainingBalance,
	saveCustomerPayment,
} from "../../redux/api/apiTaxinvoice";
import { selectTaxInvoice } from "../../redux/TaxInvoice";
import { useForm, Controller } from "react-hook-form";
import { getAccount, getAccountBalanceById } from "../../redux/api/apiAccounts";
import { accountsSelector } from "../../redux/Accounts";
export default function Managetaxinvoice() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const taxinvoiceList = useSelector(selectTaxInvoice);
	useEffect(() => {
		dispatch(getTaxinvoiceList());
	}, [dispatch]);
	let table = new DataTable("#tax-invoice-table");
	useEffect(() => {
		table.destroy();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		table = new DataTable("#tax-invoice-table", {
			data: taxinvoiceList,
			columns: [
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.invoice_no) {
							return data.invoice_no;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.customer.cus_name) {
							return data.customer.cus_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.created_date) {
							return data.created_date;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.quantity) {
							return data.quantity;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						return "5%";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.tax_amount) {
							return data.tax_amount;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.total) {
							return data.total;
						}
						return 0;
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.paid) {
							return data.paid.total;
						}
						return 0;
					},
				},
				{
					data: null,
					render: function (data, type) {
						var paid = 0;
						var total = 0;
						var remaining = 0;
						if (data.paid && data.total) {
							remaining = data.total - data.paid.total;
							return remaining;
						}
						return remaining;
					},
				},

				{
					data: "id",
					render: function (data, type, row) {
						return `
				<div class="btn-group">
				<button type="button" class="btn btn-success " onClick="Print(${data})">
				  Print
				</button>
			
							</div>
									`;
					},
				},

				{
					data: "id",
					render: function (data, type, row) {
						return `
                <div class="btn-group">
                <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  Action
                </button>
                <ul class="dropdown-menu">
			
				  <li><a class="dropdown-item" href="#" onClick="deleteTaxInvoice(${data})">Delete</a></li>
		

				    <li><a class="dropdown-item" href="#" onClick="PayTaxInvoice(${data})">Recieve Payment</a></li>
					</ul>
						</div>
								`;
					},
				},
			],
		});
	}, [taxinvoiceList]);
	//   <li>
	// 		<a class="dropdown-item" href="#" onClick="editTaxInvoice(${data})">
	// 			Edit
	// 		</a>
	// 	</li>

	window.deleteTaxInvoice = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(
					deleteTaxinvoice(id, (res) => {
						if (res.status === 200) {
							Swal.fire("Deleted!", "Your file has been deleted.", "success");
							dispatch(getTaxinvoiceList());
						}
					})
				);
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire("Cancelled", "Your Customer Data file is safe :)", "error");
			}
		});
	};

	window.editTaxInvoice = (id) => {
		navigate(`/edit/taxinvoice/${id}`);
	};
	window.Print = (id) => {
		navigate(`/print/taxinvoice/${id}`);
	};
	const [modalIsOpen, setIsOpen] = useState(false);
	function closeModal() {
		setIsOpen(false);
		setInvoiceID("");
		reset();
	}
	const accounts = useSelector(accountsSelector);
	useEffect(() => {
		dispatch(getAccount());
	}, []);
	const [formData, setFormData] = useState({
		account_id: "",
		account_balance: 0,
	});
	const [InvoiceID, setInvoiceID] = useState("");
	const accountBalance = (e) => {
		if (e === null) {
			setFormData({
				...formData,
				account_id: "",
				account_balance: "",
			});
			reset({
				...formData,
				account_id: "",
				account_balance: 0.0,
			});
			return;
		}
		setFormData({
			...formData,
			account_id: e.id,
		});

		dispatch(
			getAccountBalanceById(e.id, (res) => {
				if (res.status === 200) {
					reset({
						...formData,
						account_id: e.id,
						account_balance: res.data.account_balance,
					});
				}
			})
		);
	};

	window.PayTaxInvoice = (id) => {
		// sweet alert for pay confirmation
		Swal.fire({
			title: "Are you sure?",
			text: "You want to receive payment this invoice!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "receive payment",
		}).then((result) => {
			if (result.isConfirmed) {
				setInvoiceID(id);
				setAccountValidate(false);
				setIsOpen(true);
				dispatch(
					getRemainingBalance(id, (res) => {
						reset({
							remaining_balance: res,
						});
						setAmountLimit(res);
					})
				);
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire("Cancelled", "Your Customer Data file is safe :)", "error");
			}
		});
	};

	const [AmountLimit, setAmountLimit] = useState(0);
	const [AccountValidate, setAccountValidate] = useState(false);
	const MakeTrabsaction = (data) => {
		// console.log(data);
		if (parseFloat(data.amount) > parseFloat(data.remaining_balance)) {
			Swal.fire({
				title: "Error!",
				text: "Amount must be less than remaining balance",
				icon: "error",
				timer: 2000,
				showConfirmButton: false,
			});
			return false;
		}

		if (data.account_id === "" || data.account_id === undefined) {
			setAccountValidate(true);
			return false;
		}
		setIsOpen(false);
		Swal.fire({
			title: "Please wait...",
			text: "We are saving your data",
			icon: "info",
			showConfirmButton: false,
			allowOutsideClick: false,
		});

		dispatch(
			saveCustomerPayment(data, (res) => {
				if (res.status === 200) {
					Swal.fire({
						title: "Done!",
						text: "Customer Payment Saved",
						icon: "success",
						timer: 2000,
						showConfirmButton: false,
					});
				}
			})
		);
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Manage Tax Invoice</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a
												href="index.html"
												data-bs-original-title="data-bs-original-title"
												title="title"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-home"
												>
													<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
													<polyline points="9 22 9 12 15 12 15 22" />
												</svg>
											</a>
										</li>
										<li className="breadcrumb-item">Manage</li>
										<li className="breadcrumb-item active">Tax Invoice</li>
									</ol>
								</div>
							</div>
						</div>
					</div>
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						contentLabel="Example Modal"
						className="modal-dialog modal-dialog-centered"
						closeTimeoutMS={500}
						style={{
							//  width 1200px
							overlay: {
								backgroundColor: "rgba(0, 0, 0, 0.5)",
								zIndex: 9999,
							},
						}}
					>
						<div
							className="modal-content"
							style={{
								width: "1200px",
								margin: "0 auto",
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title">
									{InvoiceID ? "Pay Invoice" : "Make Invoice"}
								</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
									onClick={closeModal}
								/>
							</div>
							<form onSubmit={handleSubmit(MakeTrabsaction)}>
								<div className="modal-body">
									<div className="col-md-12 row">
										<div className="col-md-4">
											<label>Remaining Balance</label>
											<input
												type="text"
												className="form-control"
												name="remaining_balance"
												id="remaining_balance"
												defaultValue={0}
												readOnly
												{...register("remaining_balance", {
													required: false,
												})}
											/>
										</div>
										<div className="col-md-4 ">
											<label className="form-label">Recived on </label>
											<input
												type="date"
												className="form-control"
												required
												{...register("payment_date", {
													required: true,
												})}
											/>
											{errors.payment_date && (
												<span className="text-danger">
													This field is required
												</span>
											)}
										</div>
										<div className="col-md-4 ">
											<label className="form-label">Payment method</label>
											<select
												className="form-control"
												required
												{...register("payment_method", {
													required: true,
												})}
											>
												<option value="">Select Payment Method</option>
												<option value="cash">Cash</option>
												<option value="cheque">Cheque</option>
												<option value="bank_transfer">Bank Transfer</option>
											</select>
											{errors.payment_method && (
												<span className="text-danger">
													This field is required
												</span>
											)}
										</div>

										<div className="col-md-4 ">
											<label className="form-label">Amount</label>
											<input
												type="number"
												step="any"
												className="form-control"
												required
												{...register("amount", {
													required: true,
												})}
												max={AmountLimit}
											/>
											<input
												type="hidden"
												className="form-control"
												name="invoice_id"
												id="invoice_id"
												defaultValue={InvoiceID}
												{...register("invoice_id", {
													required: false,
												})}
											/>
											{errors.amount && (
												<span className="text-danger">
													This field is required
												</span>
											)}
										</div>
										<div className="col-md-4">
											<label>Accounts </label>
											<Controller
												name="account_id"
												control={control}
												rules={{ required: false }}
												render={({ field }) => (
													<AsyncSelect
														{...field}
														isClearable
														defaultOptions={accounts}
														loadOptions={(inputValue) =>
															new Promise((resolve) => {
																setTimeout(() => {
																	resolve(
																		accounts.filter((i) =>
																			i.account_name
																				.toLowerCase()
																				.includes(inputValue.toLowerCase())
																		)
																	);
																}, 1000);
															})
														}
														getOptionLabel={(option) => option.account_name}
														getOptionValue={(option) => option.id}
														placeholder="Select Accounts"
														value={accounts.find(
															(obj) => obj.id === field.value
														)}
														onChange={(e) => {
															field.onChange(e);
															accountBalance(e);
														}}
													/>
												)}
											/>
											{AccountValidate && (
												<span className="text-danger">
													This field is required
												</span>
											)}
										</div>
										<div className="col-md-4">
											<label>Available Balance</label>
											<input
												type="text"
												className="form-control"
												name="available-balance"
												id="available-balance"
												defaultValue={0}
												readOnly
												{...register("account_balance", {
													required: false,
												})}
											/>
										</div>
										<div className="col-md-4 ">
											<label className="form-label">Cheque No</label>
											<input
												type="text"
												className="form-control"
												{...register("cheque_no", {
													required: false,
												})}
											/>
										</div>
										<div className="col-md-4 ">
											<label className="form-label">Receipt No </label>
											<input
												type="text"
												className="form-control"
												{...register("receipt_no", {
													required: false,
												})}
											/>
										</div>

										<div className="col-md-4 ">
											<label className="form-label">Notes</label>
											<textarea
												className="form-control"
												name="notes"
												id="notes"
												placeholder="Enter Notes"
												{...register("notes", {
													required: false,
												})}
											/>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-secondary"
										data-bs-dismiss="modal"
										onClick={closeModal}
									>
										Close
									</button>
									<button type="submit" className="btn btn-primary">
										Save changes
									</button>
								</div>
							</form>
						</div>
					</Modal>
					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table className="display" id="tax-invoice-table">
												<thead>
													<tr>
														<th>Sl no</th>
														<th>Tax Invoice Number</th>
														<th>Customer </th>
														<th>Date</th>
														<th>Quantity</th>
														<th>Vat % </th>
														<th>Vat Amount</th>
														<th>Total</th>
														<th>Paid</th>
														<th>Remaining</th>
														<th>Print</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
