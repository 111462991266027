import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import { useParams } from "react-router-dom";
import { getPurchaseById } from "../../redux/api/apiPurchase";
import Swal from "sweetalert2";

export default function PDFPreview() {
	const { doctype } = useParams();
	const { id } = useParams();
	const [fileUrl, setFileUrl] = useState(null);
	const [isPDF, setIsPDF] = useState(false);

	useEffect(() => {
		if (id) {
			getPurchaseById(id, (data) => {
				console.log(doctype);
				if (doctype === "document_2") {
					const fileExtension = data.document_2.split(".").pop();

					if (fileExtension === "pdf") {
						setIsPDF(true);
					}

					setFileUrl(process.env.REACT_APP_IMAGE_URL + data.document_2);
				} else {
					const fileExtension = data.document_1.split(".").pop();

					if (fileExtension === "pdf") {
						setIsPDF(true);
					}

					setFileUrl(process.env.REACT_APP_IMAGE_URL + data.document_1);
				}
			});
		}
	}, [id, doctype]);

	return (
		<div>
			{fileUrl && (
				<>
					{isPDF ? (
						<object
							data={fileUrl}
							type="application/pdf"
							width="100%"
							height="100%"
						></object>
					) : (
						<img src={fileUrl} alt="Image" />
					)}
				</>
			)}
		</div>
	);
}
