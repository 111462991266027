import React from "react";
import { useDispatch } from "react-redux";
import { getSaleById } from "../../redux/api/apiSale";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

// import "./Print.css";

export default function Print() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { id } = useParams();
	const [sale, setSale] = useState({});
	const [custmerName, setCustomerName] = useState("");
	useEffect(() => {
		dispatch(
			getSaleById(id, (res) => {
				setSale(res.data);
				setCustomerName(res.data.customer.cus_name);
			})
		);
	}, [dispatch, id]);

	const thermalPrint = () => {
		// hide print button
		document.getElementById("print").style.display = "none";
		document.getElementById("cancel").style.display = "none";
		window.print();
		// if cancel print show print button
		document.getElementById("print").style.display = "block";
		document.getElementById("cancel").style.display = "block";
	};

	const cancelPrint = () => {
		navigate("/manage-sale");
	};

	// @media print
	return (
		<div>
			<div className="reciept">
				<div className="logo" style={{ textAlign: "center" }}>
					<img src="/creation_billing.png" alt="" />
				</div>

				<div className="table-detials">
					<table className="table">
						<tbody>
							<tr style={{ marginTop: 2 }}>
								<td>
									Customer Name :{custmerName ? custmerName : "N/A"}
									<br />
								</td>
							</tr>

							<tr style={{ marginTop: 2 }}>
								<td>Driver Code : {sale.driver_id ? sale.driver_id : "N/A"}</td>
							</tr>
							<tr style={{ marginTop: 2 }}>
								<td>
									Vehicle Code : {sale.vehicle_code ? sale.vehicle_code : "N/A"}
								</td>
							</tr>
							<tr style={{ marginTop: 2 }}>
								<td>Quantity : {sale.quantity ? sale.quantity : "N/A"}</td>
							</tr>
							<tr style={{ marginTop: 2 }}>
								<td>
									Kilometer NOW(KMs) :{" "}
									{sale.odometer_value ? sale.odometer_value : "N/A"}
								</td>
							</tr>
							<tr style={{ marginTop: 2 }}>
								<td>
									Payment Mode : {sale.payment_type ? sale.payment_type : "N/A"}
								</td>
							</tr>
							<tr style={{ marginTop: 2 }}>
								<td>
									<button
										className="btn btn-primary"
										id="print"
										onClick={() => thermalPrint()}
									>
										Print
									</button>
									<button
										className="btn btn-primary"
										id="cancel"
										onClick={() => cancelPrint()}
									>
										Cancel
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}
