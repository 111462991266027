import axios from "axios";

import Swal from "sweetalert2";
import { getCategory, getExpense } from "../../redux/Expense";
const apiExpense = axios.create({
	baseURL: process.env.REACT_APP_BASEURL + "expense",
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",

		"Access-Control-Allow-Origin": "*",
		"X-Server-ID": process.env.REACT_APP_SERVER_ID,
	},
});

apiExpense.interceptors.request.use((config) => {
	const token = localStorage.getItem("token").replace(/['"]+/g, "");
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	return config;
});

// insert expense category
export const insertCategory = (payload, callback) =>
	apiExpense
		.post(`/category/insert`, payload)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

// get expense category
export const getCategoryList = (payload, callback) => (dispatch) =>
	apiExpense.get("/category/fetch", payload).then((res) => {
		// console.log(res);
		if (res.status === 200) {
			if (callback) {
				callback(res.data);
			}

			dispatch(getCategory(res.data.data));
		}
	});

// get expense category by id
export const getCategoryById = (id, callback) =>
	apiExpense.get(`/category/fetch/${id}`).then((res) => {
		// console.log(res.data.status);
		if (res.data.status === 200) {
			if (callback) {
				callback(res.data.data);
			}
		}
	});

// update expense category
export const updateCategoryByID = (id, payload, callback) =>
	apiExpense
		.put(`/category/update/${id}`, payload)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

// delete expense category
export const deleteCategoryByID = (id, callback) =>
	apiExpense
		.delete(`/category/delete/${id}`)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

// insert expense
export const insertExpense = (payload, callback) =>
	apiExpense
		.post(`/insert`, payload, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		})
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		});

// get expense
export const getExpenseList = (payload, callback) => (dispatch) =>
	apiExpense.get("/fetch", payload).then((res) => {
		// console.log(res);
		if (res.status === 200) {
			if (callback) {
				callback(res.data);
			}

			dispatch(getExpense(res.data.data));
		}
	});

// delete expense
export const deleteExpenseByID = (id, callback) =>
	apiExpense
		.delete(`/delete/${id}`)
		.then((res) => {
			// console.log(res);
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

// get expense by id
export const getExpenseById = (id, callback) =>
	apiExpense.get(`/fetch/${id}`).then((res) => {
		// console.log(res.data.status);
		if (res.data.status === 200) {
			if (callback) {
				callback(res.data.data);
			}
		}
	});

// update expense
export const updateExpenseByID = (id, payload, callback) =>
	apiExpense
		.post(`/update/${id}`, payload, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		})
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});
