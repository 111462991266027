import { createSlice } from "@reduxjs/toolkit";

const reportSlice = createSlice({
	name: "report",
	initialState: {
		duePayment: [],
		payment: [],
		expense: [],
		ledger: [],
		cashflow: [],
		purchase_payment: [],
		transactions: [],
		ledgercustomer: [],
	},
	reducers: {
		getDuePayment: (state, action) => {
			state.duePayment = action.payload;
		},
		getPayment: (state, action) => {
			state.payment = action.payload;
		},
		getExpense: (state, action) => {
			state.expense = action.payload;
		},
		getLedger: (state, action) => {
			state.ledger = action.payload;
		},
		getCashFlow: (state, action) => {
			state.cashflow = action.payload;
		},
		getPurchasePayment: (state, action) => {
			state.purchase_payment = action.payload;
		},
		getTransactions: (state, action) => {
			state.transactions = action.payload;
		},
		getLedgerCustomer: (state, action) => {
			state.ledgercustomer = action.payload;
		},
	},
});

export const {
	getDuePayment,
	getPayment,
	getExpense,
	getLedger,
	getCashFlow,
	getPurchasePayment,
	getTransactions,
	getLedgerCustomer,
} = reportSlice.actions;

export const duePaymentSelector = (state) => state.report.duePayment;
export const paymentSelector = (state) => state.report.payment;
export const expenseSelector = (state) => state.report.expense;
export const ledgerSelector = (state) => state.report.ledger;
export const cashflowSelector = (state) => state.report.cashflow;
export const purchasePaymentSelector = (state) => state.report.purchase_payment;
export const transactionsSelector = (state) => state.report.transactions;
export const ledgerCustomerSelector = (state) => state.report.ledgercustomer;

export default reportSlice.reducer;
