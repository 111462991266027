import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import DataTable from "datatables.net-dt";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getDriverCategoryList,
	createDriver,
	getAllDriver,
	getDriverById,
	deleteDriver,
	updateDriverInfo,
} from "../../redux/api/apiDriver";
import { selectDriverCategory, selectDriver } from "../../redux/Driver";
import Modal from "react-modal";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import Swal from "sweetalert2";
import { selectCustomers } from "../../redux/Customers";
import { getAllCustomers } from "../../redux/api/apiCustomer";

export default function AddDriver() {
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();

	const [modalIsOpen, setIsOpen] = useState(false);
	const [editID, setEditID] = useState("");
	const department = useSelector(selectDriverCategory);
	const customerList = useSelector(selectCustomers);
	const driverList = useSelector(selectDriver);
	const customerLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				customerList.filter((i) =>
					i.cus_name.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};

	const departmentLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				department.filter((i) =>
					i.name.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};

	useEffect(() => {
		dispatch(getDriverCategoryList());
		dispatch(getAllCustomers());
		dispatch(getAllDriver());
	}, [dispatch]);

	function openModal() {
		setIsOpen(true);
		reset({
			cus_id: "",
			employee_id: "",
			driver_name: "",
			driver_code: "",
			driver_phone: "",
			driver_address: "",
			driver_email: "",
			driver_category_id: "",
		});
	}
	function closeModal() {
		setIsOpen(false);
		reset();
		setEditID("");
	}
	// console.log(driverList);
	let table = new DataTable("#driver-table");

	useEffect(() => {
		table.destroy();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		table = new DataTable("#driver-table", {
			data: driverList,
			columns: [
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},

				{
					data: null,
					render: function (data, type, row) {
						if (data.driver_code) {
							return data.driver_code;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type, row) {
						if (data.driver_name) {
							return data.driver_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type, row) {
						if (data.customer) {
							return data.customer.cus_name;
						}
						return "";
					},
				},

				{
					data: "id",
					render: function (data, type, row) {
						return `
	            <div class="btn-group">
	            <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
	              Action
	            </button>
	            <ul class="dropdown-menu">
	              <li><a class="dropdown-item" href="#" onClick="editDriver(${data})">Edit</a></li>
	              <li><a class="dropdown-item" href="#" onClick="deleteDriver(${data})">Delete</a></li>
	            </ul>
	          </div>

	            `;
					},
				},
			],
		});
	}, [driverList]);
	const [formData, setFormData] = useState({
		cus_id: "",
		employee_id: "",
		driver_name: "",
		driver_code: "",
		driver_phone: "",
		driver_address: "",
		driver_email: "",
		driver_category_id: "",
	});
	const addDriver = (data) => {
		// console.log(formData);
		closeModal();
		reset();
		Swal.fire({
			title: "Please wait...",
			html: "Adding Driver", // add html attribute if you want or remove
			allowOutsideClick: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		dispatch(
			createDriver(formData, (res) => {
				// console.log(res);
				if (res.status === 200) {
					dispatch(getAllDriver());
					Swal.close();
					Swal.fire({
						title: "Success",
						text: "Driver Added",
						icon: "success",
						timer: 2000,
						showConfirmButton: false,
					});
				} else {
					Swal.fire({
						title: "Error",
						text: "Something went wrong",
						icon: "error",
						timer: 2000,
						showConfirmButton: false,
					});
				}
			})
		);
	};

	const updateDriver = (data) => {
		// console.log(formData);
		closeModal();
		reset();
		Swal.fire({
			title: "Please wait...",
			html: "Updating Driver", // add html attribute if you want or remove
			allowOutsideClick: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});
		dispatch(
			updateDriverInfo(editID, formData, (res) => {
				console.log(res);
				if (res.status === 200) {
					dispatch(getAllDriver());
					Swal.close();
					Swal.fire({
						title: "Success",
						text: "Driver Updated",
						icon: "success",
						timer: 2000,
						showConfirmButton: false,
					});
				} else {
					Swal.fire({
						title: "Error",
						text: "Something went wrong",
						icon: "error",
						timer: 2000,
						showConfirmButton: false,
					});
				}
			})
		);
	};

	window.editDriver = (id) => {
		setEditID(id);
		openModal();
		dispatch(
			getDriverById(id, (res) => {
				setFormData({
					...formData,

					cus_id: res.data.customer.id,
					employee_id: res.data.employee_id,
					driver_name: res.data.driver_name,
					driver_code: res.data.driver_code,
					driver_phone: res.data.driver_phone,
					driver_address: res.data.driver_address,
					driver_email: res.data.driver_email,
					driver_category_id: res.data.driver_category.id,
				});

				reset({
					cus_id: res.data.customer.id,
					employee_id: res.data.employee_id,
					driver_name: res.data.driver_name,
					driver_code: res.data.driver_code,
					driver_phone: res.data.driver_phone,
					driver_address: res.data.driver_address,
					driver_email: res.data.driver_email,
					driver_category_id: res.data.driver_category.id,
				});
			})
		);
	};

	window.deleteDriver = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(
					deleteDriver(id, (res) => {
						if (res.status === 200) {
							dispatch(getAllDriver());
							Swal.fire("Deleted!", "Your file has been deleted.", "success");
						}
					})
				);
			}
		});
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Manage Driver</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="index.html">
												<i data-feather="home" />
											</a>
										</li>
										<li className="breadcrumb-item">Driver</li>
										<li className="breadcrumb-item active">Manage Driver</li>
									</ol>
								</div>
							</div>
						</div>
					</div>
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						contentLabel="Example Modal"
						className="modal-dialog modal-dialog-centered"
						closeTimeoutMS={500}
						style={{
							//  width 1200px
							overlay: {
								backgroundColor: "rgba(0, 0, 0, 0.5)",
								zIndex: 9999,
							},
						}}
					>
						<div
							className="modal-content"
							style={{
								width: "1200px",
								margin: "0 auto",
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title">
									{editID ? "Edit Driver" : "Add Driver"}
								</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
									onClick={closeModal}
								/>
							</div>
							<form onSubmit={handleSubmit(editID ? updateDriver : addDriver)}>
								<div className="modal-body">
									<div className="col-md-12 row">
										<div className="col-md-6">
											<div className="form-group">
												<label htmlFor="department_id">Client *</label>
												<Controller
													name="cus_id"
													control={control}
													rules={{ required: true }}
													render={({ field }) => (
														<AsyncSelect
															{...field}
															isClearable
															defaultOptions={customerList}
															loadOptions={customerLoadOptions}
															getOptionLabel={(option) => option.cus_name}
															getOptionValue={(option) => option.id}
															placeholder="Select Customer"
															value={customerList.find(
																(obj) => obj.id === field.value
															)}
															onChange={(e) => {
																field.onChange(e);
																setFormData({ ...formData, cus_id: e.id });
															}}
														/>
													)}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label htmlFor="department_id">Driver ID</label>
												<input
													type="text"
													className="form-control"
													id="employee_id"
													placeholder="Enter Driver ID"
													{...register("employee_id", {
														required: false,
													})}
													onChange={(e) => {
														setFormData({
															...formData,
															employee_id: e.target.value,
														});
													}}
												/>
											</div>
										</div>

										<div className="col-md-6">
											<div className="form-group">
												<label htmlFor="department_id">Driver Name*</label>
												<input
													type="text"
													className="form-control"
													id="driver_name"
													placeholder="Enter Driver Name"
													{...register("driver_name", {
														required: true,
													})}
													onChange={(e) => {
														setFormData({
															...formData,
															driver_name: e.target.value,
														});
													}}
												/>
												{errors.name && (
													<span className="text-danger">
														This field is required
													</span>
												)}
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label htmlFor="department_id">Driver Code *</label>
												<input
													type="text"
													className="form-control"
													id="driver_code"
													placeholder="Enter Driver Code"
													{...register("driver_code", {
														required: true,
													})}
													onChange={(e) => {
														setFormData({
															...formData,
															driver_code: e.target.value,
														});
													}}
												/>
												{errors.name && (
													<span className="text-danger">
														This field is required
													</span>
												)}
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label htmlFor="department_id">Department </label>
												<Controller
													name="driver_category_id"
													control={control}
													rules={{ required: false }}
													render={({ field }) => (
														<AsyncSelect
															{...field}
															isClearable
															defaultOptions={department}
															loadOptions={departmentLoadOptions}
															getOptionLabel={(option) => option.name}
															getOptionValue={(option) => option.id}
															placeholder="Select Department"
															value={department.find(
																(obj) => obj.id === field.value
															)}
															onChange={(e) => {
																field.onChange(e);
																setFormData({
																	...formData,
																	driver_category_id: e.id,
																});
															}}
														/>
													)}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label htmlFor="department_id">Phone </label>
												<input
													type="text"
													className="form-control"
													id="phone"
													placeholder="Enter Phone"
													{...register("driver_phone", {
														required: false,
													})}
													onChange={(e) => {
														setFormData({
															...formData,
															driver_phone: e.target.value,
														});
													}}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label htmlFor="department_id">Email </label>
												<input
													type="text"
													className="form-control"
													id="email"
													placeholder="Enter Email"
													{...register("driver_email", {
														required: false,
													})}
													onChange={(e) => {
														setFormData({
															...formData,
															driver_email: e.target.value,
														});
													}}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label htmlFor="department_id">Address </label>
												<textarea
													type="text"
													className="form-control"
													id="address"
													placeholder="Enter Address"
													{...register("driver_address", {
														required: false,
													})}
													onChange={(e) => {
														setFormData({
															...formData,
															driver_address: e.target.value,
														});
													}}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-secondary"
										data-bs-dismiss="modal"
										onClick={closeModal}
									>
										Close
									</button>
									<button type="submit" className="btn btn-primary">
										Save changes
									</button>
								</div>
							</form>
						</div>
					</Modal>

					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<button
											class="btn btn-primary"
											type="button"
											onClick={openModal}
										>
											Add Driver
										</button>

										<div className="table-responsive">
											<table
												className="display"
												id="driver-table"
												style={{
													width: "100%",
													textAlign: "center",
													border: "1px solid #dee2e6",
												}}
											>
												<thead>
													<tr>
														<th>Sl no</th>
														<th>Driver Code</th>
														<th>Driver Name</th>
														<th>Customer Name</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
