import React, { useEffect, useState } from "react";
import Footer from "../dashboard/components/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../dashboard/components/Sidebar";
import Header from "../dashboard/components/Header";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import {
	getCategoryById,
	getCategoryList,
	getExpenseById,
	insertExpense,
	updateExpenseByID,
} from "../../redux/api/apiExpense";
import { categorySelector } from "../../redux/Expense";
import { getAccount, getAccountBalanceById } from "../../redux/api/apiAccounts";
import { accountsSelector } from "../../redux/Accounts";
export default function Expense() {
	const navigate = useNavigate();
	const { id } = useParams();
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const categories = useSelector(categorySelector);
	const accounts = useSelector(accountsSelector);
	useEffect(() => {
		dispatch(getCategoryList());
		dispatch(getAccount());
	}, []);
	const accountBalance = (e) => {
		if (e === null) {
			reset({
				account_balance: "",
				account_id: "",
			});
			setFormData({
				account_id: "",
			});
		}
		setFormData({
			...formData,
			account_id: e.id,
		});
		dispatch(
			getAccountBalanceById(e.id, (res) => {
				if (res.status === 200) {
					reset({
						account_balance: res.data.account_balance,
					});
					setAmountValidation(res.data.account_balance);
				}
			})
		);
	};
	const [formData, setFormData] = useState({
		expense_category_id: "",
		account_id: "",
		amount: "",
		notes: "",
		date: "",
		cheque_no: "",
		voucher_no: "",
		image: "",
		expense_reason: "",
	});
	const saveExpense = (data) => {
		if (formData.expense_category_id === "") {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please select category",
			});
			return false;
		}
		// console.log(formData);
		if (formData.account_id === "" || formData.account_id === undefined) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please select account",
			});
			return false;
		}

		Swal.fire({
			title: "Please wait...",
			html: "Saving data",
			allowOutsideClick: false,
			onBeforeOpen: () => {
				Swal.showLoading();
			},
		});

		dispatch(
			insertExpense(formData, (res) => {
				if (res.status === 200) {
					Swal.fire({
						icon: "success",
						title: "Success",
						text: res.message,
					});
					reset({
						expense_category_id: "",
						account_id: "",
						amount: "",
						notes: "",
						date: "",
						cheque_no: "",
						voucher_no: "",
						image: "",
						expense_reason: "",
						account_balance: "",
					});
					setFormData({
						expense_category_id: "",
						account_id: "",
						amount: "",
						notes: "",
						date: "",
						cheque_no: "",
						voucher_no: "",
						image: "",
						expense_reason: "",
					});
					savecategory("");
					accountBalance("");
				}
			})
		);
	};

	const savecategory = (e) => {
		setFormData({
			...formData,
			expense_category_id: e.id,
		});
	};

	useEffect(() => {
		if (id) {
			getExpenseById(id, (data) => {
				// setFormData(data);
				// reset(data);
				// console.log(data.expense_category.id);
				dispatch(
					getAccountBalanceById(data.account_id, (res) => {
						if (res.status === 200) {
							reset({
								account_balance: res.data.account_balance,
								expense_category_id: data.expense_category.id,
								account_id: data.account_id,
								amount: data.amount,
								notes: data.notes,
								date: data.date,
								cheque_no: data.cheque_no,
								voucher_no: data.voucher_no,
								expense_reason: data.expense_reason,
							});
							setAmountValidation(res.data.account_balance);
						}
					})
				);
				reset({
					expense_category_id: data.expense_category.id,
					account_id: data.account_id,
					amount: data.amount,
					notes: data.notes,
					date: data.date,
					cheque_no: data.cheque_no,
					voucher_no: data.voucher_no,
					expense_reason: data.expense_reason,
				});
				setFormData({
					expense_category_id: data.expense_category.id,
					account_id: data.account_id,
					amount: data.amount,
					notes: data.notes,
					date: data.date,
					cheque_no: data.cheque_no,
					voucher_no: data.voucher_no,
					expense_reason: data.expense_reason,
				});

				// savecategory(data.expense_category_id);
			});
		} else {
			reset({
				expense_category_id: "",
				account_id: "",
				amount: "",
				notes: "",
				date: "",
				cheque_no: "",
				voucher_no: "",
				image: "",
				expense_reason: "",
				account_balance: "",
			});
			setFormData({
				expense_category_id: "",
				account_id: "",
				amount: "",
				notes: "",
				date: "",
				cheque_no: "",
				voucher_no: "",
				image: "",
				expense_reason: "",
			});
			savecategory("");
			accountBalance("");
		}
	}, [id]);
	const [amountValidation, setAmountValidation] = useState("");
	const updateExpense = (data) => {
		// console.log(formData);
		Swal.fire({
			title: "Please wait...",
			html: "Saving data",
			allowOutsideClick: false,
			onBeforeOpen: () => {
				Swal.showLoading();
			},
		});

		dispatch(
			updateExpenseByID(id, formData, (res) => {
				if (res.status === 200) {
					Swal.fire({
						icon: "success",
						title: "Success",
						text: res.message,
					});
					reset({
						expense_category_id: "",
						account_id: "",
						amount: "",
						notes: "",
						date: "",
						cheque_no: "",
						voucher_no: "",
						image: "",
						expense_reason: "",
						account_balance: "",
					});
					savecategory("");
					accountBalance("");
					navigate("/manage-expense");
				}
			})
		);
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>{id ? "Update" : "Create"} Expense</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<Link to="#">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-home"
												>
													<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
													<polyline points="9 22 9 12 15 12 15 22" />
												</svg>
											</Link>
										</li>
										<li className="breadcrumb-item">Expense</li>
										<li className="breadcrumb-item active">
											{id ? "Update" : "Create"} Expense
										</li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid project-create">
						<div className="row">
							<form
								id="addCustomerForm"
								className="theme-form"
								onSubmit={handleSubmit(id ? updateExpense : saveExpense)}
							>
								<div className="col-sm-12">
									<div className="card">
										<div className="card-body">
											<div className="form theme-form">
												<div className="col-md-12">
													<div className="row">
														<div className="col-md-6 ">
															<label className="form-label">
																Expense Reason *
															</label>
															<input
																type="text"
																className="form-control"
																placeholder="Enter Expense Reason *"
																{...register("expense_reason", {
																	required: true,
																})}
																onChange={(e) =>
																	setFormData({
																		...formData,
																		expense_reason: e.target.value,
																	})
																}
															/>
															{errors.expense_reason && (
																<span className="text-danger">
																	This field is required
																</span>
															)}
														</div>

														<div className="col-md-6  mt-2">
															<div className="form-group">
																<label htmlFor="expense_category_id">
																	Category Name *
																</label>
																<Controller
																	name="expense_category_id"
																	control={control}
																	rules={{ required: false }}
																	render={({ field }) => (
																		<AsyncSelect
																			{...field}
																			isClearable
																			defaultOptions={categories}
																			loadOptions={(inputValue) =>
																				new Promise((resolve) => {
																					setTimeout(() => {
																						resolve(
																							categories.filter((i) =>
																								i.cat_name
																									.toLowerCase()
																									.includes(
																										inputValue.toLowerCase()
																									)
																							)
																						);
																					}, 1000);
																				})
																			}
																			getOptionLabel={(option) =>
																				option.cat_name
																			}
																			getOptionValue={(option) => option.id}
																			placeholder="Select Category"
																			value={categories.find(
																				(obj) => obj.id === field.value
																			)}
																			onChange={(e) => {
																				field.onChange(e);
																				savecategory(e);
																				// setFormData({ ...formData, account_id: e.id });
																			}}
																		/>
																	)}
																/>
																{errors.expense_category_id && (
																	<span className="text-danger">
																		This field is required
																	</span>
																)}
															</div>
														</div>

														<div className="col-md-4  mt-2">
															<div className="form-group">
																<label htmlFor="account_id">Account *</label>
																<Controller
																	name="account_id"
																	control={control}
																	rules={{ required: false }}
																	render={({ field }) => (
																		<AsyncSelect
																			{...field}
																			isClearable
																			defaultOptions={accounts}
																			loadOptions={(inputValue) =>
																				new Promise((resolve) => {
																					setTimeout(() => {
																						resolve(
																							accounts.filter((i) =>
																								i.account_name
																									.toLowerCase()
																									.includes(
																										inputValue.toLowerCase()
																									)
																							)
																						);
																					}, 1000);
																				})
																			}
																			getOptionLabel={(option) =>
																				option.account_name
																			}
																			getOptionValue={(option) => option.id}
																			placeholder="Select Accounts"
																			value={accounts.find(
																				(obj) => obj.id === field.value
																			)}
																			onChange={(e) => {
																				field.onChange(e);
																				accountBalance(e);
																				// setFormData({ ...formData, account_id: e.id });
																			}}
																		/>
																	)}
																	disabled={id ? true : false}
																/>
																{errors.account_id && (
																	<span className="text-danger">
																		This field is required
																	</span>
																)}
															</div>
														</div>
														<div className="col-md-4 ">
															<label className="form-label">
																Available Balance
															</label>
															<input
																type="text"
																className="form-control"
																defaultValue={0.0}
																readOnly
																{...register("account_balance", {
																	required: false,
																})}
															/>
														</div>
														<div className="col-md-4 ">
															<label className="form-label">Amount</label>
															<input
																type="number"
																className="form-control"
																step="any"
																{...register("amount", {
																	required: true,
																})}
																onChange={(e) =>
																	setFormData({
																		...formData,
																		amount: e.target.value,
																	})
																}
																max={amountValidation}
															/>
															{errors.amount && (
																<span className="text-danger">
																	This field is required
																</span>
															)}
														</div>
														<div className="col-md-4 ">
															<label className="form-label">Cheque No</label>
															<input
																type="text"
																className="form-control"
																{...register("cheque_no", {
																	required: false,
																})}
																onChange={(e) =>
																	setFormData({
																		...formData,
																		cheque_no: e.target.value,
																	})
																}
															/>
														</div>
														<div className="col-md-4 ">
															<label className="form-label">Voucher No </label>
															<input
																type="text"
																className="form-control"
																{...register("voucher_no", {
																	required: false,
																})}
																onChange={(e) =>
																	setFormData({
																		...formData,
																		voucher_no: e.target.value,
																	})
																}
															/>
														</div>
														<div className="col-md-4 ">
															<label className="form-label"> Date</label>
															<input
																type="date"
																className="form-control"
																name="date"
																{...register("date", {
																	required: true,
																})}
																onChange={(e) =>
																	setFormData({
																		...formData,
																		date: e.target.value,
																	})
																}
															/>
															{errors.date && (
																<span className="text-danger">
																	This field is required
																</span>
															)}
														</div>

														<div className="col-md-6 ">
															<label className="form-label"> Image</label>
															<input
																type="file"
																className="form-control"
																name="image"
																{...register("image", {
																	required: false,
																})}
																onChange={(e) =>
																	setFormData({
																		...formData,
																		image: e.target.files[0],
																	})
																}
															/>
														</div>
														<div className="col-md-12 ">
															<label className="form-label">Notes</label>
															<textarea
																className="form-control"
																name="notes"
																id="notes"
																placeholder="Enter Notes"
																defaultValue={""}
																{...register("notes", {
																	required: false,
																})}
																onChange={(e) =>
																	setFormData({
																		...formData,
																		notes: e.target.value,
																	})
																}
															/>
														</div>
													</div>
												</div>
												<div className="col-md-12">
													<div className="mb-3">
														<button type="submit" className="btn btn-primary">
															Submit
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
