import React, { Component } from "react";
import Chart from "react-apexcharts";
export default class TransactionHistory extends Component {
	constructor(props) {
		super(props);
		console.log(props);

		this.state = {
			series: [
				{
					name: "Sales",
					data: props.series,
				},
			],
			options: {
				chart: {
					height: 350,
					type: "area",
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "smooth",
				},
				xaxis: {
					type: "date",
					categories: props.categories,
				},
				tooltip: {
					x: {
						format: "dd/MM/yy HH:mm",
					},
				},
			},
		};
	}

	render() {
		return (
			<div id="chart">
				<Chart
					options={this.state.options}
					series={this.state.series}
					type="area"
					height={350}
				/>
			</div>
		);
	}
}
