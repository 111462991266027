import React, { useState } from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import { getProfitAndLoss } from "../../redux/api/apiReports";
import { useDispatch } from "react-redux";
import html2pdf from "html2pdf.js";
export default function Profit_Loss() {
	const [exportToPdf, setExportToPdf] = useState(false);

	let defaultDate = new Date();
	defaultDate.setDate(defaultDate.getDate());
	// defaultDate to year month date
	defaultDate = defaultDate.toISOString().substr(0, 10);
	// setCurrentDate(defaultDate);
	// console.log(defaultDate);
	const [filterList, setFilterList] = useState({
		datefrom: defaultDate,
		dateto: defaultDate,
	});

	const [totalSales, setTotalSales] = useState(0);
	const [totalExpense, setTotalExpense] = useState(0);
	const [totalPurchase, setTotalPurchase] = useState(0);

	const dispatch = useDispatch();
	const getReport = () => {
		dispatch(
			getProfitAndLoss(filterList, (res) => {
				// console.log(res);
				setTotalSales(res.data.totalSale);
				setTotalExpense(res.data.totalExpense);
				setTotalPurchase(res.data.totalPurchase);

				setExportToPdf(true);
			})
		);
	};
	const logoos = localStorage.getItem("c_logo");
	var logo = process.env.REACT_APP_IMAGE_URL + logoos.replace(/['"]+/g, "");

	var company_name = localStorage.getItem("c_name");
	// remove double quotes
	company_name = company_name.replace(/['"]+/g, "");
	var company_address = localStorage.getItem("c_address");
	company_address = company_address.replace(/['"]+/g, "");
	var company_phone = localStorage.getItem("c_phone");
	company_phone = company_phone.replace(/['"]+/g, "");
	var company_email = localStorage.getItem("c_email");
	company_email = company_email.replace(/['"]+/g, "");
	const exportPDFWithComponent = () => {
		// document.getElementById("print").style.display = "none";
		// setExportToPdf(false);
		// // hide the button

		const element = document.getElementById("canva-component"); // Replace 'canva-component' with the ID of your Canva component

		// // how to remove datatables while exporting to pdf

		html2pdf()
			.set({
				margin: 0.5,
				filename: "profit_and_loss.pdf",
				image: { type: "jpeg", quality: 0.98 },
				html2canvas: { scale: 2, useCORS: true },
				jsPDF: {
					unit: "in",
					format: [20.4, 20],
					orientation: "landscape",
				},
			})
			.from(element)
			.save();
	};
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />

				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Profit &amp; Loss Report</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="index.html">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-home"
												>
													<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
													<polyline points="9 22 9 12 15 12 15 22" />
												</svg>
											</a>
										</li>
										<li className="breadcrumb-item"> Reports</li>
										<li className="breadcrumb-item active">
											Profit &amp; Loss Report{" "}
										</li>
									</ol>
								</div>
							</div>
						</div>
					</div>
					{/* Container-fluid starts*/}
					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<label htmlFor="vehicle">DateFrom</label>
													<input
														type="date"
														className="form-control"
														id="dateFrom"
														onChange={(e) =>
															setFilterList({
																...filterList,
																datefrom: e.target.value,
															})
														}
														defaultValue={defaultDate}
														value={filterList.datefrom}
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<label htmlFor="vehicle">DateTo</label>
													<input
														type="date"
														className="form-control"
														id="dateTo"
														onChange={(e) =>
															setFilterList({
																...filterList,
																dateto: e.target.value,
															})
														}
														defaultValue={defaultDate}
														value={filterList.dateto}
													/>
												</div>
											</div>
											<div
												className="col-md-3 mt-4"
												style={{
													display: "inline-grid",
													marginLeft: "10px",
												}}
											>
												<button
													type="button"
													className="btn btn-primary btn-sm"
													onClick={getReport}
												>
													Search
												</button>
											</div>
											<div
												className="col-md-3 mt-4"
												style={{
													display: exportToPdf ? "inline-grid" : "none",
													marginLeft: "10px",
												}}
											>
												<button
													type="button"
													className="btn btn-success btn-sm"
													id="export_to_pdf"
													onClick={exportPDFWithComponent}
												>
													PDF
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								className="col-sm-12"
								style={{
									display: exportToPdf ? "inline-grid" : "none",
								}}
							>
								<div className="card" id="canva-component">
									<div className="card-body">
										<div className="row">
											<div className="col-lg-12">
												<div className="invoice p-3 mb-3">
													<div className="m-auto invoice-col">
														<div className="company-logo text-center">
															<img src={logo} alt="logo" className="lg-logo" />
															<address className="mt-2">
																{" "}
																<strong>Phone:</strong>
																{company_phone}
																<br /> <strong>Email: </strong>
																{company_email}
																<br /> <strong>Address: </strong>
																{company_address}
															</address>
														</div>
													</div>
													<hr />
													<div className="row mt-5 position-relative">
														<div
															className="position-absolute w-100 h-100 opacity-50 z-10 tbl-loading"
															style={{ display: "none" }}
														>
															<div className="loader07" />
														</div>
														<div className="table-responsive table-custom">
															<table className="table text-left ">
																<thead>
																	<tr className="success text-center">
																		<th colSpan={3}>
																			<h5>
																				Income Statement <br />
																			</h5>
																		</th>
																	</tr>
																	<tr className="text-center">
																		<td colSpan={3}>
																			<strong>
																				{filterList.datefrom} To{" "}
																				{filterList.dateto}
																			</strong>
																		</td>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<th>Total Sales Payment</th>
																		<td />
																		<td className="text-right">
																			<strong>{totalSales} د.إ</strong>
																		</td>
																	</tr>
																	<tr>
																		<td colSpan={3} />
																	</tr>

																	<tr>
																		<th>
																			<span>Gross Profit</span>
																		</th>
																		<td />
																		<td className="text-right">
																			<strong>{totalSales} د.إ</strong>
																		</td>
																	</tr>
																	<tr>
																		<td colSpan={3} />
																	</tr>
																	<tr>
																		<th colSpan={3}>Operating Expenses</th>
																	</tr>

																	<tr>
																		<th>Total Expense</th>
																		<td />
																		<td className="text-right">
																			<strong>{totalExpense} د.إ</strong>
																		</td>
																	</tr>
																	<tr>
																		<th>Total Purchase Payment</th>
																		<td />
																		<td className="text-right">
																			<strong>{totalPurchase} د.إ</strong>
																		</td>
																	</tr>
																	<tr className="text-danger">
																		<th>
																			<span>Net Loss</span>
																		</th>
																		<td />
																		<td className="text-right">
																			<strong>
																				{totalSales -
																					totalExpense -
																					totalPurchase}{" "}
																				د.إ
																			</strong>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Footer />
			</div>
		</div>
	);
}
