import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../../../redux/api/apiSale";
import { notificationSelector } from "../../../redux/Sale";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import DataTable from "datatables.net-dt";

export default function AllNotification() {
	const dispatch = useDispatch();
	const notification = useSelector(notificationSelector);

	useEffect(() => {
		dispatch(getNotifications());
	}, [dispatch]);
	let table = new DataTable("#notification-table");
	useEffect(() => {
		// console.log(priceList);
		table.destroy();
		table = new DataTable("#notification-table", {
			data: notification,
			columns: [
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},
				{ data: "message" },
				{ data: "date" },
			],
		});
	}, [notification]);

	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Notification</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a
												href="index.html"
												data-bs-original-title="data-bs-original-title"
												title="title"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-home"
												>
													<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
													<polyline points="9 22 9 12 15 12 15 22" />
												</svg>
											</a>
										</li>
										<li className="breadcrumb-item">All</li>
										<li className="breadcrumb-item active">Notification</li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table className="display" id="notification-table">
												<thead>
													<tr>
														<th>Sl no</th>
														<th>Message</th>
														<th>Time</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
