import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { updateCompanyInfo, fetchCompanyInfo } from "../../redux/api/apiUser";
import { useDispatch, useSelector } from "react-redux";
import { selectCompanyInfo } from "../../redux/Users";
import Swal from "sweetalert2";

export default function Info() {
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const companyInfo = useSelector(selectCompanyInfo);
	React.useEffect(() => {
		dispatch(
			fetchCompanyInfo((res) => {
				reset({
					company_name: res.company_name,
					company_address: res.company_address,
					company_phone: res.company_phone,
					company_email: res.company_email,
					company_website: res.company_website,
				});
			})
		);
	}, [dispatch, reset]);

	localStorage.setItem("c_name", companyInfo.company_name);
	localStorage.setItem("c_address", companyInfo.company_address);
	localStorage.setItem("c_phone", companyInfo.company_phone);
	localStorage.setItem("c_email", companyInfo.company_email);
	localStorage.setItem("c_website", companyInfo.company_website);
	localStorage.setItem("c_logo", companyInfo.company_logo);

	const saveInfo = (data) => {
		const formData = new FormData();
		// formData.append("company_logo", data.company_logo[0]);
		formData.append("company_name", data.company_name);
		formData.append("company_address", data.company_address);
		formData.append("company_phone", data.company_phone);
		formData.append("company_email", data.company_email);
		formData.append("company_website", data.company_website);

		// console.log(formData.get("company_logo")); // Verify the logo file data

		dispatch(
			updateCompanyInfo(formData, (callback) => {
				if (callback.status === 200) {
					reset();
					Swal.fire({
						icon: "success",
						title: "Success",
						text: "Company info updated successfully",
					});

					dispatch(
						fetchCompanyInfo((res) => {
							reset({
								company_name: res.company_name,
								company_address: res.company_address,
								company_phone: res.company_phone,
								company_email: res.company_email,
								company_website: res.company_website,
							});
						})
					);
				}
			})
		);
	};

	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}

	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}

	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Info</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<Link to="#">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-home"
												>
													<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
													<polyline points="9 22 9 12 15 12 15 22" />
												</svg>
											</Link>
										</li>
										<li className="breadcrumb-item">Company</li>
										<li className="breadcrumb-item active">Info</li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid project-create">
						<div className="row">
							<form
								id="addCustomerForm"
								className="theme-form"
								onSubmit={handleSubmit(saveInfo)}
							>
								<div className="col-sm-12">
									<div className="card">
										<div className="card-body">
											<div className="form theme-form">
												<div className="col-md-12">
													<div className="row">
														<div className="col-md-6 ">
															<label className="form-label">Company Name</label>
															<input
																type="text"
																className="form-control"
																placeholder="Company Name"
																{...register("company_name", {
																	required: false,
																})}
															/>
														</div>
														<div className="col-md-6 ">
															<label className="form-label">
																Company Address
															</label>
															<textarea
																className="form-control"
																name="notes"
																id="notes"
																placeholder="Company Address"
																{...register("company_address", {
																	required: false,
																})}
															/>
														</div>
														<div className="col-md-6 ">
															<label className="form-label">
																Company Phone
															</label>
															<input
																type="text"
																className="form-control"
																placeholder="Company Phone"
																{...register("company_phone", {
																	required: false,
																})}
															/>
														</div>
														<div className="col-md-6 ">
															<label className="form-label">
																Company Email
															</label>
															<input
																type="text"
																className="form-control"
																placeholder="Company Email"
																{...register("company_email", {
																	required: false,
																})}
															/>
														</div>
														<div className="col-md-6 ">
															<label className="form-label">
																Company Website
															</label>
															<input
																type="text"
																className="form-control"
																placeholder="Company Website"
																{...register("company_website", {
																	required: false,
																})}
															/>
														</div>
														{/* <div className="col-md-6 ">
															<label className="form-label">Company Logo</label>
															<input
																type="file"
																className="form-control"
																placeholder="Company Logo"
																{...register("company_logo", {
																	required: false,
																})}
																accept="image/*"
															/>
														</div> */}
													</div>
												</div>
												<div className="col-md-12">
													<div className="mb-3">
														<button type="submit" className="btn btn-primary">
															Submit
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
