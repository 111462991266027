import React, { useState } from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import DataTable from "datatables.net-dt";
import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
	deletePurchase,
	getPurchaseList,
	getRemainingPurchaseBalance,
	saveSupplierPayment,
} from "../../redux/api/apiPurchase";
import { useDispatch, useSelector } from "react-redux";
import { purchaseSelector } from "../../redux/Purchase";
import Swal from "sweetalert2";
import { useForm, Controller } from "react-hook-form";
import { accountsSelector } from "../../redux/Accounts";
import Modal from "react-modal";
import AsyncSelect from "react-select/async";
import { getAccount, getAccountBalanceById } from "../../redux/api/apiAccounts";
import PDFPreview from "./PDFPreview";
export default function Managepurchase() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const dispatch = useDispatch();
	const purchaseList = useSelector(purchaseSelector);
	const accounts = useSelector(accountsSelector);
	useEffect(() => {
		dispatch(getPurchaseList());
		dispatch(getAccount());
	}, []);
	const [formData, setFormData] = useState({
		account_id: "",
		account_balance: 0,
	});
	const [AmountLimit, setAmountLimit] = useState(0);

	const accountBalance = (e) => {
		if (e === null) {
			setFormData({
				...formData,
				account_id: "",
				account_balance: "",
			});
			reset({
				...formData,
				account_id: "",
				account_balance: 0.0,
			});
			return;
		}
		setFormData({
			...formData,
			account_id: e.id,
		});

		dispatch(
			getAccountBalanceById(e.id, (res) => {
				if (res.status === 200) {
					reset({
						...formData,
						account_id: e.id,
						account_balance: res.data.account_balance,
					});
				}
			})
		);
	};

	const MakeTrabsaction = (data) => {
		// console.log(data);
		if (parseFloat(data.amount) > parseFloat(data.remaining_balance)) {
			Swal.fire({
				title: "Error!",
				text: "Amount must be less than remaining balance",
				icon: "error",
				timer: 2000,
				showConfirmButton: false,
			});
			return false;
		}

		if (data.account_id === "" || data.account_id === undefined) {
			setAccountValidate(true);
			return false;
		}

		Swal.fire({
			title: "Please wait...",
			text: "We are saving your data",
			icon: "info",
			showConfirmButton: false,
			allowOutsideClick: false,
		});

		dispatch(
			saveSupplierPayment(data, (res) => {
				if (res.status === 200) {
					Swal.fire({
						title: "Done!",
						text: " Payment Saved",
						icon: "success",
						timer: 2000,
						showConfirmButton: false,
					});
					setIsOpen(false);
					dispatch(getPurchaseList());
				}
			})
		);
	};

	let table = new DataTable("#purchase-table");
	useEffect(() => {
		table.destroy();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		table = new DataTable("#purchase-table", {
			data: purchaseList,
			columns: [
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},

				{
					data: null,
					render: function (data, type) {
						if (data.supplier) {
							return data.supplier.sp_name;
						}
						return "-";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.date) {
							return data.date;
						}
						return "-";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.po_reference) {
							return data.po_reference;
						}
						return "-";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.invoice_number) {
							return data.invoice_number;
						}
						return "-";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.delivery_at) {
							return data.delivery_at;
						}
						return "-";
					},
				},

				{
					data: null,
					render: function (data, type) {
						if (data.quantity) {
							return data.quantity;
						}
						return 0;
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.item_type) {
							return data.item_type;
						}
						return "-";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.purchase_price) {
							return data.purchase_price;
						}
						return 0;
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.sub_total) {
							return data.sub_total;
						}
						return 0;
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.tax_amount) {
							return data.tax_amount;
						}
						return 0;
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.discount) {
							return data.discount;
						}
						return 0;
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.net_total) {
							return data.net_total;
						}
						return 0;
					},
				},
				{
					data: null,
					render: function (data, type) {
						var paid = 0;
						if (data.paid) {
							paid = data.paid.total;
							return paid;
						}
						return paid;
					},
				},
				{
					data: null,
					render: function (data, type) {
						var paid = 0;
						var bill = 0;
						var remaining = 0;
						if (data.net_total && data.paid) {
							bill = data.net_total;
							paid = data.paid.total;
							remaining = bill - paid;
							return remaining;
						}
						return remaining;
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.document_1) {
							const type = "document_1";
							return (
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								`<a
									href="#"
									onClick="window.document_2('${type}','${data.id}')"
								>
									 Download
								</a>`
							);
						} else {
							return "-";
						}
					},
				},

				{
					data: null,
					render: function (data, type) {
						if (data.document_2) {
							const type = "document_2";
							return (
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								`<a
									href="#"
									onClick="window.document_2('${type}','${data.id}')"
								>
									 Download
								</a>`
							);
						} else {
							return "-";
						}
					},
				},
				{
					data: "id",
					render: function (data, type, row) {
						return `
                <div class="btn-group">
                <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  Action
                </button>
                <ul class="dropdown-menu">
				  <li><a class="dropdown-item" href="#" onClick="editPurchase(${data})">Edit</a></li>
				  <li><a class="dropdown-item" href="#" onClick="deletePurchase(${data})">Delete</a></li>
				   <li><a class="dropdown-item" href="#" onClick="PayPurchaseInvoice(${data})">Pay</a></li>
					</ul>
						</div>
								`;
					},
				},
			],
		});
	}, [purchaseList]);
	const navigate = useNavigate();

	window.document_2 = (type, id) => {
		navigate("/view/data/" + type + "/" + id);
	};

	const [InvoiceID, setInvoiceID] = useState("");
	const [AccountValidate, setAccountValidate] = useState(false);
	const [modalIsOpen, setIsOpen] = useState(false);
	function closeModal() {
		setIsOpen(false);
		setInvoiceID("");
		reset();
	}
	window.PayPurchaseInvoice = (id) => {
		reset();
		accountBalance(null);

		// sweet alert for pay confirmation
		Swal.fire({
			title: "Are you sure?",
			text: "You want to pay this invoice!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Pay",
		}).then((result) => {
			if (result.isConfirmed) {
				setInvoiceID(id);
				setAccountValidate(false);
				setIsOpen(true);

				dispatch(
					getRemainingPurchaseBalance(id, (res) => {
						reset({
							remaining_balance: res,
						});
						setAmountLimit(res);
					})
				);
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire("Cancelled", "Your data safe :)", "error");
			}
		});
	};

	window.deletePurchase = (id) => {
		// console.log(id);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(
					deletePurchase(id, (res) => {
						if (res === 200) {
							Swal.fire({
								icon: "success",
								title: "Success",
								text: res.message,
							});
							dispatch(getPurchaseList());
						}
					})
				);
			} else {
				Swal.fire({
					icon: "error",
					title: "Cancelled",
					text: "Your data is safe :)",
				});
			}
		});
	};

	window.editPurchase = (id) => {
		navigate("/edit/purchase/" + id);
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Purchase</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a
												href="index.html"
												data-bs-original-title="data-bs-original-title"
												title="title"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-home"
												>
													<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
													<polyline points="9 22 9 12 15 12 15 22" />
												</svg>
											</a>
										</li>
										<li className="breadcrumb-item">Manage</li>
										<li className="breadcrumb-item active">Purchase</li>
									</ol>
								</div>
							</div>
						</div>
					</div>
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						contentLabel="Example Modal"
						className="modal-dialog modal-dialog-centered"
						closeTimeoutMS={500}
						style={{
							//  width 1200px
							overlay: {
								backgroundColor: "rgba(0, 0, 0, 0.5)",
								zIndex: 9999,
							},
						}}
					>
						<div
							className="modal-content"
							style={{
								width: "1200px",
								margin: "0 auto",
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title">
									{InvoiceID ? "Pay Purchase Invoice" : "Make Purchase Invoice"}
								</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
									onClick={closeModal}
								/>
							</div>
							<form onSubmit={handleSubmit(MakeTrabsaction)}>
								<div className="modal-body">
									<div className="col-md-12 row">
										<div className="col-md-4">
											<label>Remaining Balance</label>
											<input
												type="text"
												className="form-control"
												name="remaining_balance"
												id="remaining_balance"
												defaultValue={0}
												readOnly
												{...register("remaining_balance", {
													required: false,
												})}
											/>
										</div>
										<div className="col-md-4 ">
											<label className="form-label">Payment Date</label>
											<input
												type="date"
												className="form-control"
												required
												{...register("payment_date", {
													required: true,
												})}
											/>
											{errors.payment_date && (
												<span className="text-danger">
													This field is required
												</span>
											)}
										</div>
										<div className="col-md-4 ">
											<label className="form-label">Payment method</label>
											<select
												className="form-control"
												required
												{...register("payment_method", {
													required: true,
												})}
											>
												<option value="">Select Payment Method</option>
												<option value="cash">Cash</option>
												<option value="cheque">Cheque</option>
												<option value="bank_transfer">Bank Transfer</option>
											</select>
											{errors.payment_method && (
												<span className="text-danger">
													This field is required
												</span>
											)}
										</div>
										<div className="col-md-4 ">
											<label className="form-label">Amount</label>
											<input
												type="number"
												step="any"
												className="form-control"
												required
												{...register("amount", {
													required: true,
												})}
												max={AmountLimit}
											/>
											<input
												type="hidden"
												className="form-control"
												name="invoice_id"
												id="invoice_id"
												defaultValue={InvoiceID}
												{...register("invoice_id", {
													required: false,
												})}
											/>
											{errors.amount && (
												<span className="text-danger">
													This field is required
												</span>
											)}
										</div>
										<div className="col-md-4">
											<label>Accounts </label>
											<Controller
												name="account_id"
												control={control}
												rules={{ required: false }}
												render={({ field }) => (
													<AsyncSelect
														{...field}
														isClearable
														defaultOptions={accounts}
														loadOptions={(inputValue) =>
															new Promise((resolve) => {
																setTimeout(() => {
																	resolve(
																		accounts.filter((i) =>
																			i.account_name
																				.toLowerCase()
																				.includes(inputValue.toLowerCase())
																		)
																	);
																}, 1000);
															})
														}
														getOptionLabel={(option) => option.account_name}
														getOptionValue={(option) => option.id}
														placeholder="Select Accounts"
														value={accounts.find(
															(obj) => obj.id === field.value
														)}
														onChange={(e) => {
															field.onChange(e);
															accountBalance(e);
														}}
													/>
												)}
											/>
											{AccountValidate && (
												<span className="text-danger">
													This field is required
												</span>
											)}
										</div>
										<div className="col-md-4">
											<label>Available Balance</label>
											<input
												type="text"
												className="form-control"
												name="available-balance"
												id="available-balance"
												defaultValue={0}
												readOnly
												{...register("account_balance", {
													required: false,
												})}
											/>
										</div>
										<div className="col-md-4 ">
											<label className="form-label">Cheque No</label>
											<input
												type="text"
												className="form-control"
												{...register("cheque_no", {
													required: false,
												})}
											/>
										</div>
										<div className="col-md-4 ">
											<label className="form-label">Receipt No </label>
											<input
												type="text"
												className="form-control"
												{...register("receipt_no", {
													required: false,
												})}
											/>
										</div>

										<div className="col-md-4 ">
											<label className="form-label">Notes</label>
											<textarea
												className="form-control"
												name="notes"
												id="notes"
												placeholder="Enter Notes"
												{...register("notes", {
													required: false,
												})}
											/>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-secondary"
										data-bs-dismiss="modal"
										onClick={closeModal}
									>
										Close
									</button>
									<button type="submit" className="btn btn-primary">
										Save changes
									</button>
								</div>
							</form>
						</div>
					</Modal>
					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table className="display" id="purchase-table">
												<thead>
													<tr>
														<th>Sl no</th>
														<th>Supplier Name</th>
														<th>Date</th>

														<th>PO Reference</th>
														<th>Invoice Number</th>
														<th>Delivery </th>
														<th>Quantity</th>
														<th>Type</th>
														<th>Purchase Price</th>
														<th>Sub Total</th>
														<th>Tax Amount</th>
														<th>Discount</th>
														<th>Net Total</th>
														<th>Paid</th>
														<th>Remaining balance</th>
														<th>Attachment One</th>
														<th>Attachment two</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
