import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
	selectVehicleModels,
	selectVehicleBrands,
	selectVehicleColors,
	selectVehicleYears,
	selectVehicleTypes,
	selectvehicelByID,
} from "../../redux/Vehicle";
import {
	fetchVehicleTypes,
	fetchVehicleModels,
	fetchVehicleBrands,
	fetchVehicleColors,
	fetchVehicleYears,
	createVehicle,
	fetchVehicleById,
	updateVehicle,
} from "../../redux/api/apiVehicle";
import { selectCustomers } from "../../redux/Customers";
import { getAllCustomers } from "../../redux/api/apiCustomer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
export default function Addvehicle() {
	const [formData, setFormData] = useState({
		cus_id: "",
		vehicle_name: "",
		vehicle_number: "",
		vehicle_plate_number: "",
		vehicle_vin_or_chasis: "",
		vehicle_type: "",
		vehicle_model: "",
		vehicle_brand: "",
		vehicle_color: "",
		vehicle_year: "",
	});

	const custmerValue = (e) => {
		if (e === null) {
			setFormData({
				...formData,
				cus_id: "",
			});
			return;
		}
		setFormData({
			...formData,
			cus_id: e.id,
		});
	};

	const vehicleTypeValue = (e) => {
		if (e === null) {
			setFormData({
				...formData,
				vehicle_type: "",
			});
			return;
		}
		setFormData({
			...formData,
			vehicle_type: e.id,
		});
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const vehicleTypes = useSelector(selectVehicleTypes);
	const vehicleModels = useSelector(selectVehicleModels);
	const vehicleBrands = useSelector(selectVehicleBrands);
	const vehicleColors = useSelector(selectVehicleColors);
	const vehicleYears = useSelector(selectVehicleYears);
	const customerList = useSelector(selectCustomers);
	useEffect(() => {
		dispatch(fetchVehicleTypes());
		dispatch(fetchVehicleModels());
		dispatch(fetchVehicleBrands());
		dispatch(fetchVehicleColors());
		dispatch(fetchVehicleYears());
		dispatch(getAllCustomers());
	}, [dispatch]);

	const customerLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				customerList.filter((i) =>
					i.cus_name.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};

	const vehicleTypesLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				vehicleTypes.filter((i) =>
					i.type_name.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};

	const vehicleBrandsLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				vehicleBrands.filter((i) =>
					i.brand_name.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};

	const vehicleModelsLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				vehicleModels.filter((i) =>
					i.model_name.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};

	const vehicleColorsLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				vehicleColors.filter((i) =>
					i.color_name.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};

	const vehicleYearsLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				vehicleYears.filter((i) =>
					//  year_name is a number
					i.year_name
						.toString()
						.toLowerCase()
						.includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};

	const saveVehicleData = (data) => {
		dispatch(
			createVehicle(formData, (response) => {
				if (response.status === 200) {
					Swal.fire({
						title: "Success!",
						text: "Vehicle added successfully",
						icon: "success",
						confirmButtonText: "OK",
					}).then((result) => {
						if (result.isConfirmed) {
							navigate("/manage-vehicle");
						}
					});
				} else {
					Swal.fire({
						title: "Error!",
						text: "Vehicle not added",
						icon: "error",
						confirmButtonText: "OK",
					});
				}
			})
		);
	};

	const { id } = useParams();
	// const byVehicleData = useSelector(selectvehicelByID);
	useEffect(() => {
		if (id) {
			dispatch(
				fetchVehicleById(id, (data) => {
					setFormData({
						...formData,
						cus_id: data.cus_id,
						vehicle_name: data.vehicle_name,
						vehicle_number: data.vehicle_number,
						vehicle_plate_number: data.vehicle_plate_number,
						vehicle_vin_or_chasis: data.vehicle_vin_or_chasis,
						vehicle_type: data.vehicle_type ? data.vehicle_type.id : "",
						vehicle_model: data.vehicle_model ? data.vehicle_model.id : "",
						vehicle_brand: data.vehicle_brand ? data.vehicle_brand.id : "",
						vehicle_color: data.vehicle_color ? data.vehicle_color.id : "",
						vehicle_year: data.vehicle_year,
					});
					reset({
						...formData,
						cus_id: data.cus_id,
						vehicle_name: data.vehicle_name,
						vehicle_number: data.vehicle_number,
						vehicle_plate_number: data.vehicle_plate_number,
						vehicle_vin_or_chasis: data.vehicle_vin_or_chasis,
						vehicle_type: data.vehicle_type ? data.vehicle_type.id : "",
						vehicle_model: data.vehicle_model ? data.vehicle_model.id : "",
						vehicle_brand: data.vehicle_brand ? data.vehicle_brand.id : "",
						vehicle_color: data.vehicle_color ? data.vehicle_color.id : "",
						vehicle_year: data.vehicle_year,
					});
				})
			);
		}
	}, [dispatch, id, reset]);
	const updateVehicleData = (data) => {
		// console.log(data);
		dispatch(
			updateVehicle(id, formData, (response) => {
				if (response.status === 200) {
					Swal.fire({
						title: "Success!",
						text: "Vehicle updated successfully",
						icon: "success",
						confirmButtonText: "OK",
					}).then((result) => {
						if (result.isConfirmed) {
							navigate("/manage-vehicle");
						}
					});
				} else {
					Swal.fire({
						title: "Error!",
						text: "Vehicle not updated",
						icon: "error",
						confirmButtonText: "OK",
					});
				}
			})
		);
	};

	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}

	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Vehicles</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="index.html">
												{" "}
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-home"
												>
													<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
													<polyline points="9 22 9 12 15 12 15 22" />
												</svg>
											</a>
										</li>
										<li className="breadcrumb-item">Customer</li>
										<li className="breadcrumb-item active">Vehicles </li>
									</ol>
								</div>
							</div>
						</div>
					</div>
					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="form theme-form">
											<form
												id="addvehicleForm"
												className="theme-form"
												onSubmit={handleSubmit(
													id ? updateVehicleData : saveVehicleData
												)}
											>
												<div className="col-md-12">
													<div className="row">
														<div className="col-md-4 ">
															<div className="form-group">
																<label htmlFor>Customer Name</label>
																<Controller
																	name="cus_id"
																	control={control}
																	rules={{ required: true }}
																	render={({ field }) => (
																		<AsyncSelect
																			{...field}
																			isClearable
																			defaultOptions={customerList}
																			loadOptions={customerLoadOptions}
																			getOptionLabel={(option) =>
																				option.cus_name
																			}
																			getOptionValue={(option) => option.id}
																			placeholder="Select Customer"
																			value={customerList.find(
																				(obj) => obj.id === field.value
																			)}
																			onChange={(e) => {
																				field.onChange(e);
																				custmerValue(e);
																			}}
																		/>
																	)}
																/>

																{errors.cus_id && (
																	<span className="text-danger">
																		This field is required
																	</span>
																)}
															</div>
														</div>
														<div className="col-md-4">
															<div className="mb-3">
																<label>Vehicle Name</label>
																<input
																	type="text"
																	className="form-control"
																	placeholder="Vehicle Name *"
																	{...register("vehicle_name", {
																		required: true,
																	})}
																	autoComplete="off"
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			vehicle_name: e.target.value,
																		});
																	}}
																/>
																{errors.vehicle_name && (
																	<span className="text-danger">
																		This field is required
																	</span>
																)}
															</div>
														</div>
														<div className="col-md-4">
															<div className="mb-3">
																<label>Vehicle Number</label>
																<input
																	type="text"
																	className="form-control"
																	placeholder="Vehicle Number "
																	{...register("vehicle_number", {
																		required: false,
																	})}
																	autoComplete="off"
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			vehicle_number: e.target.value,
																		});
																	}}
																/>
																{errors.vehicle_number && (
																	<span className="text-danger">
																		This field is required
																	</span>
																)}
															</div>
														</div>
														<div className="col-md-4">
															<div className="mb-3">
																<label>Vehicle Plate Number</label>
																<input
																	type="text"
																	className="form-control"
																	name="trn_no"
																	placeholder="Vehicle Plate Number "
																	{...register("vehicle_plate_number", {
																		required: false,
																	})}
																	autoComplete="off"
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			vehicle_plate_number: e.target.value,
																		});
																	}}
																/>
															</div>
														</div>
														<div className="col-md-4">
															<div className="mb-3">
																<label>Vehicle Vin</label>
																<input
																	type="text"
																	className="form-control"
																	name="contact_no_one"
																	placeholder="Vehicle Vin"
																	{...register("vehicle_vin_or_chasis", {
																		required: true,
																	})}
																	autoComplete="off"
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			vehicle_vin_or_chasis: e.target.value,
																		});
																	}}
																/>
																{errors.vehicle_vin_or_chasis && (
																	<span className="text-danger">
																		This field is required
																	</span>
																)}
															</div>
														</div>
														<div className="col-md-4 ">
															<div className="form-group">
																<label htmlFor>Vehicle Type</label>

																<Controller
																	name="vehicle_type"
																	control={control}
																	rules={{ required: false }}
																	render={({ field }) => (
																		<AsyncSelect
																			{...field}
																			isClearable
																			defaultOptions={vehicleTypes}
																			loadOptions={vehicleTypesLoadOptions}
																			getOptionLabel={(option) =>
																				option.type_name
																			}
																			getOptionValue={(option) => option.id}
																			placeholder="Select Vehicle Type"
																			value={vehicleTypes.find(
																				(obj) => obj.id === field.value
																			)}
																			onChange={(e) => {
																				field.onChange(e);
																				vehicleTypeValue(e);
																			}}
																		/>
																	)}
																/>
															</div>
														</div>
														<div className="col-md-4 ">
															<div className="form-group">
																<label htmlFor>Vehicle Brand</label>
																<Controller
																	name="vehicle_brand"
																	control={control}
																	rules={{ required: false }}
																	render={({ field }) => (
																		<AsyncSelect
																			{...field}
																			isClearable
																			defaultOptions={vehicleBrands}
																			loadOptions={vehicleBrandsLoadOptions}
																			getOptionLabel={(option) =>
																				option.brand_name
																			}
																			getOptionValue={(option) => option.id}
																			placeholder="Select Vehicle Brand"
																			value={vehicleBrands.find(
																				(obj) => obj.id === field.value
																			)}
																			onChange={(e) => {
																				field.onChange(e);
																				if (e === null) {
																					setFormData({
																						...formData,
																						vehicle_brand: "",
																					});
																				} else {
																					setFormData({
																						...formData,
																						vehicle_brand: e.id,
																					});
																				}
																			}}
																		/>
																	)}
																/>
															</div>
														</div>
														<div className="col-md-4 ">
															<div className="form-group">
																<label htmlFor>Vehicle Model</label>
																<Controller
																	name="vehicle_model"
																	control={control}
																	rules={{ required: false }}
																	render={({ field }) => (
																		<AsyncSelect
																			{...field}
																			isClearable
																			defaultOptions={vehicleModels}
																			loadOptions={vehicleModelsLoadOptions}
																			getOptionLabel={(option) =>
																				option.model_name
																			}
																			getOptionValue={(option) => option.id}
																			placeholder="Select Vehicle Model"
																			value={vehicleModels.find(
																				(obj) => obj.id === field.value
																			)}
																			onChange={(e) => {
																				field.onChange(e);
																				if (e === null) {
																					setFormData({
																						...formData,
																						vehicle_model: "",
																					});
																				} else {
																					setFormData({
																						...formData,
																						vehicle_model: e.id,
																					});
																				}
																			}}
																		/>
																	)}
																/>
															</div>
														</div>
														<div className="col-md-4 ">
															<div className="form-group">
																<label htmlFor>Vehicle Color</label>
																<Controller
																	name="vehicle_color"
																	control={control}
																	rules={{ required: false }}
																	render={({ field }) => (
																		<AsyncSelect
																			{...field}
																			isClearable
																			defaultOptions={vehicleColors}
																			loadOptions={vehicleColorsLoadOptions}
																			getOptionLabel={(option) =>
																				option.color_name
																			}
																			getOptionValue={(option) => option.id}
																			placeholder="Select Vehicle Color"
																			value={vehicleColors.find(
																				(obj) => obj.id === field.value
																			)}
																			onChange={(e) => {
																				field.onChange(e);
																				if (e === null) {
																					setFormData({
																						...formData,
																						vehicle_color: "",
																					});
																				} else {
																					setFormData({
																						...formData,
																						vehicle_color: e.id,
																					});
																				}
																			}}
																		/>
																	)}
																/>
															</div>
														</div>
														<div className="col-md-4 ">
															<div className="form-group">
																<label htmlFor>Vehicle Year</label>

																<Controller
																	name="vehicle_year"
																	control={control}
																	rules={{ required: false }}
																	render={({ field }) => (
																		<AsyncSelect
																			{...field}
																			isClearable
																			defaultOptions={vehicleYears}
																			loadOptions={vehicleYearsLoadOptions}
																			getOptionLabel={(option) =>
																				option.year_name
																			}
																			getOptionValue={(option) =>
																				option.year_name
																			}
																			placeholder="Select Vehicle Color"
																			value={vehicleYears.find(
																				(obj) => obj.year_name === field.value
																			)}
																			onChange={(e) => {
																				field.onChange(e);
																				if (e === null) {
																					setFormData({
																						...formData,
																						vehicle_year: "",
																					});
																				} else {
																					setFormData({
																						...formData,
																						vehicle_year: e.id,
																					});
																				}
																			}}
																		/>
																	)}
																/>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12">
													<div className="mb-3">
														<button type="submit" className="btn btn-primary">
															Submit
														</button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
