import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
	name: "user",
	initialState: {
		users: [],
		permission: [],
		account_type: "",
		cm_info: [],
	},
	reducers: {
		getUsers: (state, action) => {
			state.users = action.payload;
		},
		getUserPermission: (state, action) => {
			state.permission = action.payload;
		},
		getAccountType: (state, action) => {
			state.account_type = action.payload;
		},
		getCompanyInfo: (state, action) => {
			state.cm_info = action.payload;
		},
	},
});

export const { getUsers, getUserPermission, getAccountType, getCompanyInfo } =
	userSlice.actions;

export const selectUser = (state) => state.user.users;
export const selectUserPermission = (state) => state.user.permission;
export const selectAccountType = (state) => state.user.account_type;
export const selectCompanyInfo = (state) => state.user.cm_info;

export default userSlice.reducer;
