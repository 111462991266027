import axios from "axios";
import {
	selectCustomers,
	setCustomers,
	getPrice,
	getTags,
	getCustomerVehicle,
	getTank,
} from "../Customers";

const apiCustomer = axios.create({
	baseURL: process.env.REACT_APP_BASEURL + "customers",
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",

		"Access-Control-Allow-Origin": "*",
		"X-Server-ID": process.env.REACT_APP_SERVER_ID,
	},
});

apiCustomer.interceptors.request.use((config) => {
	const token = localStorage.getItem("token").replace(/['"]+/g, "");
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	return config;
});

export const insertCustomer = (payload, callback) =>
	apiCustomer
		.post(`/insert`, payload, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		})
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => err.response.data);

export const getAllCustomers = (callback) => (dispatch) =>
	apiCustomer
		.get(`/fetch`)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data.customers);
				}
				dispatch(setCustomers(res.data.customers));
			}
		})
		.catch((err) => err.response.data);

export const updateCustomerById = (id, payload, callback) =>
	apiCustomer
		.post(`/update/${id}`, payload)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => err.response.data);

export const deleteCustomerById = (id, callback) =>
	apiCustomer
		.get(`/delete/${id}`)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => err.response.data);

export const getCustomerById = (id, callback) =>
	apiCustomer
		.get(`/fetch/${id}`)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data.customer);
				}
			} else {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => err.response.data);

// customer price insert

export const customerPriceInsert = (payload, callback) => (dispatch) => {
	apiCustomer.post("/price/insert", payload).then((res) => {
		if (res.status === 200) {
			callback(res.data);
		}
	});
};

//  get customer price

export const getCustomerPrice = (callback) => (dispatch) => {
	apiCustomer.get("/price/fetch").then((res) => {
		// console.log(res);
		if (res.status === 200) {
			dispatch(getPrice(res.data.data));
		}
	});
};

// delete customer price

export const deleteCustomerPrice = (id, callback) =>
	apiCustomer
		.get(`/price/delete/${id}`)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => err.response.data);

//  get customer price by id

export const getCustomerPriceById = (id, callback) =>
	apiCustomer
		.get(`/price/fetch/${id}`)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			} else {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => err.response.data);

//  update customer price by id

export const updateCustomerPriceById = (id, payload, callback) =>
	apiCustomer
		.post(`/price/update/${id}`, payload)
		.then((res) => {
			if (res.data.status === 200) {
				if (callback) {
					callback(res.data);
				}
			}
		})
		.catch((err) => err.response.data);

// get customer tags

export const getCustomerTags = (callback) => (dispatch) => {
	apiCustomer.get("/tags/fetch").then((res) => {
		if (res.status === 200) {
			dispatch(getTags(res.data.data));
		}
	});
};

// get vehicle by customer id

export const getVehicleByCustomerId = (id, callback) => (dispatch) => {
	apiCustomer.get(`/vehicle/by/id/${id}`).then((res) => {
		if (res.status === 200) {
			dispatch(getCustomerVehicle(res.data.data));
		}
	});
};

//  save tags

export const saveCustomerTags = (payload, callback) => (dispatch) => {
	apiCustomer.post("/tags/insert", payload).then((res) => {
		callback(res.data);
	});
};

// delete customer tags

export const deleteCustomerTags = (id, callback) =>
	apiCustomer
		.get(`/delete/tag/${id}`)
		.then((res) => {
			if (callback) {
				callback(res.data);
			}
		})
		.catch((err) => err.response.data);

//  get tags by id

export const getTagsById = (id, callback) =>
	apiCustomer
		.get(`/tags/fetch/${id}`)
		.then((res) => {
			callback(res.data);
		})
		.catch((err) => err.response.data);

//  update tags by id

export const updateTagsById = (id, payload, callback) =>
	apiCustomer
		.post(`/tags/update/${id}`, payload)
		.then((res) => {
			callback(res.data);
		})
		.catch((err) => err.response.data);
// get tanks

export const getTanks = (callback) => (dispatch) => {
	apiCustomer.get("/tanks/fetch").then((res) => {
		dispatch(getTank(res.data));
	});
};

//  get tag info by id from geotek

export const getTagInfoById = (id, callback) =>
	apiCustomer
		.get(`/tags/info/${id}`)
		.then((res) => {
			if (res.data) {
				callback(res.data);
			}
		})
		.catch((err) => (err.response ? err.response.data : err));

// change tag status

export const changeTagStatus = (tagId, syncInfo, callback) =>
	apiCustomer
		.get(`/tags/change/status/${tagId}/${syncInfo}`)
		.then((res) => {
			callback(res.data);
		})
		.catch((err) => err.response.data);
