import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import DataTable from "datatables.net-dt";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
	getAllCustomers,
	deleteCustomerById,
} from "../../redux/api/apiCustomer";
import { selectCustomers } from "../../redux/Customers";
export default function ManageCustomer() {
	const customerList = useSelector(selectCustomers);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getAllCustomers());
	}, []);
	let table = new DataTable("#customer-table");
	useEffect(() => {
		table.destroy();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		table = new DataTable("#customer-table", {
			data: customerList,
			columns: [
				//  auto increment id column
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},
				{ data: "cus_id" },
				{ data: "cus_name" },
				{ data: "cus_email" },
				{ data: "cus_contact_number" },
				{ data: "cus_sale_price" },
				{ data: "cus_credit_price" },
				{ data: "cus_credit_limit" },

				{
					data: "cus_is_tax_applicable",
					render: function (data, type, row) {
						if (data === 1) {
							return "Yes";
						} else {
							return "No";
						}
					},
				},

				{
					data: "id",
					render: function (data, type, row) {
						return `
                <div class="btn-group">
                <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  Action
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#" onClick="editCustomer(${data})">Edit</a></li>
                  <li><a class="dropdown-item" href="#" onClick="deleteCustomer(${data})">Delete</a></li>
                </ul>
              </div>


                `;
					},
				},
			],
		});
	}, [customerList]);

	window.editCustomer = (id) => {
		navigate("/edit-customer/" + id);
	};

	window.deleteCustomer = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You will not be able to recover this Customer Data file!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, delete it!",
			cancelButtonText: "No, keep it",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(
					deleteCustomerById(id, (data) => {
						if (data.status === 200) {
							table.destroy();
							Swal.fire(
								"Deleted!",
								"Your Customer Data file has been deleted.",
								"success"
							);
							dispatch(getAllCustomers());
						}
					})
				);
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire("Cancelled", "Your Customer Data file is safe :)", "error");
			}
		});
	};

	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Manage Customer</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a
												href="index.html"
												data-bs-original-title="data-bs-original-title"
												title="title"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-home"
												>
													<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
													<polyline points="9 22 9 12 15 12 15 22" />
												</svg>
											</a>
										</li>
										<li className="breadcrumb-item">Customer</li>
										<li className="breadcrumb-item active">Manage Customer</li>
									</ol>
								</div>
							</div>
						</div>
					</div>
					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table className="display" id="customer-table">
												<thead>
													<tr>
														<th>Sl no</th>
														<th>Customer ID</th>
														<th>Customer Name</th>
														<th>Email</th>
														<th>Contact Number</th>
														<th>Sale Price</th>
														<th>Credit Price</th>
														<th>Credit Limit</th>

														<th>Tax Applicaple</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
