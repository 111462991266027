import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import DataTable from "datatables.net-dt";
import DatePicker from "react-date-picker";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import {
	getAllSales,
	getSaleById,
	updateTransactionType,
	deleteSaleById,
} from "../../redux/api/apiSale";
import { saleSelector } from "../../redux/Sale";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Modal from "react-modal";
import { useForm, Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { selectCustomers } from "../../redux/Customers";
import { getAllCustomers } from "../../redux/api/apiCustomer";
export default function ManageSale() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	let defaultDate = new Date();
	defaultDate.setDate(defaultDate.getDate());
	// defaultDate to year month date
	defaultDate = defaultDate.toISOString().substr(0, 10);
	const [filterList, setFilterList] = useState({
		datefrom: defaultDate,
		dateto: defaultDate,
		cus_id: "",
	});

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const saleList = useSelector(saleSelector);
	useEffect(() => {
		dispatch(getAllSales(filterList));
		dispatch(getAllCustomers());
	}, [dispatch]);
	const customerList = useSelector(selectCustomers);
	const customerLoadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(
				customerList.filter((i) =>
					i.cus_name.toLowerCase().includes(inputValue.toLowerCase())
				)
			);
		}, 1000);
	};

	const custmerValue = (e) => {
		if (e === null) {
			setFilterList({
				...filterList,
				cus_id: "",
			});
			return;
		}
		setFilterList({
			...filterList,
			cus_id: e.id,
		});
	};
	let table = new DataTable("#sale-table");

	useEffect(() => {
		table.destroy();
		// eslint-disable-next-line react-hooks/exhaustive-deps
		table = new DataTable("#sale-table", {
			data: saleList.data,
			columns: [
				//  auto increment id column
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},
				{
					data: null,
					render: function (data, type, row) {
						if (
							data.payment_type === "cash" ||
							data.payment_type === "credit"
						) {
							return `<label>
							
							Updated to ${data.payment_type}
							</label>
							`;
						}

						return `<input type="checkbox" class="select-checkbox" value="${data.id}">`;
					},
				},
				{
					data: null,
					render: function (data, type, row) {
						if (
							data.payment_type === "credit" ||
							data.payment_type === "cash"
						) {
							return `<label>
							
							Updated to ${data.payment_type}
							</label>
							`;
						} else {
							return `'<input type="checkbox" class="select-checkbox2" value="${data.id}">`;
						}
					},
				},
				// {
				// 	data: null,
				// 	render: function (data, type, row) {
				// 		if (
				// 			data.payment_type === "cash" ||
				// 			data.payment_type === "credit"
				// 		) {
				// 			// return `<button type="button" class="btn btn-primary" onclick="printOnly(${data.id})">Change</button>`;
				// 			return `<button type="button" class="btn btn-primary" >${data.payment_type}</button>`;
				// 		}

				// 		return `<button type="button" class="btn btn-primary" onclick="printSale(${data.id})">Make Action</button>`;
				// 	},
				// },
				{
					data: null,
					render: function (data, type) {
						if (data.customer) {
							return data.customer.cus_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.unit_price) {
							return data.unit_price;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.quantity) {
							return data.quantity;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.amount) {
							return data.amount;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.transaction_begining_time) {
							return data.transaction_begining_time;
						}
						return "";
					},
				},

				{
					data: null,
					render: function (data, type) {
						if (data.vehicle) {
							return data.vehicle.vehicle_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.vehicle) {
							return data.vehicle.vehicle_number;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.vehicle) {
							return data.vehicle.vehicle_plate_number;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.vehicle_code) {
							return data.vehicle_code;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.driver) {
							return data.driver.driver_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.driver_id) {
							return data.driver_id;
						}
						return "";
					},
				},
			],

			//  order by id desc

			lengthMenu: [
				[100, 200, 300, 400, -1],
				["100", "200", "300", "400", "All"],
			],
		});
		// Handler for the "Select All" checkbox for "cash"
		$(document).on("click", "#selectAllcash", function () {
			const cashCheckboxes = $(".select-checkbox");
			const creditCheckboxes = $(".select-checkbox2");

			if ($(this).is(":checked")) {
				// Check the "cash" checkboxes
				cashCheckboxes.prop("checked", true);

				// Uncheck the "credit" checkboxes
				creditCheckboxes.prop("checked", false);
			} else {
				// Uncheck all checkboxes
				cashCheckboxes.prop("checked", false);
			}
		});

		// Handler for the "Select All" checkbox for "credit"
		$(document).on("click", "#selectAllcredit", function () {
			const cashCheckboxes = $(".select-checkbox");
			const creditCheckboxes = $(".select-checkbox2");

			if ($(this).is(":checked")) {
				// Check the "credit" checkboxes
				creditCheckboxes.prop("checked", true);

				// Uncheck the "cash" checkboxes
				cashCheckboxes.prop("checked", false);
			} else {
				// Uncheck all checkboxes
				creditCheckboxes.prop("checked", false);
			}
		});

		// Event handler for table rows
		$(document).on("click", "#sale-table tbody tr", function () {
			const cashCheckbox = $(this).find(".select-checkbox");
			const creditCheckbox = $(this).find(".select-checkbox2");

			if (cashCheckbox.is(":checked") && creditCheckbox.is(":checked")) {
				// Display an error message or perform your desired action
				window.alert(
					"Both sets of checkboxes in the row cannot be checked simultaneously!"
				);
				// You can also uncheck one of the checkboxes in the row here
				cashCheckbox.prop("checked", false);
				// or
				creditCheckbox.prop("checked", false);
			}
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saleList]);

	const getSaleList = () => {
		Swal.fire(
			"Loading...",
			"Please wait while we are fetching the data",
			"info"
		);
		Swal.showLoading();

		dispatch(
			getAllSales(filterList, (res) => {
				if (res.status === 200) {
					Swal.close();
				}
			})
		);
	};

	const [modalIsOpen, setIsOpen] = useState(false);

	function openModal() {
		setIsOpen(true);
	}

	function closeModal() {
		setIsOpen(false);
	}

	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}

	const SaveType = () => {
		const cashCheckboxes = $(".select-checkbox");
		const creditCheckboxes = $(".select-checkbox2");
		var cash = [];
		var credit = [];
		for (var i = 0; i < cashCheckboxes.length; i++) {
			if (cashCheckboxes[i].checked) {
				cash.push(cashCheckboxes[i].value);
			}
		}
		for (var j = 0; j < creditCheckboxes.length; j++) {
			if (creditCheckboxes[j].checked) {
				credit.push(creditCheckboxes[j].value);
			}
		}
		if (cash.length === 0 && credit.length === 0) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please select atleast one checkbox",
			});
			return;
		}

		const data = {
			cash: cash,
			credit: credit,
		};

		dispatch(
			updateTransactionType(data, (res) => {
				if (res.status === 200) {
					Swal.fire({
						icon: "success",
						title: "Success",
						text: res.message,
					});
					getSaleList();
				}
			})
		);
	};

	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />

				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Manage Transaction</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<Link to="#" data-bs-original-title title>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width={24}
													height={24}
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth={2}
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-home"
												>
													<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
													<polyline points="9 22 9 12 15 12 15 22" />
												</svg>
											</Link>
										</li>
										<li className="breadcrumb-item"> Transaction</li>
										<li className="breadcrumb-item active">
											Manage Transaction
										</li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col-md-4 ">
												<div className="form-group">
													<label htmlFor>Customer Name</label>
													<Controller
														name="cus_id"
														control={control}
														rules={{ required: false }}
														render={({ field }) => (
															<AsyncSelect
																{...field}
																isClearable
																defaultOptions={customerList}
																loadOptions={customerLoadOptions}
																getOptionLabel={(option) => option.cus_name}
																getOptionValue={(option) => option.id}
																placeholder="Select Customer"
																value={customerList.find(
																	(obj) => obj.id === field.value
																)}
																onChange={(e) => {
																	field.onChange(e);
																	custmerValue(e);
																}}
															/>
														)}
													/>

													{errors.cus_id && (
														<span className="text-danger">
															This field is required
														</span>
													)}
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group">
													<label htmlFor="vehicle">DateFrom</label>
													<input
														type="date"
														className="form-control"
														id="dateFrom"
														onChange={(e) =>
															setFilterList({
																...filterList,
																datefrom: e.target.value,
															})
														}
														defaultValue={defaultDate}
														value={filterList.datefrom}
													/>
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group">
													<label htmlFor="vehicle">DateTo</label>
													<input
														type="date"
														className="form-control"
														id="dateTo"
														onChange={(e) =>
															setFilterList({
																...filterList,
																dateto: e.target.value,
															})
														}
														defaultValue={defaultDate}
														value={filterList.dateto}
													/>
												</div>
											</div>
											<div className="col-md-4 mt-4">
												<button
													type="button"
													className="btn btn-primary"
													onClick={getSaleList}
												>
													Search
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<button
												type="button"
												className="btn btn-primary"
												onClick={SaveType}
												style={{
													marginBottom: "10px",
												}}
											>
												Save Transaction Type
											</button>

											<table className="display" id="sale-table">
												<thead>
													<tr>
														<th>Sl no</th>
														<th>
															Cash
															<input type="checkbox" id="selectAllcash" />
														</th>
														<th>
															Credit
															<input type="checkbox" id="selectAllcredit" />
														</th>
														{/* <th>Transaction Type</th> */}
														<th>Customer Name</th>
														<th>Price</th>
														<th>Quantity</th>
														<th>Amount</th>
														<th>Transaction Date</th>

														<th>Vehicle Name</th>
														<th>Vehicle Number</th>
														<th>Vehicle Plate Number</th>
														<th>Vehicle Code</th>
														<th>Driver Name</th>
														<th>Driver Code</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
