import React, { useEffect, useState } from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import { Link, Navigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
	getTaxinvoiceById,
	getTransaction,
	updateTaxinvoice,
} from "../../redux/api/apiTaxinvoice";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";

export default function Edit() {
	const { id } = useParams();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const dispatch = useDispatch();
	const [formData, setFormData] = useState({
		cus_id: "",
		datefrom: "",
		dateto: "",
		product_name: "",
		invoice_no: "",
	});
	const update = (data) => {
		// console.log(formData);
		if (formData.product_name === "") {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please Enter product",
			});
			return false;
		}
		if (formData.invoice_no === "") {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Please Enter invoice no",
			});
			return false;
		}

		dispatch(
			updateTaxinvoice(id, formData, (res) => {
				if (res.status === 200) {
					Swal.fire({
						icon: "success",
						title: "Success...",
						text: "Tax Invoice Updated Successfully.",
					});
					Navigate("/taxinvoice");
				}
			})
		);
	};
	useEffect(() => {
		if (id) {
			getTaxinvoiceById(id, (data) => {
				setFormData({
					...formData,
					cus_id: data.data.cus_id,
					datefrom: data.data.datefrom,
					dateto: data.data.dateto,
					product_name: data.data.product_name,
					invoice_no: data.data.invoice_no,
				});

				reset({
					cus_id: data.data.cus_id,
					datefrom: data.data.datefrom,
					dateto: data.data.dateto,
					product_name: data.data.product_name,
					invoice_no: data.data.invoice_no,
				});
				dispatch(
					getTransaction(
						{
							cus_id: data.data.cus_id,
							datefrom: data.data.datefrom,
							dateto: data.data.dateto,
						},
						(res) => {
							if (res.status === 200) {
								// console.log(res.data);

								reset({
									totalqty: res.totalQuantity,
									totalprice: res.totalAmount,
									taxamount: res.totaltaxAmount,
									avgprice: res.avgPrice,
									subtotal: res.totalSubTotal,
								});
							}
						}
					)
				);
			});
		} else {
			reset({
				cus_id: "",
				datefrom: "",
				dateto: "",
				product_name: "",
				invoice_no: "",
			});
		}
	}, [id]);
	return (
		<>
			<div className="page-wrapper compact-wrapper" id="pageWrapper">
				<Header />

				<div className="page-body-wrapper">
					<Sidebar />
					<div className="page-body">
						<div className="container-fluid">
							<div className="page-title">
								<div className="row">
									<div className="col-sm-6">
										<h3>
											{id ? "Update" : "Add"}
											Tax Invoice
										</h3>
									</div>
									<div className="col-sm-6">
										<ol className="breadcrumb">
											<li className="breadcrumb-item">
												<Link to="#">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width={24}
														height={24}
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														strokeWidth={2}
														strokeLinecap="round"
														strokeLinejoin="round"
														className="feather feather-home"
													>
														<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
														<polyline points="9 22 9 12 15 12 15 22" />
													</svg>
												</Link>
											</li>
											<li className="breadcrumb-item">
												{id ? "Update" : "Add"}
											</li>
											<li className="breadcrumb-item active"> Tax Invoice</li>
										</ol>
									</div>
								</div>
							</div>
						</div>

						<div className="container-fluid project-create">
							<div className="row">
								<form
									id="addCustomerForm"
									className="theme-form"
									onSubmit={handleSubmit(id ? update : "")}
								>
									<div className="col-sm-12">
										<div className="card">
											<div className="card-body">
												<div className="form theme-form">
													<div className="col-md-12">
														<div className="row">
															<div className="col-md-4 ">
																<label className>Invoice Number</label>
																<input
																	type="text"
																	className="form-control"
																	name="invoice_no"
																	{...register("invoice_no", {
																		required: true,
																	})}
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			invoice_no: e.target.value,
																		});
																	}}
																/>
																{errors.invoice_no && (
																	<span className="text-danger">
																		This field is required
																	</span>
																)}
															</div>
															<div className="col-md-4 ">
																<label className>Item Name*</label>
																<input
																	type="text"
																	className="form-control"
																	name="product_name"
																	{...register("product_name", {
																		required: true,
																	})}
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			product_name: e.target.value,
																		});
																	}}
																/>
																{errors.product_name && (
																	<span className="text-danger">
																		This field is required
																	</span>
																)}
															</div>
														</div>
													</div>
													<div className="col-md-12">
														<div className="mb-3">
															<button type="submit" className="btn btn-primary">
																Submit
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</>
	);
}
