import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { Link, useNavigate, useParams } from "react-router-dom";
import { supplierSelector } from "../../redux/Supplier";
import { getSupplierList } from "../../redux/api/apiSupplier";
import {
	getPurchaseById,
	insertPurchase,
	updatePurchase,
} from "../../redux/api/apiPurchase";
export default function AddPurchase() {
	const { id } = useParams();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const dispatch = useDispatch();
	const supplierList = useSelector(supplierSelector);
	useEffect(() => {
		dispatch(getSupplierList());
	}, [dispatch]);
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		sp_id: "",
		date: "",
		product_name: "",
		quantity: "",
		purchase_price: "",
		item_type: "Liter",
		tax_amount: "",
		sub_total: "",
		po_reference: "",
		net_total: "",
		discount: "",
		document_1: "",
		document_2: "",
		invoice_number: "",
		delivery_at: "",
	});
	const savePurchaseData = (data) => {
		console.log(data);
		const formData2 = new FormData();
		if (data.document_1[0]) {
			formData2.append(
				"document_1",
				data.document_1[0] ? data.document_1[0] : ""
			);
		}
		if (data.document_2[0]) {
			formData2.append(
				"document_2",
				data.document_2[0] ? data.document_2[0] : ""
			);
		}
		formData2.append("sp_id", data.sp_id.id);
		formData2.append("date", data.date);
		formData2.append("product_name", data.product_name);
		formData2.append("quantity", data.quantity);
		formData2.append("purchase_price", data.purchase_price);
		formData2.append("item_type", data.item_type);
		formData2.append("tax_amount", data.tax_amount);
		formData2.append("sub_total", formData.sub_total);
		formData2.append("po_reference", data.po_reference);
		formData2.append("net_total", formData.net_total);
		formData2.append("discount", data.discount ? data.discount : 0);
		formData2.append("invoice_number", data.invoice_number);
		formData2.append("delivery_at", data.delivery_at);
		// console.log(formData2);
		dispatch(
			insertPurchase(formData2, (response) => {
				if (response.status === 200) {
					Swal.close();
					Swal.fire({
						icon: "success",
						title: response.message,
						showConfirmButton: true,
						timer: 2500,
					});
					reset({
						sp_id: "",
						date: "",
						product_name: "",
						quantity: "",
						purchase_price: "",
						item_type: "Liter",
						tax_amount: "",
						sub_total: "",
						po_reference: "",
						net_total: "",
						discount: "",
						document_1: "",
						document_2: "",
						invoice_number: "",
						delivery_at: "",
					});

					navigate("/purchase");
				} else {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "Something went wrong!",
					});
				}
			})
		);
	};

	useEffect(() => {
		if (id) {
			Swal.fire({
				title: "Please wait...",
				html: "Saving Account",
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
			getPurchaseById(id, (data) => {
				reset({
					sp_id: data.sp_id,
					date: data.date,
					product_name: data.product_name,
					quantity: data.quantity,
					purchase_price: data.purchase_price,
					item_type: data.item_type,
					tax_amount: data.tax_amount,
					sub_total: data.sub_total,
					po_reference: data.po_reference,
					net_total: data.net_total,
					discount: data.discount,
					invoice_number: data.invoice_number,
					delivery_at: data.delivery_at,
				});

				setFormData({
					sp_id: data.sp_id,
					date: data.date,
					product_name: data.product_name,
					quantity: data.quantity,
					purchase_price: data.purchase_price,
					item_type: data.item_type,
					tax_amount: data.tax_amount,
					sub_total: data.sub_total,
					po_reference: data.po_reference,
					net_total: data.net_total,
					discount: data.discount,
					invoice_number: data.invoice_number,
					delivery_at: data.delivery_at,
				});

				setByQuantity(data.quantity);
				setByPurchasePrice(data.purchase_price);
				setByTaxAmount(data.tax_amount);
				setByDiscount(data.discount);
				setBySubTotal(data.sub_total);
				setByNetTotal(data.net_total);
				Swal.close();
			});
		} else {
			reset({
				sp_id: "",
				date: "",
				product_name: "",
				quantity: "",
				purchase_price: "",
				item_type: "Liter",
				tax_amount: "",
				sub_total: "",
				po_reference: "",
				net_total: "",
				discount: "",
			});
		}
	}, [id, reset]);

	const updatePurchaseData = (data) => {
		// console.log(data);
		// console.log(BySubTotal);
		const formData2 = new FormData();
		if (data.document_1[0]) {
			formData2.append(
				"document_1",
				data.document_1[0] ? data.document_1[0] : ""
			);
		}
		if (data.document_2[0]) {
			formData2.append(
				"document_2",
				data.document_2[0] ? data.document_2[0] : ""
			);
		}

		formData2.append("sp_id", formData.sp_id);
		formData2.append("date", data.date);
		formData2.append("product_name", data.product_name);
		formData2.append("quantity", ByQuantity);
		formData2.append("purchase_price", ByPurchasePrice);
		formData2.append("item_type", data.item_type);
		formData2.append("tax_amount", ByTaxAmount);
		formData2.append("sub_total", BySubTotal);
		formData2.append("po_reference", data.po_reference);
		formData2.append("net_total", ByNetTotal);
		formData2.append("discount", ByDiscount);
		formData2.append("invoice_number", data.invoice_number);
		formData2.append("delivery_at", data.delivery_at);
		dispatch(
			updatePurchase(id, formData2, (res) => {
				if (res === 200) {
					Swal.fire({
						icon: "success",
						title: "Success",
						text: res.message,
					});
					reset({
						sp_id: "",
						date: "",
						product_name: "",
						quantity: "",
						purchase_price: "",
						item_type: "Liter",
						tax_amount: "",
						sub_total: "",
						po_reference: "",
						net_total: "",
						discount: "",
						document_1: "",
						document_2: "",
						invoice_number: "",
						delivery_at: "",
					});

					navigate("/purchase");
				}
			})
		);
	};

	const [ByQuantity, setByQuantity] = useState(0);
	const [ByPurchasePrice, setByPurchasePrice] = useState(0);
	const [ByTaxAmount, setByTaxAmount] = useState(0);
	const [ByDiscount, setByDiscount] = useState(0);
	const [BySubTotal, setBySubTotal] = useState(0);
	const [ByNetTotal, setByNetTotal] = useState(0);

	const calculateByQuantity = (e) => {
		setByQuantity(e.target.value);
		var val = e.target.value * parseFloat(ByPurchasePrice);
		// val = val + parseFloat(ByTaxAmount);
		// val = val + parseFloat(ByTaxAmount);

		setBySubTotal(val);
		setByNetTotal(val - parseFloat(ByDiscount));
		setFormData({
			...formData,
			sub_total: BySubTotal,
			tax_amount: ByTaxAmount,
			net_total: val - parseFloat(ByDiscount),
			quantity: e.target.value,
			discount: ByDiscount,
		});

		// setFormData({
		// 	...formData,
		// 	sub_total: val,
		// 	sub_total2: val,
		// 	net_total: val - parseFloat(ByDiscount),
		// 	quantity: e.target.value,
		// 	discount: ByDiscount,

		// });
	};

	const calculateByPurchasePrice = (e) => {
		setByPurchasePrice(e.target.value);
		var val2 = e.target.value * parseFloat(ByQuantity);
		setBySubTotal(val2);
		val2 = val2 + parseFloat(ByTaxAmount);

		setByNetTotal(val2 - parseFloat(ByDiscount));
		setFormData({
			...formData,
			sub_total: val2,
			tax_amount: ByTaxAmount,
			net_total: val2 - parseFloat(ByDiscount),
			purchase_price: e.target.value,
			discount: ByDiscount,
			quantity: ByQuantity,
		});

		// setFormData({
		// 	...formData,
		// 	sub_total: val2,
		// 	sub_total2: val2,
		// 	net_total: val2 - parseFloat(ByDiscount),

		// 	purchase_price: e.target.value,
		// 	discount: ByDiscount,
		// });
	};

	const calculateByTaxAmount = (e) => {
		setByTaxAmount(e.target.value);

		var val3 = parseFloat(ByQuantity) * parseFloat(ByPurchasePrice);
		setBySubTotal(val3);
		val3 = val3 + parseFloat(e.target.value);

		setByNetTotal(val3 - parseFloat(ByDiscount));
		setFormData({
			sub_total: BySubTotal,
			net_total: val3 - parseFloat(ByDiscount),
			tax_amount: e.target.value,
			discount: ByDiscount,
			quantity: ByQuantity,
		});
		// setFormData({
		// 	...formData,
		// 	sub_total: val3,
		// 	sub_total2: val3,
		// 	net_total: val3 - parseFloat(ByDiscount),
		// 	tax_amount: e.target.value,
		// 	tax_amount2: e.target.value,
		// 	discount: ByDiscount,
		// });
	};

	const calculateByDiscount = (e) => {
		setByDiscount(e.target.value);

		var val4 = parseFloat(ByQuantity) * parseFloat(ByPurchasePrice);
		setBySubTotal(val4);
		val4 = val4 + parseFloat(ByTaxAmount);

		setByNetTotal(val4 - parseFloat(e.target.value));
		setFormData({
			...formData,
			sub_total: BySubTotal,
			tax_amount: ByTaxAmount,
			net_total: val4 - parseFloat(e.target.value),
			discount: e.target.value,
			quantity: ByQuantity,
		});

		// setFormData({
		// 	...formData,
		// 	sub_total: val4,
		// 	sub_total2: val4,
		// 	net_total: val4 - parseFloat(e.target.value),
		// 	discount: e.target.value,
		// });
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<>
			<div className="page-wrapper compact-wrapper" id="pageWrapper">
				<Header />

				<div className="page-body-wrapper">
					<Sidebar />
					<div className="page-body">
						<div className="container-fluid">
							<div className="page-title">
								<div className="row">
									<div className="col-sm-6">
										<h3>
											{id ? "Update " : "Add"}
											Purchase
										</h3>
									</div>
									<div className="col-sm-6">
										<ol className="breadcrumb">
											<li className="breadcrumb-item">
												<Link to="#">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width={24}
														height={24}
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														strokeWidth={2}
														strokeLinecap="round"
														strokeLinejoin="round"
														className="feather feather-home"
													>
														<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
														<polyline points="9 22 9 12 15 12 15 22" />
													</svg>
												</Link>
											</li>
											<li className="breadcrumb-item">
												{id ? "Update" : "Add"}
											</li>
											<li className="breadcrumb-item active">Purchase</li>
										</ol>
									</div>
								</div>
							</div>
						</div>

						<div className="container-fluid project-create">
							<div className="row">
								<form
									id="addCustomerForm"
									className="theme-form"
									onSubmit={handleSubmit(
										id ? updatePurchaseData : savePurchaseData
									)}
								>
									<div className="col-sm-12">
										<div className="card">
											<div className="card-body">
												<div className="form theme-form">
													<div className="col-md-12">
														<div className="row">
															<div className="col-md-6  mt-2">
																<div className="form-group">
																	<label htmlFor="sp_id">Supplier Name *</label>
																	<Controller
																		name="sp_id"
																		control={control}
																		rules={{ required: false }}
																		render={({ field }) => (
																			<AsyncSelect
																				{...field}
																				isClearable
																				defaultOptions={supplierList}
																				loadOptions={(inputValue) =>
																					new Promise((resolve) => {
																						setTimeout(() => {
																							resolve(
																								supplierList.filter((i) =>
																									i.sp_name
																										.toLowerCase()
																										.includes(
																											inputValue.toLowerCase()
																										)
																								)
																							);
																						}, 1000);
																					})
																				}
																				getOptionLabel={(option) =>
																					option.sp_name
																				}
																				getOptionValue={(option) => option.id}
																				placeholder="Select Supplier"
																				value={supplierList.find(
																					(obj) => obj.id === field.value
																				)}
																				onChange={(e) => {
																					field.onChange(e);
																					setFormData({
																						...formData,
																						sp_id: e.id,
																					});
																				}}
																			/>
																		)}
																	/>
																	{errors.sp_id && (
																		<span className="text-danger">
																			This field is required
																		</span>
																	)}
																</div>
															</div>
															<div className="col-md-6 ">
																<label className>Purchase Date*</label>
																<input
																	type="date"
																	className="form-control"
																	name="date"
																	{...register("date", { required: true })}
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			date: e.target.value,
																		});
																	}}
																/>
																{errors.date && (
																	<span className="text-danger">
																		This field is required
																	</span>
																)}
															</div>
															<div className="col-md-4 ">
																<label className="form-label">
																	PO Reference
																</label>
																<input
																	type="text"
																	className="form-control"
																	name="po_reference"
																	{...register("po_reference", {
																		required: false,
																	})}
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			po_reference: e.target.value,
																		});
																	}}
																/>
															</div>
															<div className="col-md-4 ">
																<label className="form-label">
																	Invoice Number
																</label>
																<input
																	type="text"
																	className="form-control"
																	name="invoice_number"
																	{...register("invoice_number", {
																		required: false,
																	})}
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			invoice_number: e.target.value,
																		});
																	}}
																/>
															</div>
															<div className="col-md-4 ">
																<label className="form-label">Delivery</label>
																<input
																	type="text"
																	className="form-control"
																	name="delivery_at"
																	{...register("delivery_at", {
																		required: false,
																	})}
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			delivery_at: e.target.value,
																		});
																	}}
																/>
															</div>
															<div
																className="col-md-12 "
																style={{ padding: 10 }}
															>
																<div className="form-group">
																	<h4 className="headstyle">
																		PURCHASE ORDERS LIST
																	</h4>
																</div>
															</div>
															<div
																className="col-md-12 "
																style={{ padding: 20 }}
															>
																<div className="d-flex align-items-center justify-content-center">
																	<div className="table-responsive">
																		<table className="table align-middle mb-0">
																			<thead className="table-light">
																				<tr>
																					<th>Product Name</th>
																					<th>Quantity</th>

																					<th>Type</th>
																					<th>Purchase Price</th>
																					<th>Sub Total</th>
																					<th>Tax Amount</th>
																				</tr>
																			</thead>
																			<tbody>
																				<tr>
																					<td>
																						<input
																							type="text"
																							className="form-control"
																							name="product_name"
																							{...register("product_name", {
																								required: true,
																							})}
																							onChange={(e) => {
																								setFormData({
																									...formData,
																									product_name: e.target.value,
																								});
																							}}
																						/>
																						{errors.product_name && (
																							<span className="text-danger">
																								This field is required
																							</span>
																						)}
																					</td>
																					<td>
																						<input
																							type="number"
																							step="any"
																							className="form-control"
																							name="quantity"
																							{...register("quantity", {
																								required: true,
																							})}
																							onChange={(e) => {
																								setFormData({
																									...formData,
																									quantity: e.target.value,
																								});

																								calculateByQuantity(e);
																							}}
																							value={ByQuantity}
																						/>
																						{errors.quantity && (
																							<span className="text-danger">
																								This field is required
																							</span>
																						)}
																					</td>
																					<td
																						style={{
																							width: 150,
																						}}
																					>
																						<select
																							className="form-control"
																							name="item_type"
																							id="item_type"
																							{...register("item_type", {})}
																							onChange={(e) => {
																								setFormData({
																									...formData,
																									item_type: e.target.value,
																								});
																							}}
																						>
																							<option value="Liter">
																								Liter
																							</option>
																							<option value="Gallon">
																								Gallon
																							</option>
																						</select>
																					</td>
																					<td>
																						<input
																							type="number"
																							step="any"
																							className="form-control"
																							name="purchase_price"
																							{...register("purchase_price", {
																								required: true,
																							})}
																							onChange={(e) => {
																								setFormData({
																									...formData,
																									purchase_price:
																										e.target.value,
																								});
																								calculateByPurchasePrice(e);
																							}}
																							value={ByPurchasePrice}
																						/>
																						{errors.purchase_price && (
																							<span className="text-danger">
																								This field is required
																							</span>
																						)}
																					</td>

																					<td>
																						<input
																							type="text"
																							className="form-control"
																							defaultValue={0}
																							name="sub_total"
																							{...register("sub_total", {
																								required: false,
																							})}
																							onChange={(e) => {
																								setFormData({
																									...formData,
																									sub_total: BySubTotal,
																								});
																							}}
																							value={BySubTotal}
																							disabled
																						/>
																					</td>
																					<td>
																						<input
																							type="number"
																							step="any"
																							className="form-control"
																							name="tax_amount"
																							{...register("tax_amount", {
																								required: false,
																							})}
																							onChange={(e) => {
																								setFormData({
																									...formData,
																									tax_amount: e.target.value,
																								});
																								calculateByTaxAmount(e);
																							}}
																							value={ByTaxAmount}
																						/>
																					</td>
																				</tr>
																			</tbody>
																		</table>
																	</div>
																</div>
															</div>

															<div className="col-md-4 ">
																<label className="form-label">Discount</label>
																<input
																	type="text"
																	className="form-control"
																	defaultValue={0}
																	name="discount"
																	{...register("discount", {
																		required: false,
																	})}
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			discount: e.target.value,
																		});
																		calculateByDiscount(e);
																	}}
																	value={ByDiscount}
																/>
															</div>
															<div className="col-md-4 ">
																<label className="form-label">Net Total</label>
																<input
																	type="text"
																	className="form-control"
																	defaultValue={0}
																	name="net_total"
																	{...register("net_total", {
																		required: false,
																	})}
																	onChange={(e) => {
																		setFormData({
																			...formData,
																			net_total: e.target.value,
																		});
																	}}
																	value={ByNetTotal}
																	disabled
																/>
															</div>
															<div className="col-md-4 ">
																<label className="form-label">
																	Attachment One
																</label>
																<input
																	type="file"
																	className="form-control"
																	name="document_1"
																	{...register("document_1", {
																		required: false,
																	})}
																/>
															</div>
															<div className="col-md-4 ">
																<label className="form-label">
																	Attachment Two
																</label>
																<input
																	type="file"
																	className="form-control"
																	name="document_2"
																	{...register("document_2", {
																		required: false,
																	})}
																/>
															</div>
														</div>
													</div>
													<div className="col-md-12" style={{ padding: 10 }}>
														<div className="mb-3">
															<button type="submit" className="btn btn-primary">
																Submit
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</>
	);
}
