import axios from "axios";

import Swal from "sweetalert2";
import {
	getDuePayment,
	getPayment,
	getExpense,
	getLedger,
	getCashFlow,
	getPurchasePayment,
	getTransactions,
	getLedgerCustomer,
} from "../Reports";

const apiReports = axios.create({
	baseURL: process.env.REACT_APP_BASEURL + "reports",
	headers: {
		Accept: "application/pdf",

		"Access-Control-Allow-Origin": "*",
		"X-Server-ID": process.env.REACT_APP_SERVER_ID,
	},
});

apiReports.interceptors.request.use((config) => {
	const token = localStorage.getItem("token").replace(/['"]+/g, "");
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	return config;
});

//  pdf transaction
export const pdfTransaction = (payload, callback) =>
	apiReports
		.post(`/transaction/pdf`, payload, {
			headers: {
				"Content-Type": "application/pdf",
			},
		})
		.then((res) => {
			// console.log(res);

			callback(res);
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

// get balance sheet
export const getBalanceSheet = (payload, callback) =>
	apiReports
		.post(`/balance/sheet`, payload)
		.then((res) => {
			// console.log(res);

			callback(res);
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

// get profit and loss
export const getProfitAndLoss = (payload, callback) =>
	apiReports
		.post(`/profit/and/loss`, payload)
		.then((res) => {
			// console.log(res);

			callback(res);
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});
// get expense report
export const getExpenseReport = (payload, callback) =>
	apiReports
		.post(`/expense/report`, payload)
		.then((res) => {
			// console.log(res);

			callback(res);
		})
		.catch((err) => {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err.response.data.message,
			});
		});

// due payment report
export const getDuePaymentReport = (payload, callback) => (dispatch) =>
	apiReports.post(`/due/payment`, payload).then((res) => {
		// console.log(res.data);

		if (callback) {
			callback(res.data);
		}

		dispatch(getDuePayment(res.data.data));
	});

// get payment report

export const getPaymentReport = (payload, callback) => (dispatch) =>
	apiReports.post(`/payment`, payload).then((res) => {
		// console.log(res.data);

		if (callback) {
			callback(res.data);
		}

		dispatch(getPayment(res.data.data));
	});

// get expense report

export const getExpenseReportList = (payload, callback) => (dispatch) =>
	apiReports.post(`/expense`, payload).then((res) => {
		// console.log(res.data);

		if (callback) {
			callback(res.data);
		}

		dispatch(getExpense(res.data.data));
	});

// get ledger report

export const getLedgerReport = (payload, callback) => (dispatch) =>
	apiReports.post(`/ledger`, payload).then((res) => {
		// console.log(res.data.data);

		if (callback) {
			callback(res.data);
		}

		dispatch(getLedger(res.data.data));
		// dispatch(getLedgerCustomer(res.data.customer_info));
	});

// cash flow report

export const getCashFlowReport = (payload, callback) => (dispatch) =>
	apiReports.post(`/cashflow`, payload).then((res) => {
		// console.log(res.data.data);

		if (callback) {
			callback(res.data);
		}

		dispatch(getCashFlow(res.data.data));
	});

// get purchase payment report

export const getPurchasePaymentReport = (payload, callback) => (dispatch) =>
	apiReports.post(`/purchase/payment`, payload).then((res) => {
		// console.log(res.data);

		if (callback) {
			callback(res.data);
		}

		dispatch(getPurchasePayment(res.data.data));
	});

// get transactions report

export const getTransactionsReport = (payload, callback) => (dispatch) =>
	apiReports.post(`/transactions`, payload).then((res) => {
		// console.log(res.data);
		if (res.data.status === 200) {
			if (callback) {
				callback(res.data);
			}
			// console.log(res.data.data);
			dispatch(getTransactions(res.data));
		}
	});

// get image

export const getImage = (payload, callback) => (dispatch) =>
	apiReports.get(`/get/image`, payload).then((res) => {
		if (callback) {
			callback(res.data.data);
		}
	});
