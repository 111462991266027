import React from "react";
import Footer from "../dashboard/components/Footer";
import Header from "../dashboard/components/Header";
import Sidebar from "../dashboard/components/Sidebar";
import DataTable from "datatables.net-dt";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import {
	accountsSelector,
	getTransfer,
	transferSelector,
} from "../../redux/Accounts";
import {
	getAccount,
	getAccountTransfer,
	insertAccountTransfer,
	deleteAccountTransferByID,
	getAccountTransferById,
	updateAccountTransferByID,
	getAccountBalanceById,
} from "../../redux/api/apiAccounts";

export default function AccountTransfer() {
	const dispatch = useDispatch();
	const [modalIsOpen, setIsOpen] = useState(false);
	const accounts = useSelector(accountsSelector);
	const accountTransferList = useSelector(transferSelector);
	useEffect(() => {
		dispatch(getAccount());
		dispatch(getAccountTransfer());
	}, []);
	const [editID, setEditID] = useState("");
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	function closeModal() {
		setIsOpen(false);
		reset();
		setEditID("");
	}
	function openModal() {
		setIsOpen(true);
		reset({});
	}
	const addTransfer = (data) => {
		const formData = new FormData();
		formData.append("from_account_id", data.from_account_id);
		formData.append("to_account_id", data.to_account_id.id);
		formData.append("amount", data.amount);
		formData.append("notes", data.notes);
		formData.append("date", data.date);
		// console.log(formData);
		closeModal();
		reset();
		Swal.fire({
			title: "Please wait...",
			html: "Saving data",
			allowOutsideClick: false,
			onBeforeOpen: () => {
				Swal.showLoading();
			},
		});
		dispatch(
			insertAccountTransfer(formData, (res) => {
				if (res.status === 200) {
					Swal.fire({
						icon: "success",
						title: "Success",
						text: res.message,
					});
					dispatch(getAccountTransfer());
				}
			})
		);
	};

	const updateTransfer = (data) => {
		// console.log(data);
		const formData = new FormData();
		formData.append("from_account_id", data.from_account_id);
		formData.append("to_account_id", data.to_account_id);
		formData.append("amount", data.amount);
		formData.append("notes", data.notes);
		formData.append("date", data.date);
		closeModal();
		reset();
		Swal.fire({
			title: "Please wait...",
			html: "Saving data",
			allowOutsideClick: false,
			onBeforeOpen: () => {
				Swal.showLoading();
			},
		});
		dispatch(
			updateAccountTransferByID(editID, formData, (res) => {
				if (res.status === 200) {
					Swal.fire({
						icon: "success",
						title: "Success",
						text: res.message,
					});
					dispatch(getAccountTransfer());
				}
			})
		);
	};
	const [amountValidation, setAmountValidation] = useState("");
	const [formData, setFormData] = useState({
		from_account_id: "",
		to_account_id: 0,
		account_balance: 0,
		amount: "",
		date: "",
		notes: "",
	});
	const accountBalance = (e) => {
		if (e === null) {
			setFormData({
				...formData,
				from_account_id: "",
				to_account_id: 0,
				account_balance: "",
				amount: 0.0,
				date: "",
			});
			reset({
				...formData,
				from_account_id: "",
				to_account_id: 0,
				account_balance: 0.0,
				amount: 0.0,
				date: "",
			});
			return;
		}
		setFormData({
			...formData,
			from_account_id: e.id,
			to_account_id: 0,
			amount: 0.0,
			date: "",
		});
		dispatch(
			getAccountBalanceById(e.id, (res) => {
				if (res.status === 200) {
					reset({
						...formData,
						from_account_id: e.id,
						to_account_id: 0,
						account_balance: res.data.account_balance,
						amount: 0.0,
						date: "",
					});
				}
			})
		);
	};
	const setvalueoffromaccount = (e) => {
		if (e === null) {
			setFormData({
				...formData,

				to_account_id: 0,
			});
		}

		setFormData({
			...formData,
			to_account_id: e.id,
		});
	};

	let table = new DataTable("#transfer-table");
	useEffect(() => {
		table.destroy();
		table = new DataTable("#transfer-table", {
			data: accountTransferList,
			columns: [
				{
					data: null,
					render: function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.from_account.account_name) {
							return data.from_account.account_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.to_account.account_name) {
							return data.to_account.account_name;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.amount) {
							return data.amount;
						}
						return "";
					},
				},
				{
					data: null,
					render: function (data, type) {
						if (data.date) {
							return data.date;
						}
						return "";
					},
				},
				{
					data: "id",
					render: function (data, type, row) {
						return `
                <div class="btn-group">
                <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  Action
                </button>
                <ul class="dropdown-menu">
                     <li><a class="dropdown-item" href="#"  onclick="editTransfer(${data})">Edit</a></li>
                        <li><a class="dropdown-item" href="#"  onclick="deleteTransfer(${data})">Delete</a></li>
                </ul>
                </div>
                `;
					},
				},
			],
		});
	}, [accountTransferList]);

	window.deleteTransfer = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(
					deleteAccountTransferByID(id, (res) => {
						if (res.status === 200) {
							Swal.fire({
								icon: "success",
								title: "Success",
								text: res.message,
							});
							dispatch(getAccountTransfer());
						}
					})
				);
			} else {
				Swal.fire({
					icon: "error",
					title: "Cancelled",
					text: "Your imaginary file is safe :)",
				});
			}
		});
	};

	window.editTransfer = (id) => {
		setEditID(id);
		setIsOpen(true);
		dispatch(
			getAccountTransferById(id, (res) => {
				if (res.status === 200) {
					accountBalance(res.data.from_account);
					setFormData({
						...formData,
						from_account_id: res.data.from_account_id,
						to_account_id: res.data.to_account_id,
						amount: res.data.amount,
						date: res.data.date,
						notes: res.data.notes,
					});
					reset({
						from_account_id: res.data.from_account_id,
						to_account_id: res.data.to_account_id,
						amount: res.data.amount,
						date: res.data.date,
						notes: res.data.notes,
						to_account_name: res.data.to_account.account_name,
						from_account_name: res.data.from_account.account_name,
						account_balance: res.data.from_account.account_balance,
					});
					setAmountValidation(res.data.amount);
				}
			})
		);
	};
	var accountType = localStorage.getItem("account_type");
	if (accountType === null) {
		accountType = "";
	} else {
		accountType = accountType.replace(/['"]+/g, "");
	}
	if (accountType === "customer") {
		window.location.href = "/dashboard";
	}
	return (
		<div className="page-wrapper compact-wrapper" id="pageWrapper">
			<Header />

			<div className="page-body-wrapper">
				<Sidebar />
				<div className="page-body">
					<div className="container-fluid">
						<div className="page-title">
							<div className="row">
								<div className="col-sm-6">
									<h3>Manage Transfer</h3>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="index.html">
												<i data-feather="home" />
											</a>
										</li>
										<li className="breadcrumb-item">Manage</li>
										<li className="breadcrumb-item active"> Transfer </li>
									</ol>
								</div>
							</div>
						</div>
					</div>

					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						contentLabel="Example Modal"
						className="modal-dialog modal-dialog-centered"
						closeTimeoutMS={500}
						style={{
							//  width 1200px
							overlay: {
								backgroundColor: "rgba(0, 0, 0, 0.5)",
								zIndex: 9999,
							},
						}}
					>
						<div
							className="modal-content"
							style={{
								width: "1200px",
								margin: "0 auto",
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title">
									{editID ? "Edit Transfer" : "Add Transfer"}
								</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
									onClick={closeModal}
								/>
							</div>
							<form
								onSubmit={handleSubmit(editID ? updateTransfer : addTransfer)}
							>
								<div className="modal-body">
									<div className="col-md-12 row">
										{editID ? (
											<div className="col-md-6">
												<label>From Account </label>
												<input
													type="text"
													className="form-control"
													disabled
													{...register("from_account_name", {
														required: false,
													})}
												/>
											</div>
										) : (
											<div className="col-md-6">
												<label>From Accounts </label>
												<Controller
													name="from_account_id"
													control={control}
													rules={{ required: false }}
													render={({ field }) => (
														<AsyncSelect
															{...field}
															isClearable
															defaultOptions={accounts}
															loadOptions={(inputValue) =>
																new Promise((resolve) => {
																	setTimeout(() => {
																		resolve(
																			accounts.filter((i) =>
																				i.account_name
																					.toLowerCase()
																					.includes(inputValue.toLowerCase())
																			)
																		);
																	}, 1000);
																})
															}
															getOptionLabel={(option) => option.account_name}
															getOptionValue={(option) => option.id}
															placeholder="Select Accounts"
															value={accounts.find(
																(obj) => obj.id === field.value
															)}
															onChange={(e) => {
																field.onChange(e);
																accountBalance(e);
																// setFormData({ ...formData, account_id: e.id });
															}}
														/>
													)}
												/>
												{errors.account_id && (
													<span className="text-danger">
														This field is required
													</span>
												)}
											</div>
										)}

										{editID ? (
											<div className="col-md-6">
												<label>To Accounts </label>
												<input
													type="text"
													className="form-control"
													disabled
													{...register("to_account_name", {
														required: false,
													})}
												/>
											</div>
										) : (
											<div className="col-md-6">
												<label>To Accounts </label>
												<Controller
													name="to_account_id"
													control={control}
													rules={{ required: false }}
													render={({ field }) => (
														<AsyncSelect
															{...field}
															isClearable
															defaultOptions={accounts}
															loadOptions={(inputValue) =>
																new Promise((resolve) => {
																	setTimeout(() => {
																		resolve(
																			accounts.filter((i) =>
																				i.account_name
																					.toLowerCase()
																					.includes(inputValue.toLowerCase())
																			)
																		);
																	}, 1000);
																})
															}
															getOptionLabel={(option) => option.account_name}
															getOptionValue={(option) => option.id}
															placeholder="Select Accounts"
															value={accounts.find(
																(obj) => obj.id === field.value
															)}
															onChange={(e) => {
																field.onChange(e);
																setvalueoffromaccount(e);
															}}
														/>
													)}
												/>
												{errors.account_id && (
													<span className="text-danger">
														This field is required
													</span>
												)}
											</div>
										)}

										<div className="col-md-6">
											<label>Available Balance</label>
											<input
												type="text"
												className="form-control"
												name="available-balance"
												id="available-balance"
												defaultValue={0}
												readOnly
												{...register("account_balance", { required: false })}
											/>
										</div>
										<div className="col-md-6">
											<label>Amount </label>
											<input
												type="number"
												className="form-control"
												name="amount"
												id="amount"
												placeholder="Enter Amount"
												{...register("amount", {
													required: true,
												})}
												step="any"
												onChange={(e) => {
													setFormData({ ...formData, amount: e.target.value });
												}}
												min={editID ? amountValidation : 0}
											/>
											{errors.amount && (
												<span className="text-danger">
													This field is required
												</span>
											)}
										</div>
										<div className="col-md-6">
											<label>Date</label>
											<input
												type="date"
												className="form-control"
												name="date"
												id="date"
												{...register("date", { required: true })}
												onChange={(e) => {
													setFormData({ ...formData, date: e.target.value });
												}}
											/>
											{errors.date && (
												<span className="text-danger">
													This field is required
												</span>
											)}
										</div>

										<div className="col-md-12">
											<label>Notes</label>
											<textarea
												className="form-control"
												name="notes"
												id="notes"
												placeholder="Enter Notes"
												{...register("notes", { required: false })}
												onChange={(e) => {
													setFormData({ ...formData, notes: e.target.value });
												}}
											/>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-secondary"
										data-bs-dismiss="modal"
										onClick={closeModal}
									>
										Close
									</button>
									<button type="submit" className="btn btn-primary">
										Save changes
									</button>
								</div>
							</form>
						</div>
					</Modal>

					<div className="container-fluid project-create">
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-body">
										<button
											class="btn btn-primary"
											type="button"
											onClick={openModal}
										>
											Add Transfer
										</button>

										<div className="table-responsive">
											<table
												className="display"
												id="transfer-table"
												style={{
													width: "100%",
													textAlign: "center",
													border: "1px solid #dee2e6",
												}}
											>
												<thead>
													<tr>
														<th>Sl no</th>
														<th>From Account</th>
														<th>To Account </th>
														<th>Amount</th>

														<th>Date</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
